import { connect } from 'react-redux';
import CopyTextComponent from '../components/CopyTextComponent';
import * as actions from '../actions/copyTextComponentActionCreators';

const mapStateToProps = (state) => ({
  text: state.text,
});

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyTextComponent);
