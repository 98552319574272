import ReactOnRails from 'react-on-rails';

import AnnouncementFormApp from '../bundles/AnnouncementForm/startup/AnnouncementFormApp';
import AuthorizationsManagerApp from '../bundles/AuthorizationsManager/startup/AuthorizationsManagerApp';
import ChatInboxApp from '../bundles/ChatInbox/startup/ChatInboxApp';
import ClaimInviteCodeApp from '../bundles/ClaimInviteCode/startup/ClaimInviteCodeApp';
import CopyTextComponentApp from '../bundles/CopyTextComponent/startup/CopyTextComponentApp';
import GoToLinkSubnavApp from '../bundles/GoToLinkSubnav/startup/GoToLinkSubnavApp';
import OrganizationMembershipManagerApp from '../bundles/OrganizationMembershipManager/startup/OrganizationMembershipManagerApp';
import OrganizationPartnershipFormApp from '../bundles/OrganizationPartnershipForm/startup/OrganizationPartnershipFormApp';
import PartnershipFormApp from '../bundles/PartnershipForm/startup/PartnershipFormApp';
import PartnershipInvitationButtonApp from '../bundles/PartnershipInvitationButton/startup/PartnershipInvitationButtonApp';
import PartnershipPicker from '../bundles/PartnershipPicker/components/PartnershipPicker';
import PartnershipStatusApp from '../bundles/PartnershipStatus/startup/PartnershipStatusApp';
import PartnershipUploadApp from '../bundles/PartnershipUpload/startup/PartnershipUploadApp';
import ProjectProfileApp from '../bundles/ProjectProfile/startup/ProjectProfileApp';
import ChatApp from '../bundles/Chat/startup/ChatApp';
import DashboardApp from '../bundles/Dashboard/startup/DashboardApp';
import TagFormApp from '../bundles/TagForm/startup/TagFormApp';
import TaskCompletionAssignmentApp from '../bundles/TaskCompletionAssignment/startup/TaskCompletionAssignmentApp';
import TaskCompletionFormApp from '../bundles/TaskCompletionForm/startup/TaskCompletionFormApp';
import TaskCompletionGridApp from '../bundles/TaskCompletionGrid/startup/TaskCompletionGridApp';
import TaskFormApp from '../bundles/TaskForm/startup/TaskFormApp';
import TaskGridApp from '../bundles/TaskGrid/startup/TaskGridApp';
import ToastComponentApp from '../bundles/ToastComponent/startup/ToastComponentApp';
import ResourceIndexApp from '../bundles/ResourceIndex/startup/ResourceIndexApp';
import EventFormApp from '../bundles/EventForm/startup/EventFormApp';
import EventsApp from '../bundles/Events/startup/EventsApp';
import ImpressionChartApp from '../bundles/ImpressionChart/startup/ImpressionChartApp';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  AnnouncementFormApp,
  AuthorizationsManagerApp,
  ChatInboxApp,
  ClaimInviteCodeApp,
  CopyTextComponentApp,
  GoToLinkSubnavApp,
  ChatApp,
  DashboardApp,
  OrganizationMembershipManagerApp,
  OrganizationPartnershipFormApp,
  PartnershipFormApp,
  PartnershipInvitationButtonApp,
  PartnershipPicker,
  PartnershipStatusApp,
  PartnershipUploadApp,
  ProjectProfileApp,
  TagFormApp,
  TaskCompletionAssignmentApp,
  TaskCompletionFormApp,
  TaskCompletionGridApp,
  TaskFormApp,
  TaskGridApp,
  ToastComponentApp,
  ResourceIndexApp,
  EventFormApp,
  EventsApp,
  ImpressionChartApp
});
