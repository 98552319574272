import { combineReducers } from 'redux';
import {
} from '../constants/partnershipStatusConstants';

const partnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const partnershipStatusReducer = combineReducers({
  partnership
});

export default partnershipStatusReducer;
