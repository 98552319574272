import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  ALL_RESOURCES,
  ARCHIVED_RESOURCES
} from '../constants/dashboardConstants';

const Resources = ({
  currentEvent,
  currentPartnership,
  resourceTaskCompletions,
  unarchivedTaskCompletions,
  archivedTaskCompletions,
  resourceMenuState,
  resourceMenuStateChanged,
  csrfToken,
  taskCompletionLabel
}) => {
  useEffect(() => {
    // ...
  }, [])

  return (
    <div className="card border-0 shadow-1">
      <div className="card-header"
            style={{
              "borderBottom": "1px solid #e8e8e8",
              "background": "white"
            }}>
        <div className='row'>
          <div className='col-xs-auto'
                style={{"paddingLeft": "15px"}}>
            <p className="mb-0"
                style={{"fontSize": "18px"}}>
              <strong>Resources</strong>
            </p>
          </div>
          {archivedTaskCompletions.length > 0 ? (
            <div className='col d-flex align-items-center justify-content-end small'>
              <ul className='list-group list-group-horizontal'>
                <li className='list-group-item border-0 py-0 pl-0 pr-3'>
                  <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          resourceMenuStateChanged(ALL_RESOURCES);
                        }
                      }
                      className={resourceMenuState === ALL_RESOURCES ? 'text-dark' : 'text-muted'}>
                    View All <strong>{unarchivedTaskCompletions.length.toLocaleString()}</strong> Resources
                  </a>
                </li>
                <li className='list-group-item border-0 py-0 pl-0 pr-0'>
                  <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          resourceMenuStateChanged(ARCHIVED_RESOURCES);
                        }
                      }
                      className={resourceMenuState === ARCHIVED_RESOURCES ? 'text-dark' : 'text-muted'}>
                    <strong>{archivedTaskCompletions.length.toLocaleString()}</strong> Archived
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      {resourceTaskCompletions.length > 0 ? (
        <div className="card-body">
          <div className="form-row">
            {resourceTaskCompletions.map((taskCompletion, index) => (
              <div className="col-12 col-md-6"
                    key={index}
                    style={{
                      "marginBottom": "10px",
                      "opacity": !taskCompletion.task.is_published ? 0.4 : 1
                    }}>
                <div className="card border-0"
                      style={{"background": "#f5f6fb"}}>
                  <div className="card-body text-truncate"
                        style={{"fontSize": "14px"}}>
                    {!taskCompletion.task.is_published ? (
                      <span className="badge badge-primary mr-2"
                            style={{
                              "borderRadius": "4px",
                              "padding": "4px 8px",
                              "fontSize": "10px",
                              "backgroundColor": "#2563EB",
                              "color": "#white",
                              "position": "absolute",
                              "top": "-5px"
                            }}>
                        <strong>Draft</strong>
                      </span>
                    ) : null}
                    <div className="d-flex flex-row align-items-center">
                      <div className="d-flex flex-shrink-1 justify-content-center align-items-center">
                        <i className={taskCompletion.task.icon_front_end_options.css}
                            style={{
                              width: '16px',
                              height: '16px',
                              fontSize: '16px',
                              lineHeight: '16px'
                            }}>
                        </i>
                      </div>
                      <div className="flex-grow-1 ml-2 text-truncate">
                        <a href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/task_completions/" + taskCompletion.id + "/edit"}
                            title={taskCompletionLabel(currentPartnership, taskCompletion)}
                            className="text-dark stretched-link">
                          {taskCompletionLabel(currentPartnership, taskCompletion)}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="card-body">
          <div className='row'>
            <div className='col text-center my-3'>
              <i className="text-orange fab fa-readme mb-2"
                  style={{"fontSize": "55px"}}></i>
              <p className="mb-0 text-dark"
                  style={{"fontSize": "16px"}}>
                <strong>No Assigned Resources</strong>
              </p>
              <p className="mb-0 text-muted"
                  style={{"fontSize": "14px"}}>
                No resources have been shared yet.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

Resources.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  resourceTaskCompletions: PropTypes.array,
  csrfToken: PropTypes.string.isRequired,
  taskCompletionLabel: PropTypes.func.isRequired,
  unarchivedTaskCompletions: PropTypes.array,
  archivedTaskCompletions: PropTypes.array,
  resourceMenuState: PropTypes.string,
  resourceMenuStateChanged: PropTypes.func.isRequired
};

export default Resources;
