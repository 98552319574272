// Simple example of a React "smart" component

import { connect } from 'react-redux';
import AnnouncementForm from '../components/AnnouncementForm';
import * as actions from '../actions/announcementFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  announcement: state.announcement,
  taskOptions: state.taskOptions,
  tagOptions: state.tagOptions,
  partnershipCount: state.partnershipCount,
  organizationPartnershipOptions: state.organizationPartnershipOptions
});

const mapDispatchToProps = (dispatch) => {
  return {
    announcementChanged: (announcement) => dispatch(actions.announcementChanged(announcement)),
    createAnnouncement: (csrfToken, event, currentPartnership, announcement) => dispatch(actions.createAnnouncement(dispatch, csrfToken, event, currentPartnership, announcement)),
    updateAnnouncement: (csrfToken, event, currentPartnership, announcement) => dispatch(actions.updateAnnouncement(dispatch, csrfToken, event, currentPartnership, announcement))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementForm);
