import { combineReducers } from 'redux';
import {
  FIELD_DRAG_END
} from '../constants/resourceIndexConstants';

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const resources = (state = [], action) => {
  switch (action.type) {
    case FIELD_DRAG_END:
      var startIndex = action.result.source.index;
      var endIndex = action.result.destination.index;

      var resources = [...state];
      var [removed] = resources.splice(startIndex, 1);
      resources.splice(endIndex, 0, removed);

      resources = resources.map((resource, index) =>
        Object.assign({}, resource, {position: (index + 1)})
      );

      return resources;
    default:
      return state;
  }
};

const canUpdateResource = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const resourceFormReducer = combineReducers({
  currentEvent, currentPartnership, resources, canUpdateResource
});

export default resourceFormReducer;
