import PropTypes from 'prop-types';
import React from 'react';

var _ = require('lodash');

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const quantityOptions = function(inventory){
  var options = inventory.quantity_options;

  if(inventory.order_exists){
    options.unshift(0);
  }

  return _.uniq(options);
}

const InventoryField = ({
  index,
  inventory,
  inventoryChanged,
  disabled,
  currentEvent,
  currentPartnership
}) => (
  <div className="form-group">
    <div className='row'>
      <div className='col'>
        <p className='mb-0'>
          <strong>
            {inventory.name}
          </strong>
          {inventory.required ? (
            <abbr className='ml-1' title="required">*</abbr>
          ) : null}
        </p>
        <p className='mb-0'>
          {formatter.format(inventory.price)}
          <i className='fab fa-cc-stripe ml-1' style={{"color": "#6060f6"}}></i>
        </p>
        {inventory.order_exists ? (
          <React.Fragment>
            <p className='mb-0 mt-2' style={{"fontSize": "14px"}}>
              <a style={{
                  "color": "#5e65ed"
                }}
                href={`/e/${currentEvent.permalink}/p/${currentPartnership.id}/project_settings/current/edit#your-orders`}>
                <i className="fas fa-arrow-circle-right mr-1"></i>
                {inventory.owned_quantity === 0 ? (
                  <strong>An order for this item is processing.</strong>
                ) : (
                  <strong>{`You've previously purchased ${inventory.owned_quantity} of this item.`}</strong>
                )}
              </a>
            </p>
            {!disabled ? (
              <p className='mb-0' style={{"fontSize": "14px"}}>
                Set quantity to purchase more or leave 0 to submit without additional purchases.
              </p>
            ) : null}
          </React.Fragment>
        ) : null}
        {inventory.description && inventory.description.length > 0 ? (
          <p className='mb-0 mt-2 text-muted small'
              style={{"maxWidth": "450px"}}>
            {inventory.description}
          </p>
        ) : null}
      </div>
      <div className='col-xs-auto'
           style={{"paddingRight": "15px"}}>
        <select className="form-control text-muted"
                disabled={disabled}
                value={inventory.quantity || ""}
                onChange={
                  (e) => {
                    var updated = {...inventory, quantity: e.target.value};
                    inventoryChanged(updated);
                  }
                }
                id="exampleFormControlSelect1">
          <option value="">Qty</option>
          {quantityOptions(inventory).map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </select>
      </div>
    </div>
  </div>
);

InventoryField.propTypes = {
  index: PropTypes.number.isRequired,
  inventory: PropTypes.object.isRequired,
  inventoryChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired
};

export default InventoryField;
