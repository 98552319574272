import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";

import { ToastContainer } from 'react-toastify';
const axios = require('axios').default;

const AuthorizationsManager = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  individualOptions,
  organizationPartnershipOptions,
  taskOptions,
  resourceOptions,
  params,
  internalTaskOptions
}) => {
  const [permissionsForType, setPermissionsForType] = useState(params["for"] || "");
  const [permissionsForId, setPermissionsForId] = useState(params["id"] || "");
  const [optionsForPermissionsForId, setOptionsForPermissionsForId] = useState([]);
  const [withType, setWithType] = useState("");
  const [withId, setWithId] = useState("");
  const [withTypeOptions, setWithTypeOptions] = useState([]);
  const [optionsForWithId, setOptionsForWithId] = useState([]);
  const [authorizations, setAuthorizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [typeOptions, setTypeOptions] = useState([
    { value: "Dashboard", label: "Dashboard" },
    { value: "InternalTask", label: "Internal Task" },
    { value: "Partnership", label: "Host" },
    { value: "OrganizationPartnership", label: "Organization" },
    { value: "Resource", label: "Resource" },
    { value: "Task", label: "Task" }
  ]);

  useEffect(() => {
    setOptionsForPermissionsForId(
      permissionsForType === "Partnership" ? (
        individualOptions
      ) : permissionsForType === "OrganizationPartnership" ? (
        organizationPartnershipOptions
      ) : permissionsForType === "Task" ? (
        taskOptions
      ) : permissionsForType === "InternalTask" ? (
        internalTaskOptions
      ) : permissionsForType === "Resource" ? (
        resourceOptions
      ) : permissionsForType === "Dashboard" ? (
        []
      ) : ([])
    );

    setWithTypeOptions(
      permissionsForType === "Partnership" ? (
        typeOptions.filter(option => !["Partnership", "Dashboard"].includes(option.value))
      ) : permissionsForType === "OrganizationPartnership" ? (
        typeOptions.filter(option => !["OrganizationPartnership", "Dashboard"].includes(option.value))
      ) : permissionsForType === "Task" ? (
        typeOptions.filter(option => !["Task", "Resource", "Dashboard", "InternalTask"].includes(option.value))
      ) : permissionsForType === "InternalTask" ? (
        typeOptions.filter(option => !["Task", "Resource", "Dashboard", "InternalTask"].includes(option.value))
      ) : permissionsForType === "Resource" ? (
        typeOptions.filter(option => !["Task", "Resource", "Dashboard", "InternalTask"].includes(option.value))
      ) : permissionsForType === "Dashboard" ? (
        typeOptions.filter(option => !["Task", "Resource", "Dashboard", "OrganizationPartnership", "InternalTask"].includes(option.value))
      ) : ([])
    );
  }, [permissionsForType]);

  useEffect(() => {
    setOptionsForWithId(
      withType === "Partnership" ? (
        individualOptions
      ) : withType === "OrganizationPartnership" ? (
        organizationPartnershipOptions
      ) : withType === "Task" ? (
        taskOptions
      ) : withType === "InternalTask" ? (
        internalTaskOptions
      ) : withType === "Resource" ? (
        resourceOptions
      ) : ([])
    );
  }, [withType]);

  useEffect(() => {
    setAuthorizations([]);

    var hasAllValues = (
      permissionsForType !== ""
        && (permissionsForId !== "" || permissionsForType === "Dashboard")
        && withType !== ""
        && withId !== ""
    )

    if (hasAllValues) {
      setIsLoading(true);

      axios.get(`/e/${currentEvent.permalink}/p/${currentPartnership.id}/authorizations.json`, {
          params: {
            permissions_for_type: permissionsForType,
            permissions_for_id: permissionsForId,
            with_type: withType,
            with_id: withId
          }
        })
        .then(({ data }) => {
          setAuthorizations(data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [permissionsForType, permissionsForId, withType, withId]);

  return (
    <>
      <ToastContainer />
      <div className='row'>
        <div className='col-12 offset-md-1 col-md-10'>
          <div className='card border-0'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <p className='mb-0' style={{"fontSize": "18px"}}>
                    <strong>Permissions Manager</strong>
                  </p>
                  <p className='mb-0 text-muted' style={{"fontSize": "16px"}}>
                    Configure permissions for viewing and editing data.
                  </p>
                </div>
              </div>
              <form className='pb-5'>
                <div className="form-row">
                  <div className='col-12 d-flex align-items-center'>
                    <label className='mb-0 text-muted small'
                            htmlFor="permissions-for-type">
                      View for
                    </label>
                    <select className="custom-select form-control ml-2"
                            style={{"maxWidth": "200px"}}
                            value={permissionsForType}
                            onChange={(e) => {
                              setPermissionsForType(e.target.value);
                              setPermissionsForId("");
                              setWithType("");
                              setWithId("");
                            }}
                            id="permissions-for-type">
                      <option value="">Select...</option>
                      {typeOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div>
                      {permissionsForType !== "" && permissionsForType !== "Dashboard" ? (
                        <select className="custom-select form-control ml-2"
                                style={{"maxWidth": "300px"}}
                                value={permissionsForId}
                                onChange={(e) => setPermissionsForId(e.target.value)}
                                id="permissions-for-id">
                          <option value="">Select...</option>
                          {optionsForPermissionsForId.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  </div>
                </div>
                {permissionsForType !== "" ? (
                  <div className="form-row mt-2">
                    <div className='col-12 d-flex align-items-center'>
                      <label className='mb-0 text-muted small'
                              htmlFor="permissions-with-type">
                        With
                      </label>
                      <select className="custom-select form-control ml-2"
                              style={{"maxWidth": "200px"}}
                              value={withType}
                              onChange={(e) => {
                                setWithType(e.target.value);
                                setWithId("");
                              }}
                              id="permissions-with-type">
                        <option value="">Select...</option>
                        {withTypeOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div>
                        {withType !== "" ? (
                          <select className="custom-select form-control ml-2"
                                  style={{"maxWidth": "300px"}}
                                  value={withId}
                                  onChange={(e) => setWithId(e.target.value)}
                                  id="with-id">
                            <option value="">Select...</option>
                            {optionsForWithId.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </form>
              <div className='my-5'>
                {isLoading ? (
                  <div className='row'>
                    <div className='col-12 text-center'>
                      <img src="/loading.gif"
                        style={{width: "32px"}} />
                    </div>
                  </div>
                ) : authorizations.length === 0 ? (
                  <div className='row'>
                    <div className='col-12 text-center'>
                      <i style={{"fontSize": "48px"}}
                          className="fas fa-user-lock text-muted"></i>
                      <p className='mb-0 mt-1 text-muted' style={{"fontSize": "16px"}}>
                        <strong>Set Permissions</strong>
                      </p>
                      <p className='mb-0 text-muted' style={{"fontSize": "16px", "marginTop": "-3px"}}>
                        Set filters above to find permissions.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className='row'>
                    <div className='col-12'>
                      <table className='table table-sm vertical-middle'>
                        <colgroup>
                          <col span={1} style={{"width": "50%"}} />
                          <col span={1} style={{"width": "20%"}} />
                          <col span={1} style={{"width": "10%"}} />
                          <col span={1} style={{"width": "10%"}} />
                          <col span={1} style={{"width": "10%"}} />
                        </colgroup>
                        <thead>
                          <tr style={{"fontSize": "12px"}}>
                            <th className='text-muted border-0'>{permissionsForType === "Dashboard" ? "INDIVIDUAL" : "ASSIGNMENT"}</th>
                            <th className='text-muted border-0'>HOST</th>
                            <th className="text-center text-muted border-0">VIEW</th>
                            <th className="text-center text-muted border-0">EDIT</th>
                            <th className="text-center text-muted border-0">NOTIFY</th>
                          </tr>
                        </thead>
                        <tbody style={{"fontSize": "14px"}}>
                          <tr style={{"fontSize": "12px", "backgroundColor": "rgba(0, 0, 0, .05)"}}>
                            <td className='text-muted border-0' colSpan={2}>
                              <i style={{"fontSize": "10px"}}>SELECT ALL</i>
                            </td>
                            <td className="text-center text-muted border-0">
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" disabled={true} defaultChecked={true} className="custom-control-input" />
                                <label className="custom-control-label">&nbsp;</label>
                              </div>
                            </td>
                            <td className="text-center text-muted border-0">
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" disabled={true} defaultChecked={true} className="custom-control-input" />
                                <label className="custom-control-label">&nbsp;</label>
                              </div>
                            </td>
                            <td className="text-center text-muted border-0">
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                        id={"select-all-can_notify"}
                                        checked={
                                          authorizations.every((authorization) => authorization.can_notify)
                                        }
                                        onChange={
                                          (e) => {
                                            var updated = authorizations.map((authorization) => {
                                              return {
                                                ...authorization,
                                                can_notify: e.target.checked
                                              };
                                            });

                                            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
                                            axios.post(`/e/${currentEvent.permalink}/p/${currentPartnership.id}/authorizations/bulk_update`, {
                                              authorization_ids: updated.map((authorization) => authorization.id),
                                              authorization: {
                                                can_notify: e.target.checked
                                              }
                                            });

                                            setAuthorizations(updated);
                                          }
                                        }
                                        className="custom-control-input" />
                                <label htmlFor='select-all-can_notify' className="custom-control-label">&nbsp;</label>
                              </div>
                            </td>
                          </tr>
                          {authorizations.sort((a, b) => a.name.localeCompare(b.name)).map((authorization, index) => (
                            <tr key={index}>
                              <td className='overflow-ellipsis'>
                                <p className='mb-0'>
                                  <strong>{authorization.name}</strong>
                                </p>
                                <p className='mb-0' style={{"fontSize": "12px", "marginTop": "-3px"}}>
                                  {authorization.subname}
                                </p>
                              </td>
                              <td className='overflow-ellipsis'>
                                {authorization.partnership.name}
                              </td>
                              <td className="text-center">
                                <div className="custom-control custom-checkbox">
                                  <input type="checkbox" disabled={true} defaultChecked={true} className="custom-control-input" />
                                  <label className="custom-control-label">&nbsp;</label>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="custom-control custom-checkbox">
                                  <input type="checkbox" disabled={true} defaultChecked={true} className="custom-control-input" />
                                  <label className="custom-control-label">&nbsp;</label>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="custom-control custom-checkbox">
                                  <input type="checkbox"
                                          id={`authorization-${authorization.id}-can_notify`}
                                          checked={authorization.can_notify}
                                          onChange={
                                            (e) => {
                                              var updated = {
                                                ...authorization,
                                                can_notify: e.target.checked
                                              };

                                              axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
                                              axios.put(`/e/${currentEvent.permalink}/p/${currentPartnership.id}/authorizations/${authorization.id}`, {
                                                authorization: {
                                                  can_notify: updated.can_notify
                                                }
                                              });

                                              setAuthorizations((prevAuthorizations) => {
                                                return prevAuthorizations.map((prevAuthorization) => {
                                                  if (prevAuthorization.id === authorization.id) {
                                                    return updated;
                                                  } else {
                                                    return prevAuthorization;
                                                  }
                                                });
                                              });
                                            }
                                          }
                                          className="custom-control-input" />
                                  <label className="custom-control-label"
                                          htmlFor={`authorization-${authorization.id}-can_notify`}>
                                    &nbsp;
                                  </label>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

AuthorizationsManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  individualOptions: PropTypes.array,
  organizationPartnershipOptions: PropTypes.array,
  taskOptions: PropTypes.array,
  resourceOptions: PropTypes.array,
  params: PropTypes.object,
  internalTaskOptions: PropTypes.array
};

export default AuthorizationsManager;
