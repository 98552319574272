// Simple example of a React "smart" component

import { connect } from 'react-redux';
import EventForm from '../components/EventForm';
import * as actions from '../actions/eventFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership
});

const mapDispatchToProps = (dispatch) => {
  return {
    welcomeMessageChanged: (welcomeMessage) => dispatch(actions.welcomeMessageChanged(welcomeMessage)),
    resetWelcomeMessage: (csrfToken, event, currentPartnership) => dispatch(actions.resetWelcomeMessage(dispatch, csrfToken, event, currentPartnership))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
