import PropTypes from 'prop-types';
import React from 'react';

const canSubmitForm = (email) => {
  return (
    email
      && email.length > 0
  );
}

const combinedInvitationsAndOrganizationMemberships = (invitations, organizationMemberships) => {
  return invitations
    .concat(organizationMemberships)
    .sort((a, b) => a.email.localeCompare(b.email));
}

const OrganizationMembershipManager = ({
  csrfToken,
  organization,
  email,
  emailChanged,
  invitations,
  createInvitation,
  errors,
  deleteInvitation,
  organizationMemberships,
  deleteOrganizationMembership
}) => (
  <div className='row'>
    <div className="col-12 pt-3">
      <table className='table'>
        <tbody>
          {combinedInvitationsAndOrganizationMemberships(invitations, organizationMemberships).map((member, index) => (
            <tr key={index}>
              <td>
                {member.email}
                {member.owner ? (
                  <span className="badge badge-secondary ml-2">Owner</span>
                ) : null}
              </td>
              <td className="text-right">
                {!member.owner ? (
                  <a className="text-muted"
                     onClick={
                       (e) => {
                         e.preventDefault();

                         if(!confirm("Are you sure? The individual will be immediately removed from all hosted events. This action cannot be undone.")){
                           return false;
                         }

                         if(member.class_name === "Invitation"){
                           deleteInvitation(csrfToken, organization, member);
                         } else {
                           deleteOrganizationMembership(csrfToken, organization, member);
                         }
                       }
                     }
                     href="#">
                    <i className="fa fa-times-circle"></i>
                  </a>
                ) : (
                  <span>&nbsp;</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="col-12">
      <form onSubmit={
        (e) => {
          e.preventDefault();

          if(canSubmitForm(email)){
            createInvitation(csrfToken, organization, email);
          }
        }
      }>
        <div className="form-row">
          {errors && errors.length > 0 ? (
            <div className="col-12">
              <div className="form-group">
                <div className="p-3 mb-2 bg-danger text-white">
                  <ul className="mb-0">
                    {errors.map((error, key) =>
                      <li key={key}>{error}</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-12">
            <div className="input-group">
              <input className="form-control"
                     value={email}
                     onChange={
                       (e) => {
                         emailChanged(e.target.value);
                       }
                     }
                     placeholder="Email"
                     type="email" />
              <div className="input-group-append">
                <button className="btn btn-primary"
                        disabled={!canSubmitForm(email)}
                        type="submit">
                  Send Invitation
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
);

OrganizationMembershipManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  email: PropTypes.string,
  emailChanged: PropTypes.func.isRequired,
  invitations: PropTypes.array,
  createInvitation: PropTypes.func.isRequired,
  errors: PropTypes.array,
  deleteInvitation: PropTypes.func.isRequired,
  organizationMemberships: PropTypes.array,
  deleteOrganizationMembership: PropTypes.func.isRequired
};

export default OrganizationMembershipManager;
