// Simple example of a React "smart" component

import { connect } from 'react-redux';
import TagForm from '../components/TagForm';
import * as actions from '../actions/tagFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  tag: state.tag
});

const mapDispatchToProps = (dispatch) => {
  return {
    tagNameChanged: (name) => dispatch(actions.tagNameChanged(name))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
