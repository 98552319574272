import PropTypes from 'prop-types';
import React from 'react';

const CustomField = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  task,
  deleteField,
  customField,
  customFieldChanged,
  index,
  provided
}) => (
  <div className={"row " + (customField._destroy ? "hide " : "") + (task.custom_fields.length === (index + 1) ? "" : "mb-1")}>
    <input type="hidden"
           name={"task[custom_fields_attributes][" + index + "][id]"}
           value={customField.id || ""} />
    <input type="hidden"
           name={"task[custom_fields_attributes][" + index + "][_destroy]"}
           value={customField._destroy || false} />
    <input type="hidden"
           name={"task[custom_fields_attributes][" + index + "][type]"}
           value={customField.type || ""} />
    <input type="hidden"
           name={"task[custom_fields_attributes][" + index + "][name]"}
           value={customField.name || ""} />
    <input type="hidden"
           name={"task[custom_fields_attributes][" + index + "][character_limit]"}
           value={customField.character_limit || ""} />
    <input type="hidden"
           name={"task[custom_fields_attributes][" + index + "][position]"}
           value={customField.position || ""} />
    <input type="hidden"
           name={"task[custom_fields_attributes][" + index + "][required]"}
           value={customField.required || false} />
    <input type="hidden"
        name={"task[custom_fields_attributes][" + index + "][text_options]"}
        value={customField.text_options || ""} />
    <input type="hidden"
           name={"task[custom_fields_attributes][" + index + "][enable_choice_limits]"}
           value={customField.enable_choice_limits || false} />
    <div className="col-xs-auto text-muted"
         style={{"paddingLeft": "15px"}}>
      <a {...provided.dragHandleProps}
         href="#"
         onClick={
           (e) => {
             e.preventDefault();
           }
         }
         className="text-dark">
        <i className="fal fa-arrows mr-2 small"></i>
      </a>
      <span className="d-inline-block text-center"
            style={{
              "width": "25px"
            }}>
        {customField.type === "CustomFields::Text" ? (
          <i className="fas fa-comment-alt-lines"></i>
        ) : customField.type === "CustomFields::TextArea" ? (
          <i className="fas fa-paragraph"></i>
        ) : customField.type === "CustomFields::Radio" ? (
          <i className="far fa-dot-circle"></i>
        ) : customField.type === "CustomFields::CheckboxGroup" ? (
          <i className="fas fa-check-square"></i>
        ) : customField.type === "CustomFields::Select" ? (
          <i className="fas fa-chevron-circle-down"></i>
        ) : customField.type === "CustomFields::File" ? (
          <i className="fas fa-cloud-upload-alt"></i>
        ) : null}
      </span>
    </div>
    <div className="col pl-1 text-muted">
      {customField.name}
    </div>
    <div className="col-xs-auto"
         style={{"paddingRight": "15px"}}>
      <a href="#"
         className="text-muted"
         onClick={
           (e) => {
             e.preventDefault();
             customFieldChanged(customField, index);
           }
         }>
        <i className="fal fa-pencil small"></i>
      </a>
      <a href="#"
         className="text-muted ml-2 small"
         onClick={
           (e) => {
             e.preventDefault();
             deleteField(index);
           }
         }>
        <i className="fal fa-trash"></i>
      </a>
    </div>
  </div>
);

CustomField.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  deleteField: PropTypes.func.isRequired,
  customField: PropTypes.object,
  customFieldChanged: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  provided: PropTypes.object.isRequired
};

export default CustomField;
