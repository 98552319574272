/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  FILTER_CHANGED
} from '../constants/eventsConstants';

export const filterChanged = (filter) => ({
  type: FILTER_CHANGED,
  filter
});
