/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  WELCOME_MESSAGE_CHANGED
} from '../constants/eventFormConstants';

export const welcomeMessageChanged = (welcomeMessage) => ({
  type: WELCOME_MESSAGE_CHANGED,
  welcomeMessage
});

export const resetWelcomeMessage = (dispatch, csrfToken, event, currentPartnership) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/e/" + event.permalink + "/p/" + currentPartnership.id + "/partnerships/" + currentPartnership.id + "/reset_welcome_message")
      .then(({ data }) => {
        toast.success("Welcome message has been reset", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};
