import { combineReducers } from 'redux';
import {
} from '../constants/partnershipUploadConstants';

const isFtux = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const uploadType = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const partnershipUploadReducer = combineReducers({
  isFtux, csrfToken, currentEvent, currentPartnership, uploadType
});

export default partnershipUploadReducer;
