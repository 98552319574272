import PropTypes from 'prop-types';
import React from 'react';

const HiddenInputs = ({
  customFieldValue,
  index
}) => (
  <div>
    <input type="hidden"
           name={"task_completion[custom_field_values_attributes][" + index + "][id]"}
           value={customFieldValue.id || ""} />
    <input type="hidden"
           name={"task_completion[custom_field_values_attributes][" + index + "][custom_field_id]"}
           value={customFieldValue.custom_field.id} />
  </div>
);

HiddenInputs.propTypes = {
  customFieldValue: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default HiddenInputs;
