import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const axios = require('axios').default;

import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';

registerAllModules();

const fetchTaskGridData = function(currentEvent, currentPartnership, taskId, tableDataChanged, isLoadingTableDataChanged){
  new Promise(resolve => {
    isLoadingTableDataChanged(true);

    axios.get('/e/' + currentEvent.permalink + '/p/' + currentPartnership.id + '/tasks/' + taskId + '/grid_data')
      .then(function (response) {
        tableDataChanged(response.data);
      })
      .finally(function () {
        isLoadingTableDataChanged(false);
      });
  });
}

const calculateStatistics = function(selectedData) {
  const count = selectedData.flat().length;
  const numericValues = selectedData
    .flat()
    .filter(value => !isNaN(value))
    .map(Number);

  if (numericValues.length === 0) {
    return {
      count: count,
      sum: 0,
      average: 0,
      min: 0,
      max: 0,
    };
  }

  const numericCount = numericValues.length;
  const sum = numericValues.reduce((acc, value) => acc + value, 0);
  const average = (sum / numericCount);
  const min = Math.min(...numericValues);
  const max = Math.max(...numericValues);

  return {
    count: count,
    sum: sum,
    average: average,
    min: min,
    max: max
  };
}

const TaskGrid = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  tasks,
  tableData,
  tableDataChanged,
  isLoadingTableData,
  isLoadingTableDataChanged,
  openTaskId,
  selectedData,
  selectedDataChanged
}) => {
  const hotTableComponent = useRef(null);
  const selectedStatistics = calculateStatistics(selectedData);

  useEffect(() => {
    if(openTaskId){
      fetchTaskGridData(
        currentEvent,
        currentPartnership,
        openTaskId,
        tableDataChanged,
        isLoadingTableDataChanged
      );
    }
  }, []);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-6 col-xl-4'>
          <form>
            <div className='form-group'>
              <label htmlFor="grid-task"
                    className='mb-0 text-muted small'>
                Viewing results for task
              </label>
              <select className="custom-select custom-select-sm"
                      defaultValue={openTaskId || ""}
                      onChange={
                        (e) => {
                          fetchTaskGridData(
                            currentEvent,
                            currentPartnership,
                            e.target.value,
                            tableDataChanged,
                            isLoadingTableDataChanged
                          );
                        }
                      }
                      id="grid-task">
                <option disabled={true} value="">
                  Select a task...
                </option>
                {tasks.map((task, index) => 
                  <option key={index} value={task.id}>
                    {task.label}
                  </option>
                )}
              </select>
            </div>
          </form>
        </div>
      </div>
      <div className='row'>
        {isLoadingTableData ? (
          <div className='col-12 text-center my-5'>
            <img src="/loading.gif"
                  style={{width: "32px"}} />
            <p className='mb-1'>
              Loading...
            </p>
          </div>
        ) : Object.keys(tableData).length > 0 ? (
          <div className='col-12'>
            <HotTable
              data={tableData.data}
              ref={hotTableComponent}
              colHeaders={tableData.headers}
              rowHeaders={true}
              height={500}
              fixedColumnsStart={1}
              readOnly={true}
              manualColumnResize={true}
              afterSelection={
                (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
                  var data = hotTableComponent.current.hotInstance.getData(row, column, row2, column2);
                  selectedDataChanged(data);
                }
              }
              afterDeselect={
                () => {
                  selectedDataChanged([]);
                }
              }
              licenseKey="9a270-b223c-adf14-24130-e8a51"
            />
          </div>
        ) : (
          <div className='col-12 text-center my-5'>
            <div style={{"fontSize": "72px"}}>
              <i className='fas fa-table fa-fw text-muted'></i>
            </div>
            <p className='mb-1' style={{"fontSize": "20px"}}>
              <strong>No Data</strong>
            </p>
            <p className='mb-1'>
              Select a task from the dropdown above to get started.
            </p>
          </div>
        )}
      </div>
      {Object.keys(tableData).length > 0 ? (
        <div className='row'>
          <div className='col-12 text-right mt-2'>
            <span className="badge badge-pill badge-light ml-1 mb-1 py-2 px-3 font-weight-normal">
              COUNT <strong className='ml-2'>{selectedStatistics.count.toLocaleString()}</strong>
            </span>
            <span className="badge badge-pill badge-light ml-1 mb-1 py-2 px-3 font-weight-normal">
              SUM <strong className='ml-2'>{selectedStatistics.sum.toLocaleString()}</strong>
            </span>
            <span className="badge badge-pill badge-light ml-1 mb-1 py-2 px-3 font-weight-normal">
              AVERAGE <strong className='ml-2'>{selectedStatistics.average.toLocaleString()}</strong>
            </span>
            <span className="badge badge-pill badge-light ml-1 mb-1 py-2 px-3 font-weight-normal">
              MIN <strong className='ml-2'>{selectedStatistics.min.toLocaleString()}</strong>
            </span>
            <span className="badge badge-pill badge-light ml-1 mb-1 py-2 px-3 font-weight-normal">
              MAX <strong className='ml-2'>{selectedStatistics.max.toLocaleString()}</strong>
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
}

TaskGrid.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  tasks: PropTypes.array,
  tableData: PropTypes.object,
  tableDataChanged: PropTypes.func.isRequired,
  isLoadingTableData: PropTypes.bool,
  isLoadingTableDataChanged: PropTypes.func.isRequired,
  openTaskId: PropTypes.string,
  selectedData: PropTypes.array,
  selectedDataChanged: PropTypes.func.isRequired
};

export default TaskGrid;
