// Simple example of a React "smart" component

import { connect } from 'react-redux';
import PartnershipForm from '../components/PartnershipForm';
import * as actions from '../actions/partnershipFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  partnership: state.partnership,
  organizationNames: state.organizationNames,
  eventOrganizationPartnershipNames: state.eventOrganizationPartnershipNames
});

const mapDispatchToProps = (dispatch) => {
  return {
    updatePartnershipFirstName: (firstName) => dispatch(actions.updatePartnershipFirstName(firstName)),
    updatePartnershipLastName: (lastName) => dispatch(actions.updatePartnershipLastName(lastName)),
    updatePartnershipEmail: (email) => dispatch(actions.updatePartnershipEmail(email)),
    partnershipChanged: (partnership) => dispatch(actions.partnershipChanged(partnership)),
    organizationNamesChanged: (organizationNames) => dispatch(actions.organizationNamesChanged(organizationNames))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(PartnershipForm);
