/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  TABLE_DATA_CHANGED,
  IS_LOADING_TABLE_DATA_CHANGED,
  SELECTED_DATA_CHANGED
} from '../constants/taskGridConstants';

export const tableDataChanged = (tableData) => ({
  type: TABLE_DATA_CHANGED,
  tableData
});

export const isLoadingTableDataChanged = (isLoadingTableData) => ({
  type: IS_LOADING_TABLE_DATA_CHANGED,
  isLoadingTableData
});

export const selectedDataChanged = (selectedData) => ({
  type: SELECTED_DATA_CHANGED,
  selectedData
});
