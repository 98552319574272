/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  FIELD_DRAG_END
} from '../constants/resourceIndexConstants';

export const fieldDragEnd = (result) => ({
  type: FIELD_DRAG_END,
  result
});
