import { combineReducers } from 'redux';
import {
  UPDATE_PARTNERSHIP_FIRST_NAME,
  UPDATE_PARTNERSHIP_LAST_NAME,
  UPDATE_PARTNERSHIP_EMAIL,
  PARTNERSHIP_CHANGED,
  ORGANIZATION_NAMES_CHANGED
} from '../constants/partnershipFormConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const partnership = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PARTNERSHIP_FIRST_NAME:
      return Object.assign({}, state, {first_name: action.firstName});
    case UPDATE_PARTNERSHIP_LAST_NAME:
      return Object.assign({}, state, {last_name: action.lastName});
    case UPDATE_PARTNERSHIP_EMAIL:
      return Object.assign({}, state, {email: action.email});
    case PARTNERSHIP_CHANGED:
      return action.partnership;
    default:
      return state;
  }
};

const organizationNames = (state = [], action) => {
  switch (action.type) {
    case ORGANIZATION_NAMES_CHANGED:
      return action.organizationNames;
    default:
      return state;
  }
};

const eventOrganizationPartnershipNames = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const taskFormReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  partnership,
  organizationNames,
  eventOrganizationPartnershipNames
});

export default taskFormReducer;
