// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ChatPage from '../components/Chat';
import * as actions from '../actions/chatActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  chatChannelAvatars: state.chatChannelAvatars,
  channelId: state.channelId,
  chatChannelType: state.chatChannelType,
  messages: state.messages,
  chat: state.chat,
  partnership: state.partnership,
  webSocketHost: state.webSocketHost,
  currentEvent: state.currentEvent,
  csrfToken: state.csrfToken,
  canComment: state.canComment
});

const mapDispatchToProps = (dispatch) => {
  return {
    messageReceived: (message) => dispatch(actions.messageReceived(message)),
    updateChat: (chat) => dispatch(actions.updateChat(chat))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ChatPage);
