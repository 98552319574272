import { combineReducers } from 'redux';
import {
  CUSTOM_FIELD_VALUE_CHANGED
} from '../constants/taskCompletionGridConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tasks = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const organizationPartnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const customFieldValues = (state = [], action) => {
  switch (action.type) {
    case CUSTOM_FIELD_VALUE_CHANGED:
      return [...state].map((cfv) => {
        if(cfv.id === action.customFieldValue.id){
          return action.customFieldValue;
        } else {
          return cfv;
        }
      });
    default:
      return state;
  }
};

const taskCompletionGridReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  tasks,
  organizationPartnerships,
  customFieldValues
});

export default taskCompletionGridReducer;
