/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { v4 as uuidv4 } from 'uuid';

import {
  EXAMPLE
} from '../constants/goToLinkSubnavConstants';

export const example = (example) => ({
  type: EXAMPLE,
  example
});
