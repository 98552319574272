import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import AsyncSelect from 'react-select/async';
const axios = require('axios').default;
var _ = require('lodash');

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "white",
    marginTop: "-10px",
    width: "150px",
    fontSize: "12px",
    height: "28px"
  }),
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "white",
      border: "none",
      boxShadow: 'none'
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "white",
      paddingLeft: "0px"
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      color: "#6c757d",
      marginLeft: "0px",
      fontWeight: "bold"
    };
  },
  indicatorSeparator: (styles, { data }) => {
    return {
      ...styles,
      display: "none"
    };
  },
  indicatorsContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: "#6c757d",
      fontWeight: "bold",
      fontSize: "16px",
      ':hover': {
        cursor: "pointer",
        color: "#6c757d"
      }
    };
  },
  dropdownIndicator: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: "#6c757d",
      fontWeight: "bold",
      ':hover': {
        cursor: "pointer",
        color: "#6c757d"
      }
    };
  },
  menu: (provided) => ({
    ...provided,
    fontSize: "12px"
  }),
};

const debouncedAvailablePartnershipsFetch = _.debounce((inputValue, callback, currentEvent, currentPartnership) => {
  new Promise(resolve => {
    axios.get('/e/' + currentEvent.permalink + '/p/' + currentPartnership.id + '/available_partnerships', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        callback(response.data);
      })
  });
}, 250);

const PartnershipPicker = ({
  currentEvent,
  currentPartnership,
  currentUserPartnership
}) => (
  <>
    {currentUserPartnership.host ? (
      <div className='form-inline' style={{"paddingLeft": "15px"}}>
        <div className="form-group">
          <div className='position-relative' style={{"zIndex": "100"}}>
            <label className='small text-muted mb-0 mr-2'
                  htmlFor="current-partnership-select">
              Viewing as:
            </label>
          </div>
          <AsyncSelect cacheOptions
                      styles={selectStyles}
                      menuPortalTarget={document.querySelector('body')}
                      defaultOptions
                      value={currentPartnership}
                      onChange={
                        (option) => {
                          window.location.href = ("/e/" + currentEvent.permalink + "/p/" + option.id);
                        }
                      }
                      loadOptions={(inputValue, callback) => debouncedAvailablePartnershipsFetch(inputValue, callback, currentEvent, currentPartnership)} />
        </div>
      </div>
    ) : null}
  </>
);

PartnershipPicker.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  currentUserPartnership: PropTypes.object.isRequired
};

export default PartnershipPicker;
