/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  ANNOUNCEMENT_CHANGED
} from '../constants/announcementFormConstants';

export const announcementChanged = (announcement) => ({
  type: ANNOUNCEMENT_CHANGED,
  announcement
});

export const createAnnouncement = (dispatch, csrfToken, event, currentPartnership, announcement) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/e/" + event.permalink + "/p/" + currentPartnership.id + "/announcements", {
        text: announcement.text,
        announceable_id: announcement.announceable_id,
        announceable_type: announcement.announceable_type,
        segment: announcement.segment,
        scheduled_at: announcement.scheduled_at,
        schedule_now: announcement.schedule_now,
        notify: announcement.notify,
        segmentable_type: announcement.segmentable_type,
        segmentable_id: announcement.segmentable_id
      })
      .then(({ data }) => {
        window.location.href = ("/e/" + event.permalink + "/p/" + currentPartnership.id + "/announcements/" + data.id);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const updateAnnouncement = (dispatch, csrfToken, event, currentPartnership, announcement) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/e/" + event.permalink + "/p/" + currentPartnership.id + "/announcements/" + announcement.id, {
        text: announcement.text,
        announceable_id: announcement.announceable_id,
        announceable_type: announcement.announceable_type,
        segment: announcement.segment,
        scheduled_at: announcement.scheduled_at,
        schedule_now: announcement.schedule_now,
        notify: announcement.notify,
        segmentable_type: announcement.segmentable_type,
        segmentable_id: announcement.segmentable_id
      })
      .then(({ data }) => {
        window.location.href = ("/e/" + event.permalink + "/p/" + currentPartnership.id + "/announcements/" + data.id);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};
