import PropTypes from 'prop-types';
import React from 'react';

import Tippy from '@tippyjs/react';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

const PartnershipStatus = ({
  partnership
}) => (
  <span>
    {partnership.user && partnership.user.id ? (
      <Tippy content={
               <div>
                 <p className="mb-0">User granted access to their dashboard</p>
                 <p className="mb-0">Last sign in {timeAgo.format(new Date(partnership.user.current_sign_in_at))}</p>
               </div>
             }
             theme='material'
             animation={'shift-away'}>
        <i className="fad fa-circle"
           style={{"color": "#10B981"}}></i>
      </Tippy>
    ) : partnership.invitation && partnership.invitation.id ? (
      <Tippy content={"Invitation has been sent"}
             theme='material'
             animation={'shift-away'}>
        <i className="fad fa-circle"
           style={{"color": "#F59E0B"}}></i>
      </Tippy>
    ) : (
      <Tippy content={"No user access granted"}
             theme='material'
             animation={'shift-away'}>
        <i className="fad fa-circle"
           style={{"color": "#EF4444"}}></i>
      </Tippy>
    )}
  </span>
);

PartnershipStatus.propTypes = {
  partnership: PropTypes.object.isRequired
};

export default PartnershipStatus;
