import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Tippy from '@tippyjs/react';

const CustomFieldValueCell = ({
  organizationPartnership,
  customField,
  customFieldValues,
  uploadCustomFieldValueFile,
  csrfToken,
  currentEvent,
  currentPartnership,
}) => {
  var fileEl;
  var customFieldValue = customFieldValues.find((cfv) => {
    return (
      cfv.organization_partnership_id === organizationPartnership.id
        && cfv.custom_field_id === customField.id
    );
  });

  return (
    <td className="text-truncate px-2"
        style={{
          "maxWidth": "200px",
          "background": (customFieldValue && customField.id ? "" : "#dfe2e6")
        }}
        title={""}>
      {customFieldValue && customFieldValue.id ? (
        <div className="position-relative">
          <input ref={el => {
                   if(el){
                     fileEl = el;
                   }
                 }}
                 onChange={
                   (e) => {
                     var files = e.target.files;
                     for (var i = 0, file; file = files[i]; i++) {
                       uploadCustomFieldValueFile(csrfToken, currentEvent, currentPartnership, file, customFieldValue);
                     }

                     e.target.value = null;
                   }
                 }
                 type="file"
                 multiple={true}
                 style={{ display: "none" }} />
          {customFieldValue.csv_value && customFieldValue.csv_value.length > 0 ? (
            <div className="text-truncate"
                 style={{"width": "90%"}}>
              <a href={customFieldValue.csv_value}
                 className="small"
                 target="_blank">
                {customFieldValue.csv_value}
              </a>
            </div>
          ) : (
            <span style={{"opacity": "0.5"}}>--</span>
          )}
          <div className="position-absolute"
               style={{
                 "right": "0px",
                 "top": "0px"
               }}>
            {customFieldValue && customFieldValue.isUploading ? (
              <img src="/images/loading.gif"
                   style={{width: "16px"}} />
            ) : (
              <a href="#"
                 className="text-muted"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     fileEl.click();
                   }
                 }>
                <i className="fad fa-upload"></i>
              </a>
            )}
          </div>
        </div>
      ) : null}
    </td>
  )
};

CustomFieldValueCell.propTypes = {
  organizationPartnership: PropTypes.object.isRequired,
  customField: PropTypes.object.isRequired,
  customFieldValues: PropTypes.array.isRequired,
  uploadCustomFieldValueFile: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired
};

export default CustomFieldValueCell;
