import PropTypes from 'prop-types';
import React from 'react';

import FieldLabel from './FieldLabel';
import HiddenInputs from './HiddenInputs';

const fontAwesomeClassName = function(customFieldValue){
  var extension = customFieldValue.custom_field_file_upload.extension;

  if(extension === "pdf"){
    return "fa-file-pdf";
  } else if (extension === "doc") {
    return "fa-file-word";
  } else if (extension === "xls") {
    return "fa-file-excel";
  } else if (extension === "txt") {
    return "fa-file-alt";
  } else if (extension === "csv") {
    return "fa-file-csv";
  } else if (extension === "eps") {
    return "fa-file-image";
  } else {
    return "fa-file";
  }
}

const FileField = ({
  customFieldValue,
  index,
  disabled,
  uploadCustomFieldFileUpload,
  csrfToken,
  currentEvent,
  currentPartnership,
  fileFieldValueRemoved
}) => (
  <div className="form-group">
    <FieldLabel index={index}
                customFieldValue={customFieldValue} />
    <HiddenInputs index={index}
                  customFieldValue={customFieldValue} />
    <input type="hidden"
           name={"task_completion[custom_field_values_attributes][" + index + "][type]"}
           value={"CustomFieldValues::File"} />
    <input type="hidden"
           name={"task_completion[custom_field_values_attributes][" + index + "][value]"}
           value={customFieldValue.value || ""} />
    {customFieldValue.custom_field_file_upload && !customFieldValue.percentCompleted ? (
      <div className="row mb-2">
        <div className="col-12">
          {customFieldValue.custom_field_file_upload.is_image ? (
            <div className="rounded w-50 position-relative"
                 style={{
                   "maxHeight": "283.5px",
                   "overflow": "hidden"
                 }}>
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     fileFieldValueRemoved(index);
                   }
                 }
                 title="Remove"
                 style={{
                   "fontSize": "22px",
                   "top": "5px",
                   "right": "8px"
                 }}
                 className="ml-2 text-danger position-absolute">
                <i className="fas fa-trash"></i>
              </a>
              <a target="_blank"
                 href={"/custom_field_file_uploads/" + customFieldValue.custom_field_file_upload.token}>
                <img src={"/custom_field_file_uploads/" + customFieldValue.custom_field_file_upload.token}
                     alt={customFieldValue.custom_field_file_upload.file_name}
                     title={customFieldValue.custom_field_file_upload.file_name}
                     style={{
                       "borderRadius": "4px",
                       "width": "100%"
                     }}
                     className="img-fluid" />
              </a>
            </div>
          ) : (
            <div>
              <div>
                <a target="_blank"
                   className="text-muted small"
                   href={"/custom_field_file_uploads/" + customFieldValue.custom_field_file_upload.token}>
                  <i className={"fas text-muted " + fontAwesomeClassName(customFieldValue)}
                     style={{"fontSize": "55px"}}>
                  </i>
                </a>
              </div>
              <div>
                <a target="_blank"
                   className="text-muted small"
                   href={"/custom_field_file_uploads/" + customFieldValue.custom_field_file_upload.token}>
                  <i>
                    {customFieldValue.custom_field_file_upload.file_name}
                  </i>
                </a>
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       fileFieldValueRemoved(index);
                     }
                   }
                   title="Remove"
                   className="ml-2 text-danger small">
                  <i className="fas fa-trash"></i>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    ) : null}
    {customFieldValue.percentCompleted ? (
      <div className="row mb-2">
        <div className="col-12">
          <img src="/images/loading.gif"
               style={{width: "16px"}} />
          <span className="ml-2 small">
            {customFieldValue.percentCompleted + "%"}
          </span>
        </div>
      </div>
    ) : null}
    <div className="row">
      <div className="col-12">
        <input type="file"
               className="form-control-file"
               disabled={disabled}
               onChange={
                 (e) => {
                   fileFieldValueRemoved(index);

                   var file = e.target.files[0];
                   uploadCustomFieldFileUpload(csrfToken, currentEvent, currentPartnership, file, index);

                   e.target.value = null;
                 }
               }
               id={"custom-field-value-" + index} />
      </div>
    </div>
  </div>
);

FileField.propTypes = {
  customFieldValue: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  uploadCustomFieldFileUpload: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  fileFieldValueRemoved: PropTypes.func.isRequired
};

export default FileField;
