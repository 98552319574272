/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  INPUT_FIELD_CHANGED,
  TEXT_AREA_FIELD_CHANGED,
  RADIO_FIELD_CHANGED,
  CHECK_BOX_FIELD_CHANGED,
  SELECT_FIELD_CHANGED,
  FILE_FIELD_CHANGED,
  FILE_FIELD_PROGRESS_CHANGED,
  TASK_COMPLETION_CHANGED,
  FILE_FIELD_VALUE_REMOVED,
  INVENTORY_CHANGED
} from '../constants/taskCompletionFormConstants';

export const inputFieldChanged = (index, value) => ({
  type: INPUT_FIELD_CHANGED,
  index,
  value
});

export const textAreaFieldChanged = (index, value) => ({
  type: TEXT_AREA_FIELD_CHANGED,
  index,
  value
});

export const radioFieldChanged = (index, value) => ({
  type: RADIO_FIELD_CHANGED,
  index,
  value
});

export const checkBoxFieldChanged = (index, value) => ({
  type: CHECK_BOX_FIELD_CHANGED,
  index,
  value
});

export const selectFieldChanged = (index, value) => ({
  type: SELECT_FIELD_CHANGED,
  index,
  value
});

export const uploadCustomFieldFileUpload = (dispatch, csrfToken, currentEvent, currentPartnership, file, index) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var formData = new FormData();
    formData.append("custom_field_file_upload[file]", file);

    return axios.post("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/custom_field_file_uploads", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch(fileFieldProgressChanged(index, percentCompleted));

          if(percentCompleted === 100){
            setTimeout(() => {
              dispatch(fileFieldProgressChanged(index, null));
            }, 750);
          }
        }
      })
      .then(({ data }) => {
        dispatch(fileFieldChanged(index, data));
      });
  };
};

export const fileFieldChanged = (index, customFieldFileUpload) => ({
  type: FILE_FIELD_CHANGED,
  index,
  customFieldFileUpload
});

export const fileFieldProgressChanged = (index, percentCompleted) => ({
  type: FILE_FIELD_PROGRESS_CHANGED,
  index,
  percentCompleted
});

const valueTypeForCustomField = function(customField){
  return customField.type.replace("CustomFields", "CustomFieldValues");
}

export const saveTaskCompletionProgress = (dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion, onSuccess) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var nestedAttrs = taskCompletion.custom_field_values.map((customFieldValue) => {
      return {
        id: customFieldValue.id,
        custom_field_id: customFieldValue.custom_field.id,
        value: customFieldValue.value,
        type: valueTypeForCustomField(customFieldValue.custom_field)
      }
    });

    return axios.patch("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/task_completions/" + taskCompletion.id + ".json", {
        task_completion: {
          custom_field_values_attributes: nestedAttrs
        }
      })
      .then(({ data }) => {
        dispatch(taskCompletionChanged(data));

        if(typeof(onSuccess) === "function"){
          onSuccess(data);
        } else {
          toast.success("Your progress has been saved", {
            position: toast.POSITION.TOP_CENTER,
            draggable: false,
            closeOnClick: false,
            autoClose: 5000,
            hideProgressBar: true
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const completeTaskCompletion = (dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion) => {
  return dispatch => {
    dispatch(saveTaskCompletionProgress(dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion, () => {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

      return axios.post("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/task_completions/" + taskCompletion.id + "/complete.json")
        .then(({ data }) => {
          location.reload();
        })
        .catch((error) => {
          toast.error(error.response.data.join(", "), {
            position: toast.POSITION.TOP_CENTER,
            draggable: false,
            closeOnClick: false,
            autoClose: 5000,
            hideProgressBar: true
          });
        });
    }));
  };
};

export const goToTaskCompletionLink = (dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion) => {
  return dispatch => {
    dispatch(saveTaskCompletionProgress(dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion, () => {
      window.location.href = "/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/task_completions/" + taskCompletion.id + "/go_to_link";
    }));
  };
};

export const taskCompletionChanged = (taskCompletion) => ({
  type: TASK_COMPLETION_CHANGED,
  taskCompletion
});

export const fileFieldValueRemoved = (index) => ({
  type: FILE_FIELD_VALUE_REMOVED,
  index
});

export const createOrderAndGoToStripe = (dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion, inventories) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var inventoryAttrs = inventories
      .filter((inventory) => inventory.quantity && parseInt(inventory.quantity) > 0)
      .map((inventory) => {
        return {
          inventory_id: inventory.id,
          quantity: inventory.quantity
        }
      });

    dispatch(saveTaskCompletionProgress(dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion, () => {
      return axios.post("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/orders", {
          order: {
            task_completion_id: taskCompletion.id,
            order_items_attributes: inventoryAttrs
          }
        })
        .then(({ data }) => {
          window.location.replace(data);
        })
        .catch((error) => {
          toast.error(error.response.data.join(", "), {
            position: toast.POSITION.TOP_CENTER,
            draggable: false,
            closeOnClick: false,
            autoClose: 5000,
            hideProgressBar: true
          });
        });
    }));
  };
};

export const inventoryChanged = (inventory) => ({
  type: INVENTORY_CHANGED,
  inventory
});
