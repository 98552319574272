/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  CURRENT_PARTNERSHIP_CHANGED
} from '../constants/impressionChartConstants';

export const currentPartnershipChanged = (partnership) => ({
  type: CURRENT_PARTNERSHIP_CHANGED,
  partnership
});
