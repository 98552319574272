// Simple example of a React "smart" component

import { connect } from 'react-redux';
import PartnershipInvitationButton from '../components/PartnershipInvitationButton';
import * as actions from '../actions/partnershipInvitationButtonActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentPartnership: state.currentPartnership,
  currentEvent: state.currentEvent,
  partnership: state.partnership,
  invitationUrl: state.invitationUrl
});

const mapDispatchToProps = (dispatch) => {
  return {
    createPartnershipInvitation: (csrfToken, event, currentPartnership, partnership) => dispatch(actions.createPartnershipInvitation(dispatch, csrfToken, event, currentPartnership, partnership)),
    resendInvitation: (csrfToken, event, currentPartnership, invitation) => dispatch(actions.resendInvitation(dispatch, csrfToken, event, currentPartnership, invitation))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(PartnershipInvitationButton);
