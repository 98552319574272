import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import CustomFieldForm from './CustomFieldForm';
import TaskCompletionLink from './TaskCompletionLink';
import Froala from '../../Froala/components/Froala';

import InventoryItem from './InventoryItem';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

var _ = require('lodash');
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
const axios = require('axios').default;
import Flatpickr from "react-flatpickr";
import Tippy from '@tippyjs/react';

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "3"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const debouncedFetch = _.debounce((inputValue, callback, currentEvent, currentPartnership) => {
  new Promise(resolve => {
    axios.get('/e/' + currentEvent.permalink + '/p/' + currentPartnership.id + '/tags/autocomplete', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(newOptions);
      })
  });
}, 250);

const taskDueAt = function(task){
  if(typeof(task.due_at) === "string"){
    var parsed = new Date(task.due_at);
    return parsed;
  } else {
    return task.due_at;
  }
}

const publishedAt = function(task){
  if(typeof(task.published_at) === "string"){
    var parsed = new Date(task.published_at);
    return parsed;
  } else {
    return task.published_at;
  }
}

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const TaskForm = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  task,
  iconOptions,
  updateTaskOrganizationTags,
  updatePartnershipTaskCompletionEnabled,
  updateOrganizationPartnershipTaskCompletionEnabled,
  updateTaskCompletionDueAt,
  updateTaskDueAt,
  updateTaskPublishedAt,
  updateTaskDescription,
  updateTaskLabel,
  updateTaskPinned,
  deleteField,
  customField,
  customFieldChanged,
  customFieldIndex,
  addField,
  customFieldTypeChanged,
  customFieldNameChanged,
  customFieldCharacterLimitChanged,
  customFieldRequiredChanged,
  customFieldSaved,
  updateTaskLocked,
  fieldDragEnd,
  taskChanged,
  resourcesOptions,
  selectAllClicked,
  inventories,
  chargesEnabled,
  addInventory,
  inventoryChanged,
  tagOptions,
  inventoryDragEnd,
  customFieldTextOptionsChanged,
  customFieldEnableChoiceLimitsChanged,
  updateIcon
}) => {
  useEffect(() => {
    inventories.forEach((inventory, index) => {
      var updated = {...inventory};

      if(typeof(inventory.min_per_order) === "number"){
        updated = {...updated, min_per_order: updated.min_per_order.toString()};
      }

      if(typeof(inventory.max_per_order) === "number"){
        updated = {...updated, max_per_order: updated.max_per_order.toString()};
      }

      if(inventory.price){
        updated = {...updated, price: parseFloatFromString(updated.price).toFixed(2)};
      }

      inventoryChanged(updated, index);
    });
  }, []);

  return (
    <div className='row'>
      <div className='col-12'>
        {task && task.copied_from_id ? (
          <input type="hidden"
                name={"task[copied_from_id]"}
                value={task.copied_from_id} />
        ) : null}
        <div className="form-group">
          <label className="form-control-label text-muted"
                htmlFor="task-label">
            Name <abbr title="required">*</abbr>
          </label>
          <input className="form-control"
                type="text"
                name="task[label]"
                onChange={
                  (e) => {
                    updateTaskLabel(e.target.value);
                  }
                }
                value={task.label || ""}
                id="task-label" />
        </div>
        <div className="form-group">
          <label className="form-control-label text-muted"
                htmlFor="task-description">
            Description
          </label>
          <Froala id="task-description"
                  onModelChange={
                    (html) => {
                      updateTaskDescription(html);
                    }
                  }
                  currentEvent={currentEvent}
                  currentPartnership={currentPartnership}
                  csrfToken={csrfToken}
                  model={task.description || ""} />
          <input type="hidden"
                name="task[description]"
                value={task.description || ""} />
        </div>


        {(task && task.type === 'ToDo') || (task && task.type === 'InternalTask') ? (
          <div>
            {(task && task.type === 'ToDo') ? (
              <div className="form-group">
                <label htmlFor="task-referenced-task-ids">
                  Embedded Resources
                  <Tippy content={"Embedded resources appear above task content when published and assigned."}
                        theme='material'
                        animation={'shift-away'}>
                    <span className="fa-stack ml-2 text-success"
                          style={{"fontSize": "10px"}}>
                      <i className="fas fa-circle fa-stack-2x"></i>
                      <i className="far fa-info fa-stack-1x fa-inverse"></i>
                    </span>
                  </Tippy>
                </label>
                <Select
                  value={task.referenced_tasks}
                  id="task-referenced-task-ids"
                  isMulti
                  name="task[referenced_task_ids][]"
                  options={resourcesOptions}
                  className="basic-multi-select"
                  onChange={
                    (options) => {
                      var updated = Object.assign({}, task, {
                        referenced_tasks: options
                      });

                      taskChanged(updated);
                    }
                  }
                  classNamePrefix="select"
                />
              </div>
            ) : null }
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input type="hidden"
                      name={"task[locked]"}
                      value={task.locked} />
                <input type="checkbox"
                      className="custom-control-input"
                      onChange={
                        (e) => {
                          updateTaskLocked(e.target.checked);
                        }
                      }
                      checked={task.locked}
                      id="task-locked" />
                <label className="custom-control-label text-muted"
                      htmlFor="task-locked">
                  <i className="fas fa-lock mr-1 small fa-fw"></i>
                  <strong>Lock this task </strong>
                  <span>
                    Locked tasks will remain visible but will no longer accept changes.
                  </span>
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input type="hidden"
                      name={"task[notify_hosts_on_completion]"}
                      value={task.notify_hosts_on_completion} />
                <input type="checkbox"
                      className="custom-control-input"
                      onChange={
                        (e) => {
                          var updated = Object.assign({}, task, {
                            notify_hosts_on_completion: e.target.checked
                          });

                          taskChanged(updated);
                        }
                      }
                      checked={task.notify_hosts_on_completion}
                      id="task-notify-hosts-on-completion" />
                <label className="custom-control-label text-muted"
                      htmlFor="task-notify-hosts-on-completion">
                  <i className="fas fa-paper-plane mr-1 small fa-fw"></i>
                  <strong>Notify Hosts </strong>
                  <span>
                    Send email to hosts when task is completed.
                  </span>
                </label>
              </div>
            </div>
            <div className="form-group mb-0">
              <label className="form-control-label text-muted"
                    htmlFor="task-due-at">
                Task Due...
                <Tippy content={"Email reminders are automatically sent to user-connected individuals: 2 weeks before, 1 week before, 2 days before, and day of due date"}
                      theme='material'
                      animation={'shift-away'}>
                  <span className="fa-stack ml-2 text-success"
                        style={{"fontSize": "10px"}}>
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="far fa-envelope fa-stack-1x fa-inverse"></i>
                  </span>
                </Tippy>
              </label>
            </div>
            <input type="hidden"
                  name={"task[advance]"}
                  value={task.advance} />
            <input type="hidden"
                  name={"task[individual_due_dates]"}
                  value={task.individual_due_dates} />
            <input type="hidden"
                  name={"task[due_at]"}
                  value={task.due_at || ""} />
            <input type="hidden"
                  name={"task[due_in_number]"}
                  value={task.due_in_number || ""} />
            <input type="hidden"
                  name={"task[due_in_time_period]"}
                  value={task.due_in_time_period || ""} />
            <ul className="list-group">
              <li className="list-group-item">
                <div className="form-check">
                  <input className="form-check-input"
                        type="radio"
                        disabled={task.pinned}
                        checked={(!task.advance && !task.individual_due_dates) || false}
                        onChange={
                          (e) => {
                            var updated = Object.assign({}, task, {
                              advance: false,
                              individual_due_dates: false,
                              due_in_number: null,
                              due_in_time_period: null
                            });

                            taskChanged(updated);
                          }
                        }
                        id="task-advance-false" />
                  <label className="form-check-label"
                        htmlFor="task-advance-false">
                    On a single date for all partners
                    {(!task.advance && !task.individual_due_dates) ? (
                      <React.Fragment>
                        <p className="small mb-0 text-muted">
                          Make this task due at a specific date & time for everyone.
                        </p>
                        <div className="my-2">
                          <Flatpickr
                            data-enable-time
                            disabled={task.pinned}
                            options={{
                              dateFormat: "F j, Y - h:i K"
                            }}
                            value={taskDueAt(task) || ""}
                            onChange={
                              (date) => {
                                updateTaskDueAt(date);
                              }
                            }
                            style={{"maxWidth": "400px"}}
                            className="form-control"
                          />
                        </div>
                      </React.Fragment>
                    ) : null}
                  </label>
                </div>
              </li>
              <li className="list-group-item">
                <div className="form-check">
                  <input className="form-check-input"
                        type="radio"
                        disabled={task.pinned}
                        checked={(task.advance && !task.individual_due_dates) || false}
                        onChange={
                          (e) => {
                            var updated = Object.assign({}, task, {
                              advance: true,
                              individual_due_dates: false,
                              due_at: null,
                              due_in_time_period: "days"
                            });

                            taskChanged(updated);
                          }
                        }
                        id="task-advance-true" />
                  <label className="form-check-label"
                        htmlFor="task-advance-true">
                    On a set number of days after the partner is assigned the task
                    {task.advance ? (
                      <React.Fragment>
                        <p className="small mb-0 text-muted">
                          Deadline is individually determined based on when the task is assigned.
                        </p>
                        <div className="my-2">
                          <div className="form-row">
                            <div className="col-xs-auto">
                              <MaskedInput className="form-control"
                                          style={{"maxWidth": "80px"}}
                                          disabled={task.pinned}
                                          mask={createNumberMask({
                                            prefix: "",
                                            decimalLimit: 0,
                                            integerLimit: 3
                                          })}
                                          placeholder={"0"}
                                          id="task-due-in-number"
                                          onChange={
                                            (e) => {
                                              var updated = Object.assign({}, task, {due_in_number: e.target.value});
                                              taskChanged(updated);
                                            }
                                          }
                                          value={task.due_in_number || ""}
                                          aria-describedby="offer-upside-percentage-percentage" />
                            </div>
                            <div className="col-xs-auto">
                              <select className="form-control"
                                      style={{"width": "250px"}}
                                      disabled={task.pinned}
                                      value={task.due_in_time_period || ""}
                                      onChange={
                                        (e) => {
                                          var updated = Object.assign({}, task, {due_in_time_period: e.target.value});
                                          taskChanged(updated);
                                        }
                                      }
                                      id="task-due-in-time-period">
                                <option value="hours">
                                  Hour(s) after assigned
                                </option>
                                <option value="days">
                                  Day(s) after assigned
                                </option>
                                <option value="weeks">
                                  Week(s) after assigned
                                </option>
                                <option value="months">
                                  Month(s) after assigned
                                </option>
                                <option value="years">
                                  Year(s) after assigned
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </label>
                </div>
              </li>
              <li className="list-group-item">
                <div className="form-check">
                  <input className="form-check-input"
                        type="radio"
                        disabled={task.pinned}
                        checked={task.individual_due_dates || false}
                        onChange={
                          (e) => {
                            var updatedTaskCompletions = [...task.organization_partnership_task_completions].map(tc => Object.assign({}, tc, { due_at: null }));

                            var updated = Object.assign({}, task, {
                              due_at: null,
                              advance: false,
                              individual_due_dates: true,
                              due_in_number: null,
                              due_in_time_period: null,
                              organization_partnership_task_completions: updatedTaskCompletions
                            });

                            taskChanged(updated);
                          }
                        }
                        id="task-individual-due-dates" />
                  <label className="form-check-label"
                        htmlFor="task-individual-due-dates">
                    On custom, partner-specific dates defined below
                  </label>
                </div>
              </li>
            </ul>
            <div className="form-group mt-3">
              <label className="form-control-label text-muted"
                    htmlFor="task-custom-fields">
                Fields
              </label>
              <CustomFieldForm csrfToken={csrfToken}
                              currentPartnership={currentPartnership}
                              task={task}
                              deleteField={deleteField}
                              customField={customField}
                              customFieldChanged={customFieldChanged}
                              customFieldIndex={customFieldIndex}
                              addField={addField}
                              fieldDragEnd={fieldDragEnd}
                              customFieldTypeChanged={customFieldTypeChanged}
                              customFieldNameChanged={customFieldNameChanged}
                              customFieldCharacterLimitChanged={customFieldCharacterLimitChanged}
                              customFieldRequiredChanged={customFieldRequiredChanged}
                              customFieldSaved={customFieldSaved}
                              customFieldTextOptionsChanged={customFieldTextOptionsChanged}
                              customFieldEnableChoiceLimitsChanged={customFieldEnableChoiceLimitsChanged}
                              currentEvent={currentEvent} />
            </div>
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input type="hidden"
                      name={"task[has_go_to_link]"}
                      value={task.has_go_to_link} />
                <input type="hidden"
                      name="task[go_to_link]"
                      value={task.go_to_link || ""} />
                <input type="hidden"
                      name="task[go_to_link_instructions]"
                      value={task.go_to_link_instructions || ""} />
                <input type="hidden"
                      name="task[go_to_link_embedded]"
                      value={task.go_to_link_embedded || false} />
                <input type="checkbox"
                      className="custom-control-input"
                      disabled={task.pinned}
                      onChange={
                        (e) => {
                          var updated = Object.assign({}, task, {
                            has_go_to_link: e.target.checked,
                            go_to_link: "",
                            go_to_link_instructions: ""
                          });

                          taskChanged(updated);
                        }
                      }
                      checked={task.has_go_to_link}
                      id="task-has-go-to-link" />
                <label className="custom-control-label text-muted"
                      htmlFor="task-has-go-to-link">
                  <i className="fas fa-share mr-1 small fa-fw"></i>
                  <strong>Add "Go To" Link </strong>
                  <span>
                    Require visit to external website to complete this task
                  </span>
                </label>
                {task.has_go_to_link ? (
                  <div className="form-row my-2">
                    <div className="col-12">
                      <div className="form-group">
                        <p className="small mb-1 text-muted">
                          Enter the URL to visit and any helpful instructions
                          <Tippy content={"Be sure to test before assigning to your partners. Many websites restrict access via iframe. Look for a URL associated with embedding."}
                                theme='material'
                                animation={'shift-away'}>
                            <i className="fas fa-info-circle ml-2"></i>
                          </Tippy>
                        </p>
                        <input type="text"
                              id="task-go-to-link"
                              className="form-control small"
                              placeholder={"https://www.example.com/"}
                              style={{"maxWidth": "400px"}}
                              onChange={
                                (e) => {
                                  var updated = Object.assign({}, task, {go_to_link: e.target.value});
                                  taskChanged(updated);
                                }
                              }
                              value={task.go_to_link || ""} />
                        <textarea className="form-control small mt-1"
                                  id="task-go-to-link-instructions"
                                  placeholder={"Add instructions..."}
                                  style={{"maxWidth": "400px"}}
                                  value={task.go_to_link_instructions || ""}
                                  onChange={
                                    (e) => {
                                      var updated = Object.assign({}, task, {go_to_link_instructions: e.target.value});
                                      taskChanged(updated);
                                    }
                                  }
                                  rows="3">
                        </textarea>
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox"
                                  className="custom-control-input"
                                  checked={task.go_to_link_embedded || false}
                                  onChange={
                                    (e) => {
                                      var updated = Object.assign({}, task, {go_to_link_embedded: e.target.checked});
                                      taskChanged(updated);
                                    }
                                  }
                                  id="task-go-to-link-embedded" />
                          <label className="custom-control-label"
                                  htmlFor="task-go-to-link-embedded">
                            Embed webpage within PartnerHQ
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {chargesEnabled ? (
              <div className="card my-4">
                <div className="card-header">
                  <h5 className="card-title text-dark">
                    <strong>Sell Your Inventory</strong>
                    <i className="fab fa-cc-stripe ml-2" style={{"color": "#6060f6"}}></i>
                  </h5>
                  <h6 className="card-subtitle mb-0 text-muted">
                    Add inventory items available for purchase
                  </h6>
                </div>
                <DragDropContext onDragEnd={
                                  (result) => {
                                    if (!result.destination) {
                                      return false;
                                    }

                                    inventoryDragEnd(result);
                                  }
                                }>
                  <Droppable droppableId="task-inventories">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {inventories.map((inventory, index) =>
                          <Draggable key={inventory.uuid} draggableId={inventory.uuid} index={index}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} {...provided.draggableProps}>
                                <InventoryItem key={index}
                                  inventory={inventory}
                                  inventoryChanged={inventoryChanged}
                                  tagOptions={tagOptions}
                                  provided={provided}
                                  index={index} />
                              </div>
                            )}
                          </Draggable>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-12'>
                      <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            addInventory();
                          }
                        }>
                        <i className="fas fa-plus-circle mr-1"></i>
                        Add item
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="form-group">
            <input type="hidden"
              name={"task[icon]"}
              value={task.icon || ''}
            />
            <label className="text-muted">
              Icon
            </label>
            {iconOptions.map((option, index) => (
              <div className="form-check" key={index}>
                <input className="form-check-input"
                       type="radio"
                       id={option.type}
                       value={option.type} 
                       checked={task.icon === option.type}
                       onChange={
                        (e) => {
                          var updated = Object.assign({}, task, {
                            icon: e.target.value
                          });

                          updateIcon(updated.icon);
                         }
                       }
                />
                <label className="form-check-label" htmlFor={option.type}>
                  <i className={option.css + " mr-1"}></i>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        )}
        <div className="form-group">
          <label htmlFor="task-organization-tags"
                className="text-muted">
            Tag(s)
          </label>
          <AsyncSelect cacheOptions
                      styles={selectStyles}
                      menuPortalTarget={document.querySelector('body')}
                      defaultOptions
                      isMulti
                      id="task-organization-tags"
                      name="task[organization_tag_ids][]"
                      onChange={(options) => { updateTaskOrganizationTags(options || []) }}
                      value={ task.organization_tags }
                      loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback, currentEvent, currentPartnership)} />
        </div>
        <div className="form-group">
          <label htmlFor="task-organization-task-completions"
                className="text-muted">
            {task.organization_partnership_task_completions.filter(t => t.enabled).length} Assigned Organizations
          </label>
          <div className="pl-1">
            <div className="custom-control custom-checkbox">
              <input type="checkbox"
                            className="custom-control-input"
                            checked={task.organization_partnership_task_completions.every((tc) => tc.enabled)}
                            onChange={
                              (e) => {
                                selectAllClicked(e.target.checked);
                              }
                            }
                            id="selectAllOrgs" />
              <label className="custom-control-label"
                      htmlFor="selectAllOrgs">
                Select All
              </label>
            </div>
          </div>
          {task.organization_partnership_task_completions
            .filter((tc) => !tc.partnerable.archived)
            .sort((a, b) => a.partnerable.name.localeCompare(b.partnerable.name))
            .map((taskCompletion, index) => 
          (
            <div key={index} className={"pl-1 " + (taskCompletion.enabled && task.individual_due_dates ? "highlight-on-hover" : "")}>
              <div className="custom-control custom-checkbox">
                <input type="hidden"
                      name={"task[organization_partnership_task_completions_attributes][" + index + "][id]"}
                      value={taskCompletion.id || ""} />
                <input type="hidden"
                      name={"task[organization_partnership_task_completions_attributes][" + index + "][partnerable_id]"}
                      value={taskCompletion.partnerable.id} />
                {task && task.type === 'InternalTask' && taskCompletion.for_organization_partnership ? (
                  <input type="hidden"
                        name={"task[organization_partnership_task_completions_attributes][" + index + "][for_organization_partnership_id]"}
                        value={taskCompletion.for_organization_partnership.id} />
                ) : null}
                <input type="hidden"
                      name={"task[organization_partnership_task_completions_attributes][" + index + "][enabled]"}
                      value={taskCompletion.enabled} />
                <input type="hidden"
                      name={"task[organization_partnership_task_completions_attributes][" + index + "][due_at]"}
                      value={taskCompletion.due_at || ""} />
                {task && task.type === 'InternalTask' && taskCompletion.for_organization_partnership ? (
                  <span>
                    <input type="checkbox"
                          className="custom-control-input"
                          onChange={
                            (e) => {
                              updateOrganizationPartnershipTaskCompletionEnabled(taskCompletion.for_organization_partnership, e.target.checked);
                            }
                          }
                          checked={taskCompletion.enabled}
                          id={"task-organization-task-completion-" + index} />
                    <label className="custom-control-label"
                          htmlFor={"task-organization-task-completion-" + index}>
                      {taskCompletion.for_organization_partnership.name}
                    </label>
                  </span>
                ) : (
                  <span>
                    <input type="checkbox"
                          className="custom-control-input"
                          onChange={
                            (e) => {
                              updateOrganizationPartnershipTaskCompletionEnabled(taskCompletion.partnerable, e.target.checked);
                            }
                          }
                          checked={taskCompletion.enabled}
                          id={"task-organization-task-completion-" + index} />
                    <label className="custom-control-label"
                          htmlFor={"task-organization-task-completion-" + index}>
                      {taskCompletion.partnerable.name}
                    </label>
                  </span>
                )}
                {taskCompletion.enabled && task.individual_due_dates ? (
                  <div className="pb-1">
                    <span className="smaller text-muted mr-1">
                      Due:
                    </span>
                    <Flatpickr
                      data-enable-time
                      disabled={task.pinned}
                      options={{
                        dateFormat: "F j, Y - h:i K"
                      }}
                      value={taskDueAt(taskCompletion) || ""}
                      onChange={
                        (date) => {
                          updateTaskCompletionDueAt(taskCompletion.partnerable, taskCompletion, date)
                        }
                      }
                      style={{"maxWidth": "200px"}}
                      className="form-control form-control-sm smaller d-inline"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

TaskForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  iconOptions: PropTypes.array,
  updateTaskOrganizationTags: PropTypes.func.isRequired,
  updatePartnershipTaskCompletionEnabled: PropTypes.func.isRequired,
  updateOrganizationPartnershipTaskCompletionEnabled: PropTypes.func.isRequired,
  updateTaskCompletionDueAt: PropTypes.func.isRequired,
  updateTaskDueAt: PropTypes.func.isRequired,
  updateTaskPublishedAt: PropTypes.func.isRequired,
  updateTaskDescription: PropTypes.func.isRequired,
  updateTaskLabel: PropTypes.func.isRequired,
  updateTaskPinned: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  customField: PropTypes.object,
  customFieldChanged: PropTypes.func.isRequired,
  customFieldIndex: PropTypes.number,
  addField: PropTypes.func.isRequired,
  customFieldTypeChanged: PropTypes.func.isRequired,
  customFieldNameChanged: PropTypes.func.isRequired,
  customFieldCharacterLimitChanged: PropTypes.func.isRequired,
  customFieldRequiredChanged: PropTypes.func.isRequired,
  customFieldSaved: PropTypes.func.isRequired,
  updateTaskLocked: PropTypes.func.isRequired,
  fieldDragEnd: PropTypes.func.isRequired,
  taskChanged: PropTypes.func.isRequired,
  resourcesOptions: PropTypes.array,
  selectAllClicked: PropTypes.func.isRequired,
  inventories: PropTypes.array,
  chargesEnabled: PropTypes.bool,
  addInventory: PropTypes.func.isRequired,
  inventoryChanged: PropTypes.func.isRequired,
  tagOptions: PropTypes.array,
  inventoryDragEnd: PropTypes.func.isRequired,
  customFieldTextOptionsChanged: PropTypes.func.isRequired,
  customFieldEnableChoiceLimitsChanged: PropTypes.func.isRequired,
  updateIcon: PropTypes.func.isRequired
};

export default TaskForm;
