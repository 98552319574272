import PropTypes from 'prop-types';
import React from 'react';

import FieldLabel from './FieldLabel';
import HiddenInputs from './HiddenInputs';

const allOptions = function(customFieldValue, disabled){
  var options = customFieldValue.custom_field.options;

  if (disabled && !options.includes(customFieldValue.value)) {
    options.push(customFieldValue.value);
  }

  return options;
}

const SelectField = ({
  customFieldValue,
  index,
  selectFieldChanged,
  disabled
}) => (
  <div className="form-group">
    <FieldLabel index={index}
                customFieldValue={customFieldValue} />
    <HiddenInputs index={index}
                  customFieldValue={customFieldValue} />
    <input type="hidden"
           name={"task_completion[custom_field_values_attributes][" + index + "][type]"}
           value={"CustomFieldValues::Select"} />
    <select className="custom-select"
            disabled={disabled}
            value={customFieldValue.value || ""}
            onChange={
              (e) => {
                selectFieldChanged(index, e.target.value);
              }
            }
            name={"task_completion[custom_field_values_attributes][" + index + "][value]"}>
      <option value={""}>
        Select an option
      </option>
      {allOptions(customFieldValue, disabled).map((optionValue, optionIndex) => (
        <option key={optionIndex}
                value={optionValue}>
          {optionValue}
        </option>
      ))}
    </select>
  </div>
);

SelectField.propTypes = {
  customFieldValue: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectFieldChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default SelectField;
