import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import Popup from "reactjs-popup";

const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "700px",
  "padding": "20px 0px",
  "zIndex": "5000"
}

const ChatInbox = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  fetchInboxMessages,
  messages,
  nextPage,
  hasMore,
  inboxModalIsOpen,
  inboxModalIsOpenChanged
}) => {
  useEffect(() => {
    // ...
  }, [])

  return (
    <>
      <a href="#"
        onClick={
          (e) => {
            e.preventDefault();
            inboxModalIsOpenChanged(true);
          }
        }
        className='text-muted'>
        <i className="fas fa-comment-alt-lines fa-fw mr-1"></i>
        All Comments
      </a>
      <Popup arrow={false}
            open={inboxModalIsOpen}
            offsetY={5}
            className={"popup-modal"}
            contentStyle={settingsContentStyle}
            onClose={
              () => {
                inboxModalIsOpenChanged(false);
              }
            }
            onOpen={
              () => {
                if(nextPage === 1){
                  fetchInboxMessages(currentEvent, currentPartnership, messages, nextPage);
                }
              }
            }>
        {close => (
          <div className="popup-modal-container">
            <div className='row m-0 mb-4 pb-3' style={{"borderBottom": "2px solid #f7f8fa"}}>
              <div className='col'>
                <p className='mb-0' style={{"fontSize": "18px"}}>
                  <strong>All Comments</strong>
                </p>
                <p className='mb-0 text-muted' style={{"fontSize": "14px"}}>
                  Here you can review all comments you've sent and received. Click on the comments to visit the associated task or resource.
                </p>
              </div>
              <div className='col-xs-auto' style={{"paddingRight": "15px"}}>
                <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      close();
                    }
                  }
                  style={{"fontSize": "18px"}}
                  className='text-dark'>
                  <i className="fal fa-times fa-fw"></i>
                </a>
              </div>
            </div>
            <div className='row m-0'>
              <div className='col-12'>
                <InfiniteScroll dataLength={messages.length}
                                next={
                                  () => {
                                    fetchInboxMessages(currentEvent, currentPartnership, messages, nextPage);
                                  }
                                }
                                hasMore={hasMore}
                                height={"350px"}
                                style={{"overflowX": "hidden"}}
                                loader={
                                  <div className="text-center my-2">
                                    <img src="/images/loading.gif" style={{width: "16px"}} />
                                  </div>
                                }>
                  {messages.map((message, index) =>
                    <div className="row"
                        key={index}>
                      <div className="col-12">
                        <div className={"card message-card mb-2 " + (message.task_completion ? "clickable" : "")}
                            style={{"border": "2px solid #f7f8fa"}}>
                          <div className="card-header border-0"
                              style={{"fontSize": "14px"}}>
                            {message.task_completion ? (
                              <strong>
                                <a href={`/e/${currentEvent.permalink}/p/${currentPartnership.id}/task_completions/${message.task_completion.id}/edit`}
                                   className='stretched-link text-dark'>
                                  {message.task_completion.label}
                                </a>
                                <i className="fas fa-external-link-alt small ml-2"></i>
                              </strong>
                            ) : (
                              <strong>{currentEvent.name}</strong>
                            )}
                          </div>
                          <div className="card-body overflow-auto">
                            <div className="row">
                              <div className="col-xs-auto"
                                  style={{"paddingLeft": "15px"}}>
                                <div className="image-container rounded-circle"
                                      style={{
                                        "width": "32px",
                                        "height": "32px",
                                        "backgroundImage": "url('" + message.avatar_path + "')"
                                      }}>
                                </div>
                              </div>
                              <div className="col">
                                <p className="small mb-0">
                                  <strong>{message.name}</strong>
                                </p>
                                <p className="small mb-2 text-muted">
                                  {message.human_created_at}
                                </p>
                                <div className="fr-view message p-2 rounded overflow-auto"
                                      style={{"background": "#f2f6fd", "fontSize": "12px"}}>
                                  <div dangerouslySetInnerHTML={{__html: message.text}} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </>
  )
};

ChatInbox.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  fetchInboxMessages: PropTypes.func.isRequired,
  messages: PropTypes.array,
  nextPage: PropTypes.number,
  hasMore: PropTypes.bool,
  inboxModalIsOpen: PropTypes.bool,
  inboxModalIsOpenChanged: PropTypes.func.isRequired
};

export default ChatInbox;
