import { combineReducers } from 'redux';
import {
} from '../constants/goToLinkSubnavConstants';

import { v4 as uuidv4 } from 'uuid';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const taskCompletion = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const task = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const goToLinkSubnavReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  taskCompletion,
  task
});

export default goToLinkSubnavReducer;
