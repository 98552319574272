import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import Select from 'react-select'

const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "300px",
  "padding": "0px",
  "marginTop": "10px",
  "zIndex": "3000"
}

const TaskCompletionAssignment = ({
  csrfToken,
  taskCompletion,
  partnerships,
  currentPartnership,
  event,
  updateTaskCompletionAssignedToPartnershipId
}) => (
  <div className="text-muted"
       style={{"fontSize": "14px"}}>
    <Popup arrow={false}
           offsetY={-5}
           position="bottom right"
           contentStyle={settingsContentStyle}
           trigger={open => (
          <button style={{"fontSize": "14px"}}
             className="btn btn-outline-secondary btn-sm">
            {taskCompletion.human_assigned_partnership}
            <i className="fas fa-cog pl-2 pr-1"></i>
          </button>
       )} >
         {close => (
           <div className='text-left px-3 pb-3 pt-2'>
            <form>
              <div className="form-group mb-0">
                <label className="text-muted small"
                       htmlFor="task-completion-assigned-partnership-id">
                  Who should complete this task?
                </label>
                <select className="form-control"
                        value={taskCompletion.assigned_partnership_id || ""}
                        onChange={
                          (e) => {
                            var partnershipId = e.target.value;
                            updateTaskCompletionAssignedToPartnershipId(csrfToken, event, taskCompletion, currentPartnership, partnershipId);
                          }
                        }
                        id="task-completion-assigned-partnership-id"
                        style={{"fontSize": "14px"}}>
                  <option value="">Unassigned</option>
                  {partnerships.map((partnership, index) =>
                    <option key={index}
                            value={partnership.id}>
                      {partnership.name}
                    </option>
                  )}
                </select>
              </div>
            </form>
           </div>
         )}
       </Popup>
  </div>
);

TaskCompletionAssignment.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  taskCompletion: PropTypes.object,
  partnerships: PropTypes.array,
  currentPartnership: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  updateTaskCompletionAssignedToPartnershipId: PropTypes.func.isRequired
};

export default TaskCompletionAssignment;
