import PropTypes from 'prop-types';
import React from 'react';

var _ = require('lodash');
const axios = require('axios').default;

import Tags from "@yaireo/tagify/dist/react.tagify";
import AsyncSelect from 'react-select/async';

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const debouncedFetch = _.debounce((inputValue, callback, currentEvent, currentPartnership) => {
  new Promise(resolve => {
    axios.get('/e/' + currentEvent.permalink + '/p/' + currentPartnership.id + '/tags/autocomplete', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(newOptions);
      })
  });
}, 250);

const PartnershipForm = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  partnership,
  updatePartnershipFirstName,
  updatePartnershipLastName,
  updatePartnershipEmail,
  partnershipChanged,
  organizationNames,
  organizationNamesChanged,
  eventOrganizationPartnershipNames
}) => (
  <div className='row'>
    <div className='col-12'>
      <div className="form-row">
        <div className="col">
          <div className="form-group">
            {partnership.invitation && partnership.invitation.id ? (
              <React.Fragment>
                <input type="hidden"
                       name="partnership[invitation_attributes][id]"
                       value={partnership.invitation.id} />
                <input type="hidden"
                       name="partnership[invitation_attributes][email]"
                       value={partnership.email} />
              </React.Fragment>
            ) : null}
            <label className="form-control-label text-muted"
                   htmlFor="partnership-email">
              Email (No email will be sent) <abbr title="required">*</abbr>
            </label>
            <input className="form-control"
                   type="email"
                   name="partnership[email]"
                   onChange={
                     (e) => {
                       updatePartnershipEmail(e.target.value);
                     }
                   }
                   disabled={partnership.user_id || false}
                   value={partnership.email || ""}
                   id="partnership-email" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="form-group">
            <label className="form-control-label text-muted"
                   htmlFor="partnership-first-name">
              First name <abbr title="required">*</abbr>
            </label>
            <input className="form-control"
                   type="text"
                   name="partnership[first_name]"
                   disabled={partnership.user_id || false}
                   onChange={
                     (e) => {
                       updatePartnershipFirstName(e.target.value);
                     }
                   }
                   value={partnership.first_name || ""}
                   id="partnership-first-name" />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label className="form-control-label text-muted"
                   htmlFor="partnership-last-name">
              Last name <abbr title="required">*</abbr>
            </label>
            <input className="form-control"
                   type="text"
                   name="partnership[last_name]"
                   disabled={partnership.user_id || false}
                   onChange={
                     (e) => {
                       updatePartnershipLastName(e.target.value);
                     }
                   }
                   value={partnership.last_name || ""}
                   id="partnership-last-name" />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="partnership-organization-name"
               className="text-muted">
          Organization <abbr title="required">*</abbr>
        </label>
        <input type="hidden"
               value=""
               name="partnership[organization_names][]" />
        {organizationNames.map((selected, index) =>
          <input key={index}
                 type="hidden"
                 value={selected.value}
                 name="partnership[organization_names][]" />
        )}
        <Tags placeholder={"Add or find an Organization - REQUIRED UNLESS HOST"}
              className="w-100"
              whitelist={eventOrganizationPartnershipNames}
              onChange={
                (e) => {
                  organizationNamesChanged(e.detail.tagify.value);
                }
              }
              value={organizationNames} />
      </div>
    </div>
  </div>
);

PartnershipForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  partnership: PropTypes.object.isRequired,
  updatePartnershipFirstName: PropTypes.func.isRequired,
  updatePartnershipLastName: PropTypes.func.isRequired,
  updatePartnershipEmail: PropTypes.func.isRequired,
  partnershipChanged: PropTypes.func.isRequired,
  organizationNames: PropTypes.array,
  organizationNamesChanged: PropTypes.func.isRequired,
  eventOrganizationPartnershipNames: PropTypes.array
};

export default PartnershipForm;
