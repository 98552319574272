/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  EXAMPLE
} from '../constants/authorizationsManagerConstants';

export const announcementChanged = (announcement) => ({
  type: EXAMPLE,
  announcement
});
