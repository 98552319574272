// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OrganizationMembershipManager from '../components/OrganizationMembershipManager';
import * as actions from '../actions/organizationMembershipManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  organization: state.organization,
  email: state.email,
  invitations: state.invitations,
  errors: state.errors,
  organizationMemberships: state.organizationMemberships
});

const mapDispatchToProps = (dispatch) => {
  return {
    emailChanged: (email) => dispatch(actions.emailChanged(email)),
    createInvitation: (csrfToken, organization, email) => dispatch(actions.createInvitation(dispatch, csrfToken, organization, email)),
    deleteInvitation: (csrfToken, organization, invitation) => dispatch(actions.deleteInvitation(dispatch, csrfToken, organization, invitation)),
    deleteOrganizationMembership: (csrfToken, organization, organizationMembership) => dispatch(actions.deleteOrganizationMembership(dispatch, csrfToken, organization, organizationMembership))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMembershipManager);
