import PropTypes from 'prop-types';
import React from 'react';

const FieldLabel = ({
  customFieldValue,
  index
}) => (
  <label htmlFor={"custom-field-value-" + index}>
    <span>{customFieldValue.custom_field.name} </span>
    {customFieldValue.custom_field.required ? (
      <abbr title="required">*</abbr>
    ) : null}
  </label>
);

FieldLabel.propTypes = {
  customFieldValue: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default FieldLabel;
