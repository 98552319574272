// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ProjectProfile from '../components/ProjectProfile';
import * as actions from '../actions/projectProfileActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  activeFilter: state.activeFilter,
  createInvitation: state.createInvitation,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  email: state.email,
  errors: state.errors,
  csrfToken: state.csrfToken,
  organizationPartnerships: state.organizationPartnerships
});

const mapDispatchToProps = (dispatch) => {
  return {
    emailChanged: (email) => dispatch(actions.emailChanged(email)),
    filterChanged: (filter) => dispatch(actions.filterChanged(filter)),
    createInvitation: (csrfToken, organization, email, currentEvent, currentPartnership) => dispatch(actions.createInvitation(dispatch, csrfToken, organization, email, currentEvent, currentPartnership))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ProjectProfile);
