import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import ToDoTaskCompletion from './ToDoTaskCompletion';

import {
  ALL_TASKS,
  ARCHIVED_TASKS
} from '../constants/dashboardConstants';

const Tasks = ({
  currentEvent,
  currentPartnership,
  pendingTaskCompletions,
  csrfToken,
  taskCompletionLabel,
  completedTaskCompletions,
  unarchivedTaskCompletions,
  archivedTaskCompletions,
  taskMenuState,
  taskMenuStateChanged
}) => {
  useEffect(() => {
    // ...
  }, [])

  return (
    <div className="card border-0 shadow-1">
      <div className="card-header"
            style={{
              "borderBottom": "1px solid #e8e8e8",
              "background": "white"
            }}>
        <div className='row'>
          <div className='col-xs-auto'
                style={{"paddingLeft": "15px"}}>
            <p className="mb-0"
                style={{"fontSize": "18px"}}>
              <strong>Tasks</strong>
            </p>
          </div>
          {archivedTaskCompletions.length > 0 ? (
            <div className='col d-flex align-items-center justify-content-end small'>
              <ul className='list-group list-group-horizontal'>
                <li className='list-group-item border-0 py-0 pl-0 pr-3'>
                  <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          taskMenuStateChanged(ALL_TASKS);
                        }
                      }
                      className={taskMenuState === ALL_TASKS ? 'text-dark' : 'text-muted'}>
                    View All <strong>{unarchivedTaskCompletions.length.toLocaleString()}</strong> Tasks
                  </a>
                </li>
                <li className='list-group-item border-0 py-0 pl-0 pr-0'>
                  <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          taskMenuStateChanged(ARCHIVED_TASKS);
                        }
                      }
                      className={taskMenuState === ARCHIVED_TASKS ? 'text-dark' : 'text-muted'}>
                    <strong>{archivedTaskCompletions.length.toLocaleString()}</strong> Archived
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      {pendingTaskCompletions.length > 0 ? (
        <div className="card-body">
          <div className="form-row">
            {pendingTaskCompletions.map((taskCompletion, index) => (
              <ToDoTaskCompletion key={index}
                                  isLast={index === pendingTaskCompletions.length - 1}
                                  currentEvent={currentEvent}
                                  currentPartnership={currentPartnership}
                                  taskCompletion={taskCompletion}
                                  csrfToken={csrfToken}
                                  taskCompletionLabel={taskCompletionLabel} />
            ))}
          </div>
        </div>
      ) : null}
      {pendingTaskCompletions.length > 0 && completedTaskCompletions.length > 0 ? (
        <hr style={{"margin": "10px 0px"}} />
      ) : null}
      {completedTaskCompletions.length > 0 ? (
        <div className="card-body">
          <div className="form-row">
            {completedTaskCompletions.map((taskCompletion, index) => (
              <ToDoTaskCompletion key={index}
                                  isLast={index === completedTaskCompletions.length - 1}
                                  currentEvent={currentEvent}
                                  currentPartnership={currentPartnership}
                                  taskCompletion={taskCompletion}
                                  csrfToken={csrfToken}
                                  taskCompletionLabel={taskCompletionLabel} />
            ))}
          </div>
        </div>
      ) : null}
      {pendingTaskCompletions.length === 0 && completedTaskCompletions.length === 0 ? (
        <div className="card-body">
          <div className='row'>
            <div className='col text-center my-3'>
              <i className="fad fa-check-circle mb-2 text-success"
                 style={{"fontSize": "55px"}}></i>
              <p className="mb-0 text-dark"
                 style={{"fontSize": "16px"}}>
                <strong>You're all caught up!</strong>
              </p>
              <p className="mb-0 text-muted"
                 style={{"fontSize": "14px"}}>
                You have no assignments to complete.
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
};

Tasks.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  pendingTaskCompletions: PropTypes.array,
  csrfToken: PropTypes.string.isRequired,
  taskCompletionLabel: PropTypes.func.isRequired,
  completedTaskCompletions: PropTypes.array,
  unarchivedTaskCompletions: PropTypes.array,
  archivedTaskCompletions: PropTypes.array,
  taskMenuState: PropTypes.string,
  taskMenuStateChanged: PropTypes.func.isRequired
};

export default Tasks;
