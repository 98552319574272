/* eslint-disable import/prefer-default-export */

export const OPTIONABLE_TYPES = ["CustomFields::Radio", "CustomFields::CheckboxGroup", "CustomFields::Select"];

export const TASK_CHANGED = 'TASK_CHANGED';
export const UPDATE_TASK_ORGANIZATION_TAGS = 'UPDATE_TASK_ORGANIZATION_TAGS';
export const UPDATE_TASK_DUE_AT = 'UPDATE_TASK_DUE_AT';
export const UPDATE_TASK_PUBLISHED_AT = 'UPDATE_TASK_PUBLISHED_AT';
export const UPDATE_TASK_DESCRIPTION = 'UPDATE_TASK_DESCRIPTION';
export const UPDATE_TASK_PINNED = 'UPDATE_TASK_PINNED';
export const UPDATE_TASK_LOCKED = 'UPDATE_TASK_LOCKED';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_PARTNERSHIP_TASK_COMPLETION_ENABLED = 'UPDATE_PARTNERSHIP_TASK_COMPLETION_ENABLED';
export const UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED = 'UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED';
export const UPDATE_TASK_COMPLETION_DUE_AT = 'UPDATE_TASK_COMPLETION_DUE_AT';

export const DELETE_FIELD = 'DELETE_FIELD';
export const ADD_FIELD = 'ADD_FIELD';
export const FIELD_DRAG_END = 'FIELD_DRAG_END';

export const CUSTOM_FIELD_CHANGED = 'CUSTOM_FIELD_CHANGED';
export const CUSTOM_FIELD_TYPE_CHANGED = 'CUSTOM_FIELD_TYPE_CHANGED';
export const CUSTOM_FIELD_NAME_CHANGED = 'CUSTOM_FIELD_NAME_CHANGED';
export const CUSTOM_FIELD_CHARACTER_LIMIT_CHANGED = 'CUSTOM_FIELD_CHARACTER_LIMIT_CHANGED';
export const CUSTOM_FIELD_REQUIRED_CHANGED = 'CUSTOM_FIELD_REQUIRED_CHANGED';
export const CUSTOM_FIELD_TEXT_OPTIONS_CHANGED = 'CUSTOM_FIELD_TEXT_OPTIONS_CHANGED';
export const CUSTOM_FIELD_ENABLE_CHOICE_LIMITS_CHANGED = 'CUSTOM_FIELD_ENABLE_CHOICE_LIMITS_CHANGED';
export const CUSTOM_FIELD_SAVED = 'CUSTOM_FIELD_SAVED';

export const SELECT_ALL_CLICKED = 'SELECT_ALL_CLICKED';

export const INVENTORY_ADDED = 'INVENTORY_ADDED';
export const INVENTORY_CHANGED = 'INVENTORY_CHANGED';
export const INVENTORY_DRAG_END = 'INVENTORY_DRAG_END';

export const UPDATE_ICON = 'UPDATE_ICON';
