/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  MESSAGE_RECEIVED,
  UPDATE_CHAT
} from '../constants/chatConstants';

export const messageReceived = (message) => ({
  type: MESSAGE_RECEIVED,
  message
});

export const updateChat = (chat) => ({
  type: UPDATE_CHAT,
  chat
});
