import PropTypes from 'prop-types';
import React from 'react';

import Froala from '../../Froala/components/Froala';
import Flatpickr from "react-flatpickr";

import Tippy from '@tippyjs/react';
import { ToastContainer, toast } from 'react-toastify';

const canSubmitForm = (announcement) => {
  var baseConditions = (
    announcement
      && announcement.text
      && announcement.text.length > 0
      && announcement.scheduled_at
  );

  return (
    baseConditions
      && (
        announcement.notify === "all"
          || (
            announcement.announceable_type
              && announcement.announceable_id
              && announcement.segment
              && announcement.segment.length > 0
          )
      )
  );
}

const parseDate = function(date){
  if(typeof(date) === "string"){
    var parsed = new Date(date);
    return parsed;
  } else {
    return date;
  }
}

const getTaskOptionFromId = function(taskOptions, taskId){
  return taskOptions.find((o) => o.id === taskId);
}

const getTagOptionFromId = function(tagOptions, tagId){
  return tagOptions.find((o) => o.id === tagId);
}

const getOrganizationPartnershipOptionFromId = function(organizationPartnershipOptions, organizationPartnershipId){
  return organizationPartnershipOptions.find((o) => o.id === organizationPartnershipId);
}

const AnnouncementForm = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  announcement,
  announcementChanged,
  taskOptions,
  tagOptions,
  partnershipCount,
  createAnnouncement,
  updateAnnouncement,
  organizationPartnershipOptions
}) => (
  <form onSubmit={
          (e) => {
            e.preventDefault();

            if(!canSubmitForm(announcement)){
              return false;
            }

            if(announcement.id){
              updateAnnouncement(csrfToken, currentEvent, currentPartnership, announcement);
            } else {
              createAnnouncement(csrfToken, currentEvent, currentPartnership, announcement);
            }
          }
        }>
    <ToastContainer />
    <input type="hidden" name="announcement[announceable_type]" value={announcement.announceable_type || ""} />
    <div className="form-group mb-0">
      <label className="form-control-label text-muted">
        When to post
      </label>
    </div>
    <div className="form-group">
      <div className="custom-control custom-radio">
        <input type="radio"
               id="announcement-scheduled-now-true"
               checked={announcement.schedule_now || false}
               onChange={
                 (e) => {
                   var now = new Date();
                   var updated = Object.assign({}, announcement, {
                     schedule_now: true,
                     scheduled_at: now
                   });
                   announcementChanged(updated);
                 }
               }
               className="custom-control-input" />
        <label className="custom-control-label"
               htmlFor="announcement-scheduled-now-true">
          Now
        </label>
      </div>
      <div className="custom-control custom-radio">
        <input type="radio"
               id="announcement-scheduled-now-false"
               checked={!announcement.schedule_now || false}
               onChange={
                 (e) => {
                   var now = new Date();
                   var updated = Object.assign({}, announcement, {
                     schedule_now: false,
                     scheduled_at: now
                   });

                   announcementChanged(updated);
                 }
               }
               className="custom-control-input" />
        <label className="custom-control-label w-100"
               htmlFor="announcement-scheduled-now-false">
          Schedule for later
          {!announcement.schedule_now ? (
            <Flatpickr
              data-enable-time
              options={{
                dateFormat: "F j, Y - h:i K"
              }}
              value={parseDate(announcement.scheduled_at) || ""}
              onChange={
                (dates) => {
                  var updated = Object.assign({}, announcement, {scheduled_at: dates[0]});
                  announcementChanged(updated);
                }
              }
              style={{"maxWidth": "400px"}}
              className="form-control"
            />
          ) : null}
        </label>
      </div>
    </div>
    <div className="form-group mb-0">
      <label className="form-control-label text-muted">
        Who to notify
        <Tippy content={"Only individuals who have accepted their invitation will be notified via email."}
               theme='material'
               animation={'shift-away'}>
          <i className="fas fa-info-circle ml-2"></i>
        </Tippy>
      </label>
    </div>
    <div className="form-group">
      <div className="custom-control custom-radio">
        <input type="radio"
               id="announcement-notify-all-true"
               checked={announcement.notify === "all" || false}
               onChange={
                 (e) => {
                   var updated = Object.assign({}, announcement, {
                     notify: "all",
                     announceable_id: "",
                     announceable_type: "",
                     segment: "",
                     segmentable_id: "",
                     segmentable_type: ""
                   });
                   announcementChanged(updated);
                 }
               }
               className="custom-control-input" />
        <label className="custom-control-label"
               htmlFor="announcement-notify-all-true">
          All {partnershipCount} individuals
        </label>
      </div>
      <div className="custom-control custom-radio">
        <input type="radio"
               id="announcement-notify-task"
               checked={announcement.notify === "task" || false}
               onChange={
                 (e) => {
                   var updated = Object.assign({}, announcement, {
                     notify: "task",
                     announceable_id: "",
                     announceable_type: "",
                     segment: "all",
                     segmentable_id: "",
                     segmentable_type: ""
                   });
                   announcementChanged(updated);
                 }
               }
               className="custom-control-input" />
        <label className="custom-control-label w-100"
               htmlFor="announcement-notify-task">
          Based on task or resource
          {announcement.notify === "task" ? (
            <div className="row">
              <div className="col-12 mb-1">
                <select className="form-control"
                        style={{"maxWidth": "400px"}}
                        onChange={
                          (e) => {
                            var taskId = (e.target.value === "" ? null : parseInt(e.target.value));
                            var task = getTaskOptionFromId(taskOptions, taskId);

                            var updated = Object.assign({}, announcement, {
                              announceable_id: taskId,
                              announceable_type: "Task",
                              segment: (task && task.pinned ? "all" : announcement.segment)
                            });

                            announcementChanged(updated);
                          }
                        }
                        value={announcement.announceable_id || ""}>
                  <option value="">Select a task or resource</option>
                  {taskOptions.map((option, index) =>
                    <option key={index} value={option.id}>
                      {option.label}
                    </option>
                  )}
                </select>
              </div>
              {announcement.announceable_id ? (
                <div className="col-12">
                  <div className="form-group mb-0">
                    <div className="custom-control custom-radio">
                      <input type="radio"
                             id="announcement-segment-all"
                             checked={announcement.segment === "all"}
                             onChange={
                               (e) => {
                                 var updated = Object.assign({}, announcement, {segment: "all"});
                                 announcementChanged(updated);
                               }
                             }
                             className="custom-control-input" />
                      <label className="custom-control-label"
                             htmlFor="announcement-segment-all">
                        {getTaskOptionFromId(taskOptions, announcement.announceable_id).allCount} assigned total
                      </label>
                    </div>
                    {!getTaskOptionFromId(taskOptions, announcement.announceable_id).pinned ? (
                      <React.Fragment>
                        <div className="custom-control custom-radio">
                          <input type="radio"
                                 id="announcement-segment-completed"
                                 checked={announcement.segment === "completed"}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, announcement, {segment: "completed"});
                                     announcementChanged(updated);
                                   }
                                 }
                                 className="custom-control-input" />
                          <label className="custom-control-label"
                                 htmlFor="announcement-segment-completed">
                            {getTaskOptionFromId(taskOptions, announcement.announceable_id).completedCount} completed assignments
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input type="radio"
                                 id="announcement-segment-incomplete"
                                 checked={announcement.segment === "incomplete"}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, announcement, {segment: "incomplete"});
                                     announcementChanged(updated);
                                   }
                                 }
                                 className="custom-control-input" />
                          <label className="custom-control-label"
                                 htmlFor="announcement-segment-incomplete">
                            {getTaskOptionFromId(taskOptions, announcement.announceable_id).incompleteCount} incomplete assignments
                          </label>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </label>
      </div>
      <div className="custom-control custom-radio">
        <input type="radio"
                id="announcement-notify-tag"
                checked={announcement.notify === "tag" || false}
                onChange={
                  (e) => {
                    var updated = Object.assign({}, announcement, {
                      notify: "tag",
                      announceable_id: "",
                      announceable_type: "",
                      segment: "",
                      segmentable_id: "",
                      segmentable_type: ""
                    });
                    announcementChanged(updated);
                  }
                }
                className="custom-control-input" />
        <label className="custom-control-label w-100"
                htmlFor="announcement-notify-tag">
          Based on tag
          {announcement.notify === "tag" ? (
            <div className="row">
              <div className="col-12 mb-1">
                <select className="form-control"
                        style={{"maxWidth": "400px"}}
                        onChange={
                          (e) => {
                            var tagId = (e.target.value === "" ? null : parseInt(e.target.value));

                            var updated = Object.assign({}, announcement, {
                              announceable_id: tagId,
                              announceable_type: "Tag",
                              segment: "organization_partnerships"
                            });

                            announcementChanged(updated);
                          }
                        }
                        value={announcement.announceable_id || ""}>
                  <option value="">Select a tag</option>
                  {tagOptions.map((option, index) =>
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  )}
                </select>
              </div>
              {announcement.announceable_id ? (
                <div className="col-12">
                  <div className="form-group mb-0">
                    <div className="custom-control custom-radio">
                      <input type="radio"
                             id="announcement-segment-organization-partnerships"
                             checked={announcement.segment === "organization_partnerships"}
                             onChange={
                               (e) => {
                                 var updated = Object.assign({}, announcement, {segment: "organization_partnerships"});
                                 announcementChanged(updated);
                               }
                             }
                             className="custom-control-input" />
                      <label className="custom-control-label"
                             htmlFor="announcement-segment-organization-partnerships">
                        {getTagOptionFromId(tagOptions, announcement.announceable_id).organizationsCount} Organizations
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </label>
      </div>
      <div className="custom-control custom-radio">
        <input type="radio"
               id="announcement-notify-organization-partnership"
               checked={announcement.notify === "organization_partnership" || false}
               onChange={
                 (e) => {
                   var updated = Object.assign({}, announcement, {
                     notify: "organization_partnership",
                     announceable_id: "",
                     announceable_type: "",
                     segment: "",
                     segmentable_id: "",
                     segmentable_type: ""
                   });
                   announcementChanged(updated);
                 }
               }
               className="custom-control-input" />
        <label className="custom-control-label"
               htmlFor="announcement-notify-organization-partnership">
          Based on organization
          {announcement.notify === "organization_partnership" ? (
            <div className="row">
              <div className="col-12 mb-1">
                <select className="form-control"
                        style={{"maxWidth": "400px"}}
                        onChange={
                          (e) => {
                            var organizationPartnershipId = (e.target.value === "" ? null : parseInt(e.target.value));

                            var updated = Object.assign({}, announcement, {
                              announceable_id: organizationPartnershipId,
                              announceable_type: "OrganizationPartnership",
                              segment: "partnerships",
                              segmentable_id: "",
                              segmentable_type: ""
                            });

                            announcementChanged(updated);
                          }
                        }
                        value={announcement.announceable_id || ""}>
                  <option value="">Select an organization</option>
                  {organizationPartnershipOptions.map((option, index) =>
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  )}
                </select>
              </div>
              {announcement.announceable_id ? (
                <div className="col-12">
                  <div className="form-group mb-0">
                    <div className="custom-control custom-radio">
                      <input type="radio"
                             id="announcement-segment-partnerships"
                             name={"notify-organization-partnership-segment"}
                             checked={announcement.segment === "partnerships"}
                             onChange={
                               (e) => {
                                  var updated = Object.assign({}, announcement, {
                                    segment: "partnerships",
                                    segmentable_id: "",
                                    segmentable_type: ""
                                  });

                                  announcementChanged(updated);
                               }
                             }
                             className="custom-control-input" />
                      <label className="custom-control-label"
                             htmlFor="announcement-segment-partnerships">
                        {getOrganizationPartnershipOptionFromId(organizationPartnershipOptions, announcement.announceable_id).partnershipsCount} Individual(s) dashboard
                      </label>
                    </div>
                    {getOrganizationPartnershipOptionFromId(organizationPartnershipOptions, announcement.announceable_id).taskCompletions.sort((a, b) => b.task.type.localeCompare(a.task.type) || a.task.label.localeCompare(b.task.label)).filter((tc) => !tc.task.archived).map((taskCompletion, index) =>
                      <div key={index} className="custom-control custom-radio">
                        <input type="radio"
                              id={`task-completion-option-${taskCompletion.id}`}
                              name={"notify-organization-partnership-segment"}
                              checked={
                                announcement.segment === "task_completion"
                                  && announcement.segmentable_type === "TaskCompletion"
                                  && announcement.segmentable_id === taskCompletion.id
                              }
                              onChange={
                                (e) => {
                                  var updated = Object.assign({}, announcement, {
                                    segment: "task_completion",
                                    segmentable_id: taskCompletion.id,
                                    segmentable_type: "TaskCompletion"
                                  });

                                  announcementChanged(updated);
                                }
                              }
                              className="custom-control-input" />
                        <label className="custom-control-label"
                              htmlFor={`task-completion-option-${taskCompletion.id}`}>
                          {taskCompletion.name}
                          {taskCompletion.task.type === "Resource" ? (
                            <span className="ml-1 text-info small">RESOURCE</span>
                          ) : null}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </label>
      </div>
    </div>
    <div className="form-group">
      <label className="form-control-label text-muted"
             htmlFor="task-description">
        Message <abbr title="required">*</abbr>
      </label>
      <Froala id="task-description"
              onModelChange={
                (html) => {
                  var updated = Object.assign({}, announcement, {text: html});
                  announcementChanged(updated);
                }
              }
              toolbarButtons={{
                'moreText': {
                  'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'textColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                },
                'moreParagraph': {
                  'buttons': ['formatOLSimple', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                },
                'moreRich': {
                  'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
                },
                'moreMisc': {
                  'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                  'align': 'right',
                  'buttonsVisible': 2
                }
              }}
              currentEvent={currentEvent}
              currentPartnership={currentPartnership}
              csrfToken={csrfToken}
              model={announcement.text || ""} />
      <input type="hidden"
             name="task[description]"
             value={announcement.text || ""} />
    </div>
    <div className="row">
      <div className="col-12 text-right">
        <a href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/announcements"}>
          Cancel
        </a>
        <button type="submit"
                disabled={!canSubmitForm(announcement)}
                className="btn btn-primary ml-2">
          Save
        </button>
      </div>
    </div>
  </form>
);

AnnouncementForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  announcement: PropTypes.object.isRequired,
  announcementChanged: PropTypes.func.isRequired,
  taskOptions: PropTypes.array,
  tagOptions: PropTypes.array,
  partnershipCount: PropTypes.string,
  createAnnouncement: PropTypes.func.isRequired,
  updateAnnouncement: PropTypes.func.isRequired,
  organizationPartnershipOptions: PropTypes.array
};

export default AnnouncementForm;
