import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

const ToastComponent = ({
}) => {
  useEffect(() => {
    //...
  }, [])

  return (
    <ToastContainer />
  )
};

ToastComponent.propTypes = {
};

export default ToastComponent;
