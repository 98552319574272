import { combineReducers } from 'redux';
import {
  EXAMPLE
} from '../constants/authorizationsManagerConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const individualOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const organizationPartnershipOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const taskOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const resourceOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const params = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const internalTaskOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const authorizationsManagerReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  individualOptions,
  organizationPartnershipOptions,
  taskOptions,
  resourceOptions,
  params,
  internalTaskOptions
});

export default authorizationsManagerReducer;
