import PropTypes from 'prop-types';
import React from 'react';

import { ToastContainer, toast } from 'react-toastify';

import InputField from './InputField';
import TextAreaField from './TextAreaField';
import RadioField from './RadioField';
import CheckBoxField from './CheckBoxField';
import SelectField from './SelectField';
import FileField from './FileField';
import InventoryField from './InventoryField';

const canSubmitForm = function(taskCompletion, inventories){
  var hasRequiredFields = taskCompletion
    .custom_field_values
    .filter((customFieldValue) => customFieldValue.custom_field.required)
    .every((customFieldValue) => customFieldValue.value && customFieldValue.value.length > 0)

  var hasRequiredInventories = inventories
    .filter((inventory) => inventory.required)
    .every((inventory) => {
      return (
        inventory.quantity && parseInt(inventory.quantity) >= 0
      );
    })

  return (
    !taskCompletion.completed_at
      && !taskCompletion.task.locked
      && hasRequiredFields
      && hasRequiredInventories
  );
}

const hasGoToLink = function(task){
  return (
    task.has_go_to_link
  );
}

const canSaveTaskCompletionProgress = function(taskCompletion){
  return (
    !taskCompletion.completed_at
      && !taskCompletion.task.locked
  );
}

const isDisabled = function(taskCompletion){
  return (
    !!taskCompletion.completed_at
      || taskCompletion.task.locked
  );
}

const shouldGoToStripe = function(inventories){
  return inventories.some((inventory) =>
    inventory.quantity && parseInt(inventory.quantity) > 0
  );
}

const TaskCompletionForm = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  taskCompletion,
  inputFieldChanged,
  textAreaFieldChanged,
  radioFieldChanged,
  checkBoxFieldChanged,
  selectFieldChanged,
  uploadCustomFieldFileUpload,
  saveTaskCompletionProgress,
  completeTaskCompletion,
  task,
  goToTaskCompletionLink,
  fileFieldValueRemoved,
  canUpdateTaskCompletion,
  inventories,
  createOrderAndGoToStripe,
  inventoryChanged
}) => (
  <React.Fragment>
    <ToastContainer />
    <form onSubmit={
            (e) => {
              e.preventDefault();

              if(!canSubmitForm(taskCompletion, inventories)){
                return false;
              }

              if(shouldGoToStripe(inventories)){
                createOrderAndGoToStripe(csrfToken, currentEvent, currentPartnership, taskCompletion, inventories);
              } else if(hasGoToLink(task)){
                goToTaskCompletionLink(csrfToken, currentEvent, currentPartnership, taskCompletion);
              } else {
                completeTaskCompletion(csrfToken, currentEvent, currentPartnership, taskCompletion);
              }
            }
          }>
      <div className='row mb-4'>
        <div className="col-12 task-completion-form-groups">
          {taskCompletion.custom_field_values.sort((a, b) => a.custom_field.position - b.custom_field.position).map((customFieldValue, index) => (
            customFieldValue.custom_field.type === "CustomFields::Text" ? (
              <InputField key={index}
                          index={index}
                          inputFieldChanged={inputFieldChanged}
                          disabled={isDisabled(taskCompletion)}
                          customFieldValue={customFieldValue} />
            ) : customFieldValue.custom_field.type === "CustomFields::TextArea" ? (
              <TextAreaField key={index}
                             index={index}
                             textAreaFieldChanged={textAreaFieldChanged}
                             disabled={isDisabled(taskCompletion)}
                             customFieldValue={customFieldValue} />
            ) : customFieldValue.custom_field.type === "CustomFields::Radio" ? (
              <RadioField key={index}
                          index={index}
                          radioFieldChanged={radioFieldChanged}
                          disabled={isDisabled(taskCompletion)}
                          customFieldValue={customFieldValue} />
            ) : customFieldValue.custom_field.type === "CustomFields::CheckboxGroup" ? (
              <CheckBoxField key={index}
                             index={index}
                             checkBoxFieldChanged={checkBoxFieldChanged}
                             disabled={isDisabled(taskCompletion)}
                             customFieldValue={customFieldValue} />
            ) : customFieldValue.custom_field.type === "CustomFields::Select" ? (
              <SelectField key={index}
                           index={index}
                           selectFieldChanged={selectFieldChanged}
                           disabled={isDisabled(taskCompletion)}
                           customFieldValue={customFieldValue} />
            ) : customFieldValue.custom_field.type === "CustomFields::File" ? (
              <FileField key={index}
                         index={index}
                         disabled={isDisabled(taskCompletion)}
                         uploadCustomFieldFileUpload={uploadCustomFieldFileUpload}
                         csrfToken={csrfToken}
                         currentEvent={currentEvent}
                         currentPartnership={currentPartnership}
                         fileFieldValueRemoved={fileFieldValueRemoved}
                         customFieldValue={customFieldValue} />
            ) : null
          ))}
          {inventories.map((inventory, index) => (
            <InventoryField key={index}
                            index={index}
                            inventoryChanged={inventoryChanged}
                            currentEvent={currentEvent}
                            currentPartnership={currentPartnership}
                            disabled={isDisabled(taskCompletion)}
                            inventory={inventory} />
          ))}
          <br />
          <div style={{"padding": "0 20px"}}>
            {taskCompletion.task.locked ? (
              <div className="text-center small mt-2">
                <i className="fas fa-lock mr-2" aria-hidden="true"></i>
                This task has been locked
              </div>
            ) : !canUpdateTaskCompletion ? (
              <div className="text-center small mt-2">
                <i className="fas fa-lock mr-2" aria-hidden="true"></i>
                You have read-only access to this task
              </div>
            ) : (
              <div className="form-row">
                <div className="col">
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();

                         if(canSaveTaskCompletionProgress(taskCompletion)){
                           saveTaskCompletionProgress(csrfToken, currentEvent, currentPartnership, taskCompletion);
                         }
                       }
                     }
                     className={"btn btn-outline-secondary btn-block " + (canSaveTaskCompletionProgress(taskCompletion) ? "" : "disabled")}>
                    Save Progress
                  </a>
                </div>
                <div className="col">
                  <input type="submit"
                         disabled={!canSubmitForm(taskCompletion, inventories)}
                         value={
                            shouldGoToStripe(inventories) ? (
                              "Review Payment & Complete"
                            ) : hasGoToLink(task) ? (
                              "Continue"
                            ) : (
                              "Complete"
                            )
                          }
                         className="btn btn btn-primary btn-block" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  </React.Fragment>
);

TaskCompletionForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  taskCompletion: PropTypes.object.isRequired,
  inputFieldChanged: PropTypes.func.isRequired,
  textAreaFieldChanged: PropTypes.func.isRequired,
  radioFieldChanged: PropTypes.func.isRequired,
  checkBoxFieldChanged: PropTypes.func.isRequired,
  selectFieldChanged: PropTypes.func.isRequired,
  uploadCustomFieldFileUpload: PropTypes.func.isRequired,
  saveTaskCompletionProgress: PropTypes.func.isRequired,
  completeTaskCompletion: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  goToTaskCompletionLink: PropTypes.func.isRequired,
  fileFieldValueRemoved: PropTypes.func.isRequired,
  canUpdateTaskCompletion: PropTypes.bool,
  inventories: PropTypes.array,
  createOrderAndGoToStripe: PropTypes.func.isRequired,
  inventoryChanged: PropTypes.func.isRequired
};

export default TaskCompletionForm;
