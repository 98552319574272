import { combineReducers } from 'redux';
import {
  CURRENT_PARTNERSHIP_CHANGED
} from '../constants/impressionChartConstants';

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_PARTNERSHIP_CHANGED:
      return action.partnership;
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const hostImpressions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const individualImpressions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const impressionChartReducer = combineReducers({
  currentEvent,
  currentPartnership,
  csrfToken,
  hostImpressions,
  individualImpressions
});

export default impressionChartReducer;
