// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ResourceIndex from '../components/ResourceIndex';
import * as actions from '../actions/resourceIndexActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  resources: state.resources,
  fieldDragEnd: state.fieldDragEnd,
  canUpdateResource: state.canUpdateResource
});

const mapDispatchToProps = (dispatch) => {
  return {
    fieldDragEnd: (result) => dispatch(actions.fieldDragEnd(result))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ResourceIndex);
