import PropTypes from 'prop-types';
import React from 'react';

const Resource = ({
  currentEvent,
  currentPartnership,
  index,
  resource,
  provided,
  canUpdateResource
}) => (
  <tr ref={provided.innerRef} {...provided.draggableProps}>
    <td className="overflow-ellipsis" style={{width: "70%"}}>
      <input type="hidden"
            name={"event[resources_attributes][" + index + "][id]"}
            value={resource.id || ""} />
      <input type="hidden"
            name={"event[resources_attributes][" + index + "][position]"}
            value={resource.position || ""} />
      <a {...provided.dragHandleProps}
          href="#"
          onClick={
            (e) => {
              e.preventDefault();
            }
          }
          className={"text-dark " + (canUpdateResource ? "" : "d-none")}>
        <i className="fal fa-arrows mr-2 small"></i>
      </a>
      {!resource.is_published ? (
        <span className="badge badge-primary py-1 px-2 mr-2" style={{"fontSize": "10px"}}>DRAFT</span>
      ) : null}
      <strong>
        <a href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/resources/" + resource.id} className="text-dark">
          {resource.label}
        </a>
      </strong>
    </td>
    <td className="overflow-ellipsis text-right" style={{width: "15%"}}>
      {resource.assigned_count}
    </td>
    <td className="overflow-ellipsis text-right" style={{width: "10%"}}>
      <a className="text-muted"
         href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/impressions?q%5Bimpressionable_of_TaskCompletion_type_task_id_eq%5D=" + resource.id}>
        {resource.impression_count}
      </a>
    </td>
    <td style={{width: "5%"}}>
      {canUpdateResource ? (
        <div className="dropdown dropleft open">
          <button aria-expanded="false" aria-haspopup="true" className="dropdown-no-icon btn btn-link dropdown-toggle text-muted" data-toggle="dropdown" id="dropdownMenuButton" type="button">
            <i className="fas fa-ellipsis-v"></i>
          </button>
          <div aria-labelledby="dropdownMenuButton" className="dropdown-menu">
            <a className="dropdown-item" title="Edit Resource" href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/resources/" + resource.id + "/edit"}>
              <i className="fa fa-pencil fa-fw mr-1"></i>
              Edit Resource
            </a><div className="dropdown-divider"></div>
            <a className="dropdown-item" title="Copy Resource" href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/resources/new?copy_resource_id=" + resource.id}>
              <i className="fa fa-copy fa-fw mr-1"></i>
              Copy Resource
            </a><div className="dropdown-divider"></div>
            {resource.archived ? (
              <a className="dropdown-item" title="Archive Resource" data-method="post" href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/resources/" + resource.id + "/toggle_archive"}>
                <i className="fa fa-archive fa-fw mr-1"></i>
                Move to Active
              </a>
            ) : (
              <a className="dropdown-item" title="Archive Resource" data-method="post" href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/resources/" + resource.id + "/toggle_archive"}>
                <i className="fa fa-archive fa-fw mr-1"></i>
                Archive Resource
              </a>
            )}
            <div className="dropdown-divider"></div>
            <a data-confirm="Are you sure?" className="dropdown-item" rel="nofollow" data-method="delete" href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/resources/" + resource.id}>
              <i className="fa fa-trash fa-fw mr-1"></i>
              Delete Resource
            </a>
          </div>
        </div>
      ) : (
        <span>&nbsp;</span>
      )}
    </td>
  </tr>
);

Resource.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  resource: PropTypes.object.isRequired,
  provided: PropTypes.object.isRequired,
  canUpdateResource: PropTypes.bool
};

export default Resource;
