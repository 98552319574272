import { connect } from 'react-redux';
import ToastComponent from '../components/ToastComponent';
import * as actions from '../actions/toastComponentActionCreators';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastComponent);
