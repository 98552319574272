import PropTypes from 'prop-types';
import React from 'react';

import CustomField from './CustomField';

import Popup from "reactjs-popup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  OPTIONABLE_TYPES
} from '../constants/taskFormConstants';

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const nonDestroyedCustomFields = function(task){
  return task.custom_fields.filter((customField) => !customField._destroy);
}

const canSubmitCustomField = function(customField){
  return (
    customField.type
      && customField.type.length > 0
      && customField.name
      && customField.name.length > 0
  )
}

const CustomFieldForm = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  task,
  deleteField,
  customField,
  customFieldChanged,
  customFieldIndex,
  addField,
  customFieldTypeChanged,
  customFieldNameChanged,
  customFieldCharacterLimitChanged,
  customFieldRequiredChanged,
  customFieldSaved,
  fieldDragEnd,
  customFieldTextOptionsChanged,
  customFieldEnableChoiceLimitsChanged
}) => (
  <div className='form-row'>
    {task && task.custom_fields.length > 0 ? (
      <div className={"col-12 " + (nonDestroyedCustomFields(task).length === 0 ? "hide" : "")}>
        <div className="p-2"
             style={{
               "border": "1px solid #cccccc",
               "borderRadius": "8px"
             }}>
          <DragDropContext onDragEnd={
                             (result) => {
                               if (!result.destination) {
                                 return false;
                               }

                               fieldDragEnd(result);
                             }
                           }>
            <Droppable droppableId="task-custom-fields">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {task.custom_fields.map((customField, index) =>
                    <Draggable key={customField.uuid} draggableId={customField.uuid} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <CustomField key={index}
                                       index={index}
                                       csrfToken={csrfToken}
                                       currentEvent={currentEvent}
                                       currentPartnership={currentPartnership}
                                       task={task}
                                       deleteField={deleteField}
                                       customField={customField}
                                       provided={provided}
                                       customFieldChanged={customFieldChanged} />
                        </div>
                      )}
                    </Draggable>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    ) : null}
    <div className="col-12 pt-1">
      <a href="#"
         className={task.pinned ? "disabled" : ""}
         onClick={
           (e) => {
             e.preventDefault();

             if(task.pinned){
               return false;
             }

             addField(task.custom_fields.length);
           }
         }>
        <i className="fas fa-plus-circle mr-1"></i>
        Add field
      </a>
    </div>
    <Popup
      open={Object.keys(customField).length > 0}
      contentStyle={modalContentStyle}
      className={"popup-modal"}
      closeOnDocumentClick={false}
      onClose={() => customFieldChanged({}, 0)}
    >
      <div className="popup-modal-container">
        <div className="row">
          <div className="col">
            <h5 className="text-dark"
                style={{"marginBottom": "16px"}}>
              {customField.id ? (
                <strong>Edit field</strong>
              ) : (
                <strong>New field</strong>
              )}
            </h5>
          </div>
          <div className="col-xs-auto">
            <a href="#"
               className="text-dark"
               style={{"paddingRight": "15px"}}
               onClick={
                 (e) => {
                   e.preventDefault();
                   customFieldChanged({}, 0);
                 }
               } >
              <i className="fal fa-times"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={
              (e) => {
                e.preventDefault();

                if (canSubmitCustomField(customField)){
                  customFieldSaved(customField, customFieldIndex);
                }
              }
            }>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-12 col-md-6">
                    <select className="form-control form-control-sm"
                            style={{"borderRadius": "8px"}}
                            value={customField.type || ""}
                            onChange={
                              (e) => {
                                customFieldTypeChanged(e.target.value);
                              }
                            }
                            id="custom-field-type">
                      <option value="" disabled={true}>Select type</option>
                      <option value="CustomFields::Text">Short answer</option>
                      <option value="CustomFields::TextArea">Paragraph</option>
                      <option value="CustomFields::Radio">Multiple choice</option>
                      <option value="CustomFields::CheckboxGroup">Checkboxes</option>
                      <option value="CustomFields::Select">Dropdown</option>
                      <option value="CustomFields::File">File upload</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <input type="text"
                       id="custom-field-name"
                       value={customField.name || ""}
                       onChange={
                         (e) => {
                           customFieldNameChanged(e.target.value);
                         }
                       }
                       style={{"borderRadius": "8px"}}
                       placeholder={"Name"}
                       className="form-control form-control-sm" />
              </div>
              {customField.type === "CustomFields::Text" || customField.type === "CustomFields::TextArea" ? (
                <div className="form-group">
                  <input type="number"
                          id="custom-field-character-limit"
                          value={customField.character_limit || ""}
                          onChange={
                            (e) => {
                              customFieldCharacterLimitChanged(e.target.value);
                            }
                          }
                          style={{"borderRadius": "8px"}}
                          placeholder={"Character Limit (Optional)"}
                          className="form-control form-control-sm" />
                </div>
               ) : null}
              {OPTIONABLE_TYPES.includes(customField.type) ? (
                <>
                  <div className="form-group">
                    <label htmlFor="custom-field-text-options"
                            style={{"fontSize": "14px"}}
                            className='text-muted mb-0'>
                      Options (One option per line)
                    </label>
                    <textarea className="form-control small"
                              value={customField.text_options || ""}
                              onChange={
                                (e) =>  {
                                  customFieldTextOptionsChanged(e.target.value);
                                }
                              }
                              id="custom-field-text-options" rows="3">
                    </textarea>
                  </div>
                  {currentEvent.enable_choice_limits && ["CustomFields::Radio", "CustomFields::Select"].includes(customField.type) ? (
                    <div className="form-group mb-0">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                              className="custom-control-input"
                              checked={customField.enable_choice_limits || false}
                              value={customField.enable_choice_limits || false}
                              onChange={
                                (e) =>  {
                                  customFieldEnableChoiceLimitsChanged(e.target.checked);
                                }
                              }
                              id="custom-field-enable-choice-limits" />
                        <label className="custom-control-label"
                              htmlFor="custom-field-enable-choice-limits">
                          Remove option from list once selected
                        </label>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox"
                         className="custom-control-input"
                         checked={customField.required || false}
                         value={customField.required || false}
                         onChange={
                           (e) =>  {
                             customFieldRequiredChanged(e.target.checked);
                           }
                         }
                         id="custom-field-required" />
                  <label className="custom-control-label"
                         htmlFor="custom-field-required">
                    Require a response to submit
                  </label>
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col-12 text-right">
                  <a href="#"
                     className="small"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         customFieldChanged({}, 0);
                       }
                     } >
                    <strong>Cancel</strong>
                  </a>
                  <button type="submit"
                          disabled={!canSubmitCustomField(customField)}
                          style={{"borderRadius": "8px"}}
                          className="btn btn-primary ml-3 btn-sm">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>
  </div>
);

CustomFieldForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  deleteField: PropTypes.func.isRequired,
  customField: PropTypes.object,
  customFieldChanged: PropTypes.func.isRequired,
  customFieldIndex: PropTypes.number,
  addField: PropTypes.func.isRequired,
  customFieldTypeChanged: PropTypes.func.isRequired,
  customFieldNameChanged: PropTypes.func.isRequired,
  customFieldCharacterLimitChanged: PropTypes.func.isRequired,
  customFieldRequiredChanged: PropTypes.func.isRequired,
  customFieldSaved: PropTypes.func.isRequired,
  fieldDragEnd: PropTypes.func.isRequired,
  customFieldTextOptionsChanged: PropTypes.func.isRequired,
  customFieldEnableChoiceLimitsChanged: PropTypes.func.isRequired
};

export default CustomFieldForm;
