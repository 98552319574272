import { combineReducers } from 'redux';
import {
  TABLE_DATA_CHANGED,
  IS_LOADING_TABLE_DATA_CHANGED,
  SELECTED_DATA_CHANGED
} from '../constants/taskGridConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tasks = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tableData = (state = {}, action) => {
  switch (action.type) {
    case TABLE_DATA_CHANGED:
      return action.tableData;
    default:
      return state;
  }
};

const isLoadingTableData = (state = false, action) => {
  switch (action.type) {
    case IS_LOADING_TABLE_DATA_CHANGED:
      return action.isLoadingTableData;
    default:
      return state;
  }
};

const openTaskId = (state = null, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedData = (state = [], action) => {
  switch (action.type) {
    case SELECTED_DATA_CHANGED:
      return action.selectedData;
    default:
      return state;
  }
};

const taskGridReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  tasks,
  tableData,
  isLoadingTableData,
  openTaskId,
  selectedData
});

export default taskGridReducer;
