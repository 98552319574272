import { combineReducers } from 'redux';
import {
  TAG_NAME_CHANGED
} from '../constants/tagFormConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tag = (state = {}, action) => {
  switch (action.type) {
    case TAG_NAME_CHANGED:
      return Object.assign({}, state, {name: action.name});
    default:
      return state;
  }
};

const tagFormReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  tag
});

export default tagFormReducer;
