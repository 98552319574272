import PropTypes from 'prop-types';
import React from 'react';

import FroalaEditorComponent from 'react-froala-wysiwyg';

// import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/table.min.js';
// import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/trim_video.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
// import 'froala-editor/js/plugins/line_height.min.js';
// import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/edit_in_popup.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/forms.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/cryptojs.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
// import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/link.min.js';
// import 'froala-editor/js/plugins/files_manager.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/help.min.js';

const Froala = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  id,
  model,
  onModelChange,
  fontSizeDefaultSelection,
  toolbarButtons
}) => (
  <FroalaEditorComponent id={id}
                         config={{
                           key: 'MZC1rE1D4H4H3B16C11A8jF1QUg1Xc2OZE1ABVJRDRNGGUH1ITrA1C7A6E5E1D4E4E1B9A6==',
                           toolbarButtons: toolbarButtons,
                           toolbarSticky: false,
                           heightMax: 500,
                           fontSizeDefaultSelection: fontSizeDefaultSelection,
                           fontFamily: {
                            "Arial,Helvetica,sans-serif": "Arial",
                            "Arial Black,Gadget,sans-serif": "Arial Black",
                            "Comic Sans MS,cursive": "Comic Sans MS",
                            "Courier New,Courier,monospace": "Courier New",
                            "Georgia,serif": "Georgia",
                            "Gill Sans,sans-serif": "Gill Sans",
                            "Impact,Charcoal,sans-serif": "Impact",
                            "Lucida Console,Monaco,monospace": "Lucida Console",
                            "Lucida Sans Unicode,Lucida Grande,sans-serif": "Lucida Sans Unicode",
                            "Palatino Linotype,Book Antiqua,Palatino,serif": "Palatino Linotype",
                            "Tahoma,Geneva,sans-serif": "Tahoma",
                            "Times New Roman,Times,serif": "Times New Roman",
                            "Trebuchet MS,Helvetica,sans-serif": "Trebuchet MS",
                            "Verdana,Geneva,sans-serif": "Verdana"
                           },
                           fontFamilySelection: true,
                           quickInsertEnabled: false,
                           charCounterCount: false,
                           videoUpload: true,
                           fontSize: ['8', '10', '12', '14', '16', '18', '20', '22'],
                           imageUploadParam: 'image[file]',
                           imageUploadURL: "/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/images",
                           imageUploadMethod: 'POST',
                           imageMaxSize: 1024 * 1024 * 25,
                           imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'svg+xml'],
                           imageUploadParams: {'authenticity_token': csrfToken},
                           videoUploadParam: 'video[file]',
                           videoUploadURL: "/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/videos",
                           videoUploadMethod: 'POST',
                           videoMaxSize: 1024 * 1024 * 500,
                           videoUploadParams: {'authenticity_token': csrfToken},
                           videoEditButtons: ['videoReplace', 'videoRemove', 'videoDisplay', 'videoAlign', 'videoSize'],
                           fileUploadParam: 'upload[file]',
                           fileUploadURL: "/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/uploads",
                           fileUploadMethod: 'POST',
                           fileMaxSize: 1024 * 1024 * 25,
                           fileAllowedTypes: ['*'],
                           fileUploadParams: {'authenticity_token': csrfToken},
                           tableStyles: {
                            noborder: 'No Border'
                          }
                         }}
                         onModelChange={
                           (html) => {
                             onModelChange(html);
                           }
                         }
                         model={model} />
);

Froala.defaultProps = {
  fontSizeDefaultSelection: "12",
  toolbarButtons: {
    'moreText': {
      'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
    },
    'moreParagraph': {
      'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
    },
    'moreRich': {
      'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
    },
    'moreMisc': {
      'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
      'align': 'right',
      'buttonsVisible': 2
    }
  }
};

Froala.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  id: PropTypes.string,
  model: PropTypes.string.isRequired,
  onModelChange: PropTypes.func.isRequired,
  fontSizeDefaultSelection: PropTypes.string,
  toolbarButtons: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

export default Froala;
