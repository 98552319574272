import PropTypes from 'prop-types';
import React from 'react';

import FieldLabel from './FieldLabel';
import HiddenInputs from './HiddenInputs';

const allOptions = function(customFieldValue, disabled){
  var options = customFieldValue.custom_field.options;

  if (disabled) {
    (customFieldValue.value || []).forEach(value => {
      if (!options.includes(value)){
        options.push(value);
      }
    });
  }

  return options;
}

const CheckBoxField = ({
  customFieldValue,
  index,
  checkBoxFieldChanged,
  disabled
}) => (
  <div className="form-group">
    <FieldLabel index={index}
                customFieldValue={customFieldValue} />
    <HiddenInputs index={index}
                  customFieldValue={customFieldValue} />
    <input type="hidden"
           name={"task_completion[custom_field_values_attributes][" + index + "][type]"}
           value={"CustomFieldValues::CheckboxGroup"} />
    <input type="hidden"
           name={"task_completion[custom_field_values_attributes][" + index + "][value][]"}
           value={""} />
    {allOptions(customFieldValue, disabled).map((optionValue, optionIndex) => (
      <div key={optionIndex}
           className="custom-control custom-checkbox">
        <input type="checkbox"
               className="custom-control-input"
               disabled={disabled}
               name={"task_completion[custom_field_values_attributes][" + index + "][value][]"}
               checked={(customFieldValue.value || []).includes(optionValue)}
               value={optionValue}
               onChange={
                 (e) => {
                   checkBoxFieldChanged(index, e.target.value);
                 }
               }
               id={"custom-field-option-" + index + "-" + optionIndex} />
        <label className="custom-control-label"
               htmlFor={"custom-field-option-" + index + "-" + optionIndex}>
          {optionValue}
        </label>
      </div>
    ))}
  </div>
);

CheckBoxField.propTypes = {
  customFieldValue: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  checkBoxFieldChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default CheckBoxField;
