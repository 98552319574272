/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  CURRENT_PARTNERSHIP_CHANGED,
  WELCOME_MESSAGE_OPENED_CHANGED,
  TASK_MENU_STATE_CHANGED,
  RESOURCE_MENU_STATE_CHANGED
} from '../constants/dashboardConstants';

export const dismissWelcomeMessage = (dispatch, csrfToken, currentEvent, currentPartnership) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    let now = new Date();
    return axios.patch("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/partnerships/" + currentPartnership.id + ".json", {
        partnership: {
          dismissed_welcome_message_at: now
        }
      })
      .then(({ data }) => {
        dispatch(currentPartnershipChanged(data));
      });
  };
};

export const currentPartnershipChanged = (partnership) => ({
  type: CURRENT_PARTNERSHIP_CHANGED,
  partnership
});

export const welcomeMessageOpenedChanged = (welcomeMessageOpened) => ({
  type: WELCOME_MESSAGE_OPENED_CHANGED,
  welcomeMessageOpened
});

export const taskMenuStateChanged = (taskMenuState) => ({
  type: TASK_MENU_STATE_CHANGED,
  taskMenuState
});

export const resourceMenuStateChanged = (resourceMenuState) => ({
  type: RESOURCE_MENU_STATE_CHANGED,
  resourceMenuState
});
