/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  TAG_NAME_CHANGED
} from '../constants/tagFormConstants';

export const tagNameChanged = (name) => ({
  type: TAG_NAME_CHANGED,
  name
});
