/* eslint-disable import/prefer-default-export */

export const CURRENT_PARTNERSHIP_CHANGED = 'CURRENT_PARTNERSHIP_CHANGED';
export const WELCOME_MESSAGE_OPENED_CHANGED = 'WELCOME_MESSAGE_OPENED_CHANGED';
export const TASK_MENU_STATE_CHANGED = 'TASK_MENU_STATE_CHANGED';
export const RESOURCE_MENU_STATE_CHANGED = 'RESOURCE_MENU_STATE_CHANGED';

export const ALL_TASKS = 'ALL_TASKS';
export const ARCHIVED_TASKS = 'ARCHIVED_TASKS';

export const ALL_RESOURCES = 'ALL_RESOURCES';
export const ARCHIVED_RESOURCES = 'ARCHIVED_RESOURCES';
