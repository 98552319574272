import { combineReducers } from 'redux';
import {
  EMAIL_CHANGED,
  INVITATION_CREATED,
  ERRORS_CHANGED,
  INVITATION_DELETED,
  ORGANIZATION_MEMBERSHIP_DELETED
} from '../constants/organizationMembershipManagerConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const organization = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const email = (state = "", action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return action.email;
    case INVITATION_CREATED:
      return "";
    default:
      return state;
  }
};

const invitations = (state = [], action) => {
  switch (action.type) {
    case INVITATION_CREATED:
      return [...state, action.invitation];
    case INVITATION_DELETED:
      return state.filter((invitation) => {
        return invitation.id !== action.invitation.id;
      });
    default:
      return state;
  }
};

const errors = (state = [], action) => {
  switch (action.type) {
    case ERRORS_CHANGED:
      return action.errors;
    default:
      return state;
  }
};

const organizationMemberships = (state = [], action) => {
  switch (action.type) {
    case ORGANIZATION_MEMBERSHIP_DELETED:
      return state.filter((organizationMembership) => {
        return organizationMembership.id !== action.organizationMembership.id;
      });
    default:
      return state;
  }
};

const organizationMembershipManagerReducer = combineReducers({
  csrfToken, organization, email, invitations, errors, organizationMemberships
});

export default organizationMembershipManagerReducer;
