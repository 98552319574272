import { combineReducers } from 'redux';
import {
  SELECT_ALL_CLICKED,
  UPDATE_TASK_ORGANIZATION_TAGS,
  UPDATE_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  UPDATE_TASK_COMPLETION_DUE_AT,
  UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  UPDATE_TASK_DUE_AT,
  UPDATE_TASK_PUBLISHED_AT,
  UPDATE_TASK_DESCRIPTION,
  UPDATE_ICON,
  UPDATE_TASK_LABEL,
  UPDATE_TASK_PINNED,
  DELETE_FIELD,
  CUSTOM_FIELD_CHANGED,
  ADD_FIELD,
  CUSTOM_FIELD_TYPE_CHANGED,
  CUSTOM_FIELD_NAME_CHANGED,
  CUSTOM_FIELD_CHARACTER_LIMIT_CHANGED,
  CUSTOM_FIELD_REQUIRED_CHANGED,
  CUSTOM_FIELD_SAVED,
  OPTIONABLE_TYPES,
  UPDATE_TASK_LOCKED,
  FIELD_DRAG_END,
  TASK_CHANGED,
  INVENTORY_ADDED,
  INVENTORY_CHANGED,
  INVENTORY_DRAG_END,
  CUSTOM_FIELD_TEXT_OPTIONS_CHANGED,
  CUSTOM_FIELD_ENABLE_CHOICE_LIMITS_CHANGED
} from '../constants/taskFormConstants';

import {
  enabledTaskCompletionsByTag
} from '../../tagLogic/reducers/tagLogic';

import { v4 as uuidv4 } from 'uuid';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const task = (state = {}, action) => {
  switch (action.type) {
    case TASK_CHANGED:
      return action.task;
    case UPDATE_TASK_ORGANIZATION_TAGS:
      var taskCompletions = enabledTaskCompletionsByTag(
        state.organization_partnership_task_completions,
        state.organization_tags,
        action.tags,
        (state.type === 'InternalTask' ? "for_organization_partnership" : "partnerable"),
        "tag_ids"
      );

      return Object.assign({}, state, {
        organization_tags: action.tags,
        organization_partnership_task_completions: taskCompletions
      });
    case UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED:
      if (state.type == 'ToDo' || state.type == 'Resource') {
        var organizationPartnershipTaskCompletions = [...state.organization_partnership_task_completions].map((taskCompletion) => {
          if(taskCompletion.partnerable.id === action.organizationPartnership.id){
            return Object.assign({}, taskCompletion, {enabled: action.enabled});
          } else {
            return taskCompletion;
          }
        });
      } else {
        var organizationPartnershipTaskCompletions = [...state.organization_partnership_task_completions].map((taskCompletion) => {
          if(taskCompletion.for_organization_partnership.id === action.organizationPartnership.id){
            return Object.assign({}, taskCompletion, {enabled: action.enabled});
          } else {
            return taskCompletion;
          }
        });
      }

      return Object.assign({}, state, {organization_partnership_task_completions: organizationPartnershipTaskCompletions});
    case UPDATE_TASK_COMPLETION_DUE_AT:
      var organizationPartnershipTaskCompletions = [...state.organization_partnership_task_completions].map((taskCompletion) => {
        if(taskCompletion.partnerable.id === action.organizationPartnership.id){
          return Object.assign({}, taskCompletion, {due_at: action.date[0]});
        } else {
          return taskCompletion;
        }
      });

      return Object.assign({}, state, {organization_partnership_task_completions: organizationPartnershipTaskCompletions});
    case UPDATE_TASK_DUE_AT:
      return Object.assign({}, state, {due_at: action.date});
    case UPDATE_TASK_PUBLISHED_AT:
      return Object.assign({}, state, {published_at: action.date});
    case UPDATE_TASK_DESCRIPTION:
      return Object.assign({}, state, {description: action.html});
    case UPDATE_TASK_LABEL:
      return Object.assign({}, state, {label: action.label});
    case UPDATE_ICON:
      return Object.assign({}, state, {icon: action.icon});
    case UPDATE_TASK_PINNED:
      var customFields = [...state.custom_fields].map((customField) =>
        Object.assign({}, customField, {_destroy: true})
      );

      return Object.assign({}, state, {
        pinned: action.pinned,
        due_at: null,
        advance: false,
        due_in_number: null,
        due_in_time_period: null,
        custom_fields: customFields,
        has_go_to_link: false,
        go_to_link: "",
        go_to_link_instructions: ""
      });
    case UPDATE_TASK_LOCKED:
      return Object.assign({}, state, {
        locked: action.locked
      });
    case DELETE_FIELD:
      var customFields = [...state.custom_fields].map((customField, index) => {
        if (index === action.index){
          return Object.assign({}, customField, {_destroy: true});
        } else {
          return customField;
        }
      });

      return Object.assign({}, state, {custom_fields: customFields});
    case ADD_FIELD:
      return Object.assign({}, state, {custom_fields: [...state.custom_fields, action.customField]});
    case FIELD_DRAG_END:
      var startIndex = action.result.source.index;
      var endIndex = action.result.destination.index;

      var customFields = [...state.custom_fields];
      var [removed] = customFields.splice(startIndex, 1);
      customFields.splice(endIndex, 0, removed);

      customFields = customFields.map((customField, index) =>
        Object.assign({}, customField, {position: (index + 1)})
      );

      return Object.assign({}, state, {custom_fields: customFields});
    case CUSTOM_FIELD_SAVED:
      var customFields = [...state.custom_fields].map((customField, index) => {
        if (index === action.index){
          return Object.assign({}, action.customField, {_destroy: false});
        } else {
          return customField;
        }
      });

      return Object.assign({}, state, {custom_fields: customFields});
    case SELECT_ALL_CLICKED:
      var organizationPartnershipTaskCompletions = [...state.organization_partnership_task_completions].map(optc => {
        return Object.assign({}, optc, { enabled: action.value });
      });

      return Object.assign({}, state, {organization_partnership_task_completions: organizationPartnershipTaskCompletions});
    default:
      return state;
  }
};

const iconOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const customField = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FIELD_CHANGED:
      return action.customField;
    case CUSTOM_FIELD_TYPE_CHANGED:
      var textOptions        = state.text_options;
      var characterLimit     = state.character_limit;
      var enableChoiceLimits = state.enable_choice_limits;
      var fromType           = state.type;
      var fromTypeOptionable = OPTIONABLE_TYPES.includes(fromType);
      var fromTypeText       = ["CustomFields::Text", "CustomFields::TextArea"].includes(fromType);
      var toType             = action.fieldType;
      var toTypeOptionable   = OPTIONABLE_TYPES.includes(toType);
      var toTypeText         = ["CustomFields::Text", "CustomFields::TextArea"].includes(toType);

      if(!fromTypeOptionable && !toTypeOptionable){
        // no change
      } else if (!fromTypeOptionable && toTypeOptionable) {
        textOptions = "Option 1\nOption 2\nOption 3";
      } else if (fromTypeOptionable && !toTypeOptionable) {
        textOptions = "";
        enableChoiceLimits = false;
      } else if (fromTypeOptionable && toTypeOptionable) {
        // no change
      }

      if(!fromTypeText && toTypeText){
        characterLimit = "";
      }

      return Object.assign({}, state, {
        type: toType,
        text_options: textOptions,
        enable_choice_limits: enableChoiceLimits,
        character_limit: characterLimit
      });
    case CUSTOM_FIELD_NAME_CHANGED:
      return Object.assign({}, state, {name: action.name});
    case CUSTOM_FIELD_CHARACTER_LIMIT_CHANGED:
      var character_limit = action.limit;

      if (character_limit < 0) {
        character_limit = 0;
      }

      return Object.assign({}, state, {character_limit: action.limit});
    case CUSTOM_FIELD_REQUIRED_CHANGED:
      return Object.assign({}, state, {required: action.required});
    case CUSTOM_FIELD_TEXT_OPTIONS_CHANGED:
      return Object.assign({}, state, {text_options: action.textOptions});
    case CUSTOM_FIELD_ENABLE_CHOICE_LIMITS_CHANGED:
      return Object.assign({}, state, {enable_choice_limits: action.enableChoiceLimits});
    case CUSTOM_FIELD_SAVED:
      return {};
    default:
      return state;
  }
};

const customFieldIndex = (state = 0, action) => {
  switch (action.type) {
    case CUSTOM_FIELD_CHANGED:
      return action.index;
    case CUSTOM_FIELD_SAVED:
      return 0;
    default:
      return state;
  }
};

const resourcesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const inventories = (state = [], action) => {
  switch (action.type) {
    case INVENTORY_ADDED:
      return [...state, {
        min_per_order: "0",
        max_per_order: "10",
        tag_ids: [],
        can_delete: true,
        uuid: uuidv4()
      }];
    case INVENTORY_CHANGED:
      var inventories = [...state];
      inventories[action.index] = action.inventory;
      return inventories;
    case INVENTORY_DRAG_END:
      var startIndex = action.result.source.index;
      var endIndex = action.result.destination.index;

      var newState = [...state];
      var [removed] = newState.splice(startIndex, 1);
      newState.splice(endIndex, 0, removed);

      return newState;
    default:
      return state;
  }
};

const chargesEnabled = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tagOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const taskFormReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  task,
  iconOptions,
  customField,
  customFieldIndex,
  resourcesOptions,
  inventories,
  chargesEnabled,
  tagOptions
});

export default taskFormReducer;
