// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OrganizationPartnershipForm from '../components/OrganizationPartnershipForm';
import * as actions from '../actions/organizationPartnershipFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  organizationPartnership: state.organizationPartnership
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrganizationPartnershipTags: (tags) => dispatch(actions.updateOrganizationPartnershipTags(tags)),
    updateOrganizationPartnershipTaskCompletionEnabled: (task, enabled) => dispatch(actions.updateOrganizationPartnershipTaskCompletionEnabled(task, enabled)),
    organizationPartnershipChanged: (organizationPartnership) => dispatch(actions.organizationPartnershipChanged(organizationPartnership))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPartnershipForm);
