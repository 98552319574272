import PropTypes from 'prop-types';
import React from 'react';

import FieldLabel from './FieldLabel';
import HiddenInputs from './HiddenInputs';

const TextAreaField = ({
  customFieldValue,
  index,
  textAreaFieldChanged,
  disabled
}) => (
  <div className="form-group">
    <FieldLabel index={index}
                customFieldValue={customFieldValue} />
    <HiddenInputs index={index}
                  customFieldValue={customFieldValue} />
    <input type="hidden"
           name={"task_completion[custom_field_values_attributes][" + index + "][type]"}
           value={"CustomFieldValues::TextArea"} />
    <textarea type="text"
           className="form-control"
           rows="3"
           disabled={disabled}
           name={"task_completion[custom_field_values_attributes][" + index + "][value]"}
           value={customFieldValue.value || ""}
           onChange={
             (e) => {
               textAreaFieldChanged(index, e.target.value);
             }
           }
           id={"custom-field-value-" + index}>
    </textarea>
    {typeof(customFieldValue.custom_field.character_limit) === 'number' ? (
      <small className="text-muted">
        Characters: {customFieldValue.value ? customFieldValue.value.length : 0} / {customFieldValue.custom_field.character_limit}
      </small>
    ) : null}
  </div>
);

TextAreaField.propTypes = {
  customFieldValue: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  textAreaFieldChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default TextAreaField;
