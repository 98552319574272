import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

var _ = require('lodash');
import AsyncSelect from 'react-select/async';
const axios = require('axios').default;

import TaskCompletionLink from '../../TaskForm/components/TaskCompletionLink';

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const debouncedFetch = _.debounce((inputValue, callback, currentEvent, currentPartnership) => {
  new Promise(resolve => {
    axios.get('/e/' + currentEvent.permalink + '/p/' + currentPartnership.id + '/tags/autocomplete', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(newOptions);
      })
  });
}, 250);

const debouncedPartnershipFetch = _.debounce((inputValue, callback, currentEvent, currentPartnership) => {
  new Promise(resolve => {
    axios.get('/e/' + currentEvent.permalink + '/p/' + currentPartnership.id + '/partnerships/autocomplete', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(newOptions);
      })
  });
}, 250);

const OrganizationPartnershipForm = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  organizationPartnership,
  updateOrganizationPartnershipTags,
  updateOrganizationPartnershipTaskCompletionEnabled,
  organizationPartnershipChanged
}) => {

  return (
    <div className='row'>
      <div className='col-12'>
        <div className="form-group">
          <label className="form-control-label"
                htmlFor="organization-partnership-name">
            Name <abbr title="required">*</abbr>
          </label>
          <input className="form-control"
                type="text"
                onChange={
                  (e) => {
                    var updated = Object.assign({}, organizationPartnership, {name: e.target.value});
                    organizationPartnershipChanged(updated);
                  }
                }
                value={organizationPartnership.name || ""}
                name="organization_partnership[name]"
                id="organization-partnership-name" />
        </div>
        <div className="form-group">
          <label htmlFor="organization-partnership-tags"
                className="text-muted">
            Tag(s)
          </label>
          <AsyncSelect cacheOptions
                      styles={selectStyles}
                      menuPortalTarget={document.querySelector('body')}
                      defaultOptions
                      isMulti
                      id="organization-partnership-tags"
                      name="organization_partnership[tag_ids][]"
                      onChange={(options) => { updateOrganizationPartnershipTags(options || []) }}
                      value={ organizationPartnership.tags }
                      loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback, currentEvent, currentPartnership)} />
        </div>
        <div className="form-group">
          <input type="hidden"
                 name={"organization_partnership[partnership_ids][]"}
                 value={""} />
          {organizationPartnership.partnerships.map((partnership, index) => (
            <input key={index}
                   type="hidden"
                   name={"organization_partnership[partnership_ids][]"}
                   value={partnership.value || ""} />
          ))}
          <label htmlFor="organization-partnership-partnerships"
                className="text-muted">
            Individual(s)
          </label>
          <AsyncSelect cacheOptions
                      styles={selectStyles}
                      menuPortalTarget={document.querySelector('body')}
                      defaultOptions
                      isMulti
                      id="organization-partnership-partnerships"
                      onChange={
                        (options) => {
                          var updated = Object.assign({}, organizationPartnership, {
                            partnerships: (options || [])
                          });

                          organizationPartnershipChanged(updated);
                        }
                      }
                      value={ organizationPartnership.partnerships }
                      loadOptions={(inputValue, callback) => debouncedPartnershipFetch(inputValue, callback, currentEvent, currentPartnership)} />
        </div>
        <div className="form-group">
          <label htmlFor="organization-partnership-tags"
                className="text-muted">
            Tasks & Resources
          </label>
          {organizationPartnership.task_completions.sort((a, b) => b.task.type.localeCompare(a.task.type) || a.task.label.localeCompare(b.task.label)).filter((tc) => !tc.task.archived).map((taskCompletion, index) => (
            <div key={index}
                className="custom-control custom-checkbox">
              <input type="hidden"
                    name={"organization_partnership[task_completions_attributes]" + index + "[id]"}
                    value={taskCompletion.id || ""} />
              <input type="hidden"
                    name={"organization_partnership[task_completions_attributes]" + index + "[task_id]"}
                    value={taskCompletion.task.id} />
              <input type="hidden"
                    name={"organization_partnership[task_completions_attributes]" + index + "[enabled]"}
                    value={taskCompletion.enabled} />
              <input type="checkbox"
                    className="custom-control-input"
                    checked={taskCompletion.enabled}
                    onChange={
                      (e) => {
                        updateOrganizationPartnershipTaskCompletionEnabled(taskCompletion.task, e.target.checked);
                      }
                    }
                    id={"organization-partnership-task-completion-" + index} />
              <label className="custom-control-label"
                    htmlFor={"organization-partnership-task-completion-" + index}>
                {taskCompletion.task.label}
                {taskCompletion.task.type === "Resource" ? (
                  <span className="ml-1 text-info small">RESOURCE</span>
                ) : null}
              </label>
              {/*
              <TaskCompletionLink csrfToken={csrfToken}
                                  currentEvent={currentEvent}
                                  currentPartnership={currentPartnership}
                                  taskCompletion={taskCompletion} />
              */}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
};

OrganizationPartnershipForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  organizationPartnership: PropTypes.object.isRequired,
  updateOrganizationPartnershipTags: PropTypes.func.isRequired,
  updateOrganizationPartnershipTaskCompletionEnabled: PropTypes.func.isRequired,
  organizationPartnershipChanged: PropTypes.func.isRequired
};

export default OrganizationPartnershipForm;
