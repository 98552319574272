import PropTypes from 'prop-types';
import React from 'react';

import Tippy from '@tippyjs/react';

const GoToLinkSubnav = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  taskCompletion,
  task
}) => (
  <div className="row w-100 m-0">
    <div className="col-6 col-lg-3 d-flex align-items-start justify-content-start order-1 order-lg-1 mb-3">
      <a href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/task_completions/" + taskCompletion.id + "/edit"}>
        Cancel
      </a>
    </div>
    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center order-3 order-lg-2">
      <ul className="fa-ul my-2">
        <li>
          <span className="fa-li">
            <i className="fas fa-check-square" aria-hidden="true"></i>
          </span>
          1. Add required information to PartnerHQ task
        </li>
        <li>
          <span className="fa-li">
            <i className="fas far fa-square" aria-hidden="true"></i>
          </span>
          2. Review this page carefully and take any necessary action
          {task.go_to_link_instructions && task.go_to_link_instructions.length > 0 ? (
            <Tippy content={<div dangerouslySetInnerHTML={{__html: task.simple_format_go_to_link_instructions}} />}
                   theme='material'
                   animation={'shift-away'}>
              <i className="fas fa-info-circle ml-2 text-muted"></i>
            </Tippy>
          ) : null}
        </li>
        <li>
          <span className="fa-li">
            <i className="fas far fa-square" aria-hidden="true"></i>
          </span>
          3. Click "Complete" when finished
        </li>
      </ul>
    </div>
    <div className="col-6 col-lg-3 d-flex align-items-start justify-content-end order-2 order-lg-3 mb-3">
      <a className="btn btn-primary"
         rel="nofollow"
         data-method="post"
         href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/task_completions/" + taskCompletion.id + "/complete"}>
        Complete
      </a>
    </div>
  </div>
);

GoToLinkSubnav.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  taskCompletion: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired
};

export default GoToLinkSubnav;
