import { combineReducers } from 'redux';
import {
  FILTER_CHANGED
} from '../constants/eventsConstants';

const activeFilter = (state = "", action) => {
  switch (action.type) {
    case FILTER_CHANGED:
      return action.filter;
    default:
      return state;
  }
};

const events = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const token = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const eventsReducer = combineReducers({
  activeFilter,
  events,
  token
});

export default eventsReducer;
