/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  TASK_COMPLETION_CHANGED
} from '../constants/taskCompletionAssignmentConstants';

export const updateTaskCompletionAssignedToPartnershipId = (dispatch, csrfToken, event, taskCompletion, currentPartnership, partnershipId) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/e/" + event.permalink + "/p/" + currentPartnership.id + "/task_completions/" + taskCompletion.id + ".json", {
        task_completion: {
          assigned_partnership_id: partnershipId
        }
      })
      .then(({ data }) => {
        dispatch(taskCompletionChanged(data));
      })
  };
};

export const taskCompletionChanged = (taskCompletion) => ({
  type: TASK_COMPLETION_CHANGED,
  taskCompletion
});
