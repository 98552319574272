// Simple example of a React "smart" component

import { connect } from 'react-redux';
import TaskCompletionForm from '../components/TaskCompletionForm';
import * as actions from '../actions/taskCompletionFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  taskCompletion: state.taskCompletion,
  task: state.task,
  canUpdateTaskCompletion: state.canUpdateTaskCompletion,
  inventories: state.inventories
});

const mapDispatchToProps = (dispatch) => {
  return {
    inputFieldChanged: (index, value) => dispatch(actions.inputFieldChanged(index, value)),
    textAreaFieldChanged: (index, value) => dispatch(actions.textAreaFieldChanged(index, value)),
    radioFieldChanged: (index, value) => dispatch(actions.radioFieldChanged(index, value)),
    checkBoxFieldChanged: (index, value) => dispatch(actions.checkBoxFieldChanged(index, value)),
    selectFieldChanged: (index, value) => dispatch(actions.selectFieldChanged(index, value)),
    uploadCustomFieldFileUpload: (csrfToken, currentEvent, currentPartnership, file, index) => dispatch(actions.uploadCustomFieldFileUpload(dispatch, csrfToken, currentEvent, currentPartnership, file, index)),
    saveTaskCompletionProgress: (csrfToken, currentEvent, currentPartnership, taskCompletion) => dispatch(actions.saveTaskCompletionProgress(dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion)),
    completeTaskCompletion: (csrfToken, currentEvent, currentPartnership, taskCompletion) => dispatch(actions.completeTaskCompletion(dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion)),
    goToTaskCompletionLink: (csrfToken, currentEvent, currentPartnership, taskCompletion) => dispatch(actions.goToTaskCompletionLink(dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion)),
    fileFieldValueRemoved: (index) => dispatch(actions.fileFieldValueRemoved(index)),
    createOrderAndGoToStripe: (csrfToken, currentEvent, currentPartnership, taskCompletion, inventories) => dispatch(actions.createOrderAndGoToStripe(dispatch, csrfToken, currentEvent, currentPartnership, taskCompletion, inventories)),
    inventoryChanged: (inventory) => dispatch(actions.inventoryChanged(inventory))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(TaskCompletionForm);
