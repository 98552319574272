import PropTypes from 'prop-types';
import React from 'react';

import MG from 'metrics-graphics';

class LineChart extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    title: PropTypes.string
  };

  static defaultProps = {
    data: [],
    title: ""
  }

  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      title: this.props.title
    };
  }

  componentDidMount() {
    let graphData = this.state.data.map((dataPoint) => {
      let parsed = new Date(dataPoint.date);
      return Object.assign({}, dataPoint, {date: parsed});
    });

    let chartOptions = {
      title: this.state.title,
      data: graphData,
      full_width: true,
      height: 250,
      target: this.el,
      x_accessor: 'date',
      y_accessor: 'value'
    }

    if(graphData.length === 0){
      chartOptions.chart_type   = "missing-data";
      chartOptions.missing_text = "No data yet";
    }

    MG.data_graphic(chartOptions);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({data: this.props.data});
    }

    if (prevProps.title !== this.props.title) {
      this.setState({title: this.props.title});
    }
  }

  render() {
    return (
      <div ref={el => this.el = el}></div>
    );
  }
}

export default LineChart;
