import { combineReducers } from 'redux';
import {
  UPDATE_ORGANIZATION_PARTNERSHIP_TAGS,
  UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  ORGANIZATION_PARTNERSHIP_CHANGED
} from '../constants/organizationPartnershipFormConstants';

import {
  enabledTaskCompletionsByTag
} from '../../tagLogic/reducers/tagLogic';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const organizationPartnership = (state = {}, action) => {
  switch (action.type) {
    case ORGANIZATION_PARTNERSHIP_CHANGED:
      return action.organizationPartnership;
    case UPDATE_ORGANIZATION_PARTNERSHIP_TAGS:
      var taskCompletions = enabledTaskCompletionsByTag(
        state.task_completions,
        state.tags,
        action.tags,
        "task",
        "organization_tag_ids"
      );

      return Object.assign({}, state, {
        task_completions: taskCompletions,
        tags: action.tags
      });
    case UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED:
      var taskCompletions = [...state.task_completions].map((taskCompletion) => {
        if(taskCompletion.task.id === action.task.id){
          return Object.assign({}, taskCompletion, {enabled: action.enabled});
        } else {
          return taskCompletion;
        }
      });

      return Object.assign({}, state, {task_completions: taskCompletions});
    default:
      return state;
  }
};

const taskFormReducer = combineReducers({
  csrfToken, currentEvent, currentPartnership, organizationPartnership
});

export default taskFormReducer;
