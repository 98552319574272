/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import {
  EMAIL_CHANGED,
  INVITATION_CREATED,
  ERRORS_CHANGED,
  INVITATION_DELETED,
  ORGANIZATION_MEMBERSHIP_DELETED
} from '../constants/organizationMembershipManagerConstants';

export const emailChanged = (email) => ({
  type: EMAIL_CHANGED,
  email
});

export const createInvitation = (dispatch, csrfToken, organization, email) => {
  return dispatch => {
    dispatch(errorsChanged([]));
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/organizations/" + organization.id + "/organization_invitations", {
        invitation: {
          email: email
        }
      })
      .then(({ data }) => {
        dispatch(invitationCreated(data));
      })
      .catch((error) => {
        dispatch(errorsChanged(error.response.data));
      });
  };
};

export const invitationCreated = (invitation) => ({
  type: INVITATION_CREATED,
  invitation
});

export const errorsChanged = (errors) => ({
  type: ERRORS_CHANGED,
  errors
});

export const deleteInvitation = (dispatch, csrfToken, organization, invitation) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/organizations/" + organization.id + "/organization_invitations/" + invitation.id)
      .then(({ data }) => {
        dispatch(invitationDeleted(data));
      });
  };
};

export const invitationDeleted = (invitation) => ({
  type: INVITATION_DELETED,
  invitation
});

export const deleteOrganizationMembership = (dispatch, csrfToken, organization, organizationMembership) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/organizations/" + organization.permalink + "/organization_memberships/" + organizationMembership.id)
      .then(({ data }) => {
        dispatch(organizationMembershipDeleted(data));
      });
  };
};

export const organizationMembershipDeleted = (organizationMembership) => ({
  type: ORGANIZATION_MEMBERSHIP_DELETED,
  organizationMembership
});
