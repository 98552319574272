import { combineReducers } from 'redux';
import {
  CURRENT_PARTNERSHIP_CHANGED,
  WELCOME_MESSAGE_OPENED_CHANGED,
  TASK_MENU_STATE_CHANGED,
  RESOURCE_MENU_STATE_CHANGED,
  ALL_TASKS,
  ALL_RESOURCES
} from '../constants/dashboardConstants';

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUserPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_PARTNERSHIP_CHANGED:
      return action.partnership;
    default:
      return state;
  }
};

const resourceTaskCompletions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const welcomeMessageOpened = (state = false, action) => {
  switch (action.type) {
    case WELCOME_MESSAGE_OPENED_CHANGED:
      return action.welcomeMessageOpened;
    default:
      return state;
  }
};

const todoTaskCompletions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const chatChannelAvatars = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const chatChannelId = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const chatChannelType = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const chatChannelMessages = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const webSocketHost = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const taskMenuState = (state = ALL_TASKS, action) => {
  switch (action.type) {
    case TASK_MENU_STATE_CHANGED:
      return action.taskMenuState;
    default:
      return state;
  }
};

const resourceMenuState = (state = ALL_RESOURCES, action) => {
  switch (action.type) {
    case RESOURCE_MENU_STATE_CHANGED:
      return action.resourceMenuState;
    default:
      return state;
  }
};

const chatHistoryExists = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const canSeeSettings = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const canComment = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const canSeePermissions = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const canSeeInternalTasks = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dashboardReducer = combineReducers({
  currentEvent,
  currentPartnership,
  currentUserPartnership,
  resourceTaskCompletions,
  welcomeMessageOpened,
  csrfToken,
  todoTaskCompletions,
  chatChannelAvatars,
  chatChannelId,
  chatChannelType,
  chatChannelMessages,
  webSocketHost,
  taskMenuState,
  resourceMenuState,
  chatHistoryExists,
  canSeeSettings,
  canComment,
  canSeePermissions,
  canSeeInternalTasks
});

export default dashboardReducer;
