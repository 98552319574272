import { combineReducers } from 'redux';
import {
} from '../constants/copyTextComponentConstants';

const text = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const copyTextComponentReducer = combineReducers({
  text
});

export default copyTextComponentReducer;
