// Simple example of a React "smart" component

import { connect } from 'react-redux';
import AuthorizationsManager from '../components/AuthorizationsManager';
import * as actions from '../actions/authorizationsManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  individualOptions: state.individualOptions,
  organizationPartnershipOptions: state.organizationPartnershipOptions,
  taskOptions: state.taskOptions,
  resourceOptions: state.resourceOptions,
  params: state.params,
  internalTaskOptions: state.internalTaskOptions
});

const mapDispatchToProps = (dispatch) => {
  return {
    announcementChanged: (announcement) => dispatch(actions.announcementChanged(announcement))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationsManager);
