import PropTypes from 'prop-types';
import React from 'react';

const TagForm = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  tag,
  tagNameChanged
}) => (
  <div className='row'>
    <div className="col-12">
      <div className="form-group">
        <label className="form-control-label text-muted"
               htmlFor="tag-name">
          Name <abbr title="required">*</abbr>
        </label>
        <input className="form-control"
              type="text"
              name="tag[name]"
              onChange={
                (e) => {
                  tagNameChanged(e.target.value);
                }
              }
              value={tag.name || ""}
              id="tag-name" />
      </div>
    </div>
  </div>
);

TagForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  tagNameChanged: PropTypes.func.isRequired
};

export default TagForm;
