import PropTypes from 'prop-types';
import React from 'react';

import LineChart from '../../LineChart/components/LineChart';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ImpressionChart = ({
  currentEvent,
  currentPartnership,
  csrfToken,
  hostImpressions,
  individualImpressions
}) => (
  <div className="row">
    <div className="col-12 mt-5">
      <LineChart data={hostImpressions}
                 title={"Recent Host Activity (" + numberWithCommas(hostImpressions.reduce((total, data) => total + data.value, 0)) + ")"} />
    </div>
    <div className="col-12 mt-5">
      <LineChart data={individualImpressions}
                 title={"Recent Partner Activity (" + numberWithCommas(individualImpressions.reduce((total, data) => total + data.value, 0)) + ")"} />
    </div>
  </div>
);

ImpressionChart.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  hostImpressions: PropTypes.array,
  individualImpressions: PropTypes.array
};

export default ImpressionChart;
