// Simple example of a React "smart" component

import { connect } from 'react-redux';
import DashboardPage from '../components/Dashboard';
import * as actions from '../actions/dashboardActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  currentUserPartnership: state.currentUserPartnership,
  resourceTaskCompletions: state.resourceTaskCompletions,
  welcomeMessageOpened: state.welcomeMessageOpened,
  csrfToken: state.csrfToken,
  todoTaskCompletions: state.todoTaskCompletions,
  chatChannelAvatars: state.chatChannelAvatars,
  chatChannelId: state.chatChannelId,
  chatChannelType: state.chatChannelType,
  chatChannelMessages: state.chatChannelMessages,
  webSocketHost: state.webSocketHost,
  taskMenuState: state.taskMenuState,
  resourceMenuState: state.resourceMenuState,
  chatHistoryExists: state.chatHistoryExists,
  canSeeSettings: state.canSeeSettings,
  canComment: state.canComment,
  canSeePermissions: state.canSeePermissions,
  canSeeInternalTasks: state.canSeeInternalTasks
});

const mapDispatchToProps = (dispatch) => {
  return {
    dismissWelcomeMessage: (csrfToken, currentEvent, currentPartnership) => dispatch(actions.dismissWelcomeMessage(dispatch, csrfToken, currentEvent, currentPartnership)),
    welcomeMessageOpenedChanged: (welcomeMessageOpened) => dispatch(actions.welcomeMessageOpenedChanged(welcomeMessageOpened)),
    taskMenuStateChanged: (taskMenuState) => dispatch(actions.taskMenuStateChanged(taskMenuState)),
    resourceMenuStateChanged: (resourceMenuState) => dispatch(actions.resourceMenuStateChanged(resourceMenuState))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
