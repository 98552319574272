// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ClaimInviteCode from '../components/ClaimInviteCode';
import * as actions from '../actions/claimInviteCodeActionCreators';

const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  inviteClaim: state.inviteClaim,
  ftux: state.ftux,
  isSubmitting: state.isSubmitting,
  errorMessages: state.errorMessages
});

const mapDispatchToProps = (dispatch) => {
  return {
    inviteClaimChanged: (inviteClaim) => dispatch(actions.inviteClaimChanged(inviteClaim)),
    claimCode: (csrfToken, inviteClaim) => dispatch(actions.claimCode(dispatch, csrfToken, inviteClaim)),
    errorMessagesChanged: (errorMessages) => dispatch(actions.errorMessagesChanged(errorMessages))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimInviteCode);
