import { combineReducers } from 'redux';
import {
  INVITATION_CREATED
} from '../constants/partnershipInvitationButtonConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const partnership = (state = {}, action) => {
  switch (action.type) {
    case INVITATION_CREATED:
      return Object.assign({}, state, {invitation: action.invitation});
    default:
      return state;
  }
};

const invitationUrl = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const partnershipInvitationButtonReducer = combineReducers({
  csrfToken,
  currentPartnership,
  currentEvent,
  partnership,
  invitationUrl
});

export default partnershipInvitationButtonReducer;
