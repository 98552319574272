import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Tippy from "@tippyjs/react";

import Popup from "reactjs-popup";
var _ = require('lodash');

const partnershipModalContentStyle = {
  "position": "relative",
  "margin": "auto",
  "maxWidth": "600px",
  "width": "90%",
  "padding": "0",
  "backgroundColor": "transparent",
  "border": "0"
}

const ProjectProfile = ({
  activeFilter,
  filterChanged,
  createInvitation,
  currentEvent,
  currentPartnership,
  errors,
  email,
  emailChanged,
  csrfToken,
  organizationPartnerships
}) => {
  useEffect(() => {
    // ...
  }, [])

  return (
    organizationPartnerships.map((organizationPartnership, organizationPartnershipIndex) =>
      <React.Fragment key={organizationPartnershipIndex}>
        <div className="d-flex flex-row align-items-center">
          <div className="flex-grow-1 text-muted overflow-ellipsis" style={{"fontSize": "16px", "fontWeight": "600"}}>
            {organizationPartnership.name}
          </div>
          <div className="flex-shrink-1 d-flex flex-row dashboard-avatars ml-1">
            {organizationPartnership.adjactent_avatar_partnerships.map((partnership, avatar_index) =>
              <Tippy content={
                        <div className="text-center">{partnership.name}</div>
                      }
                      key={avatar_index}
                      placement={'bottom-start'}
                      animation={'shift-away'}
                      theme='material'>
                  <div className="avatar hover-effect">
                    <div className="image-container rounded-circle"
                          style={{
                            "width": "40px",
                            "height": "40px",
                            "backgroundImage": "url('" + partnership.avatar_path + "')",
                            "border": "1px solid white"
                          }}>
                    </div>
                  </div>
              </Tippy>
            )}

            {(organizationPartnership.adjacent_partnerships_count - 3) > 0 ? (
              <div className="btn avatar rounded-circle round-40 border font-weight-bold border-white d-flex flex-row align-items-center justify-content-center text-white">
                +{organizationPartnership.adjacent_partnerships_count - 3}
              </div>
            ) : null }
          </div>

          <Popup arrow={false}
                modal
                contentStyle={partnershipModalContentStyle}
                trigger={open => (
                  <button className="btn btn-primary ml-0 bg-primary rounded-circle round-40 border font-weight-bold border-white d-flex flex-row align-items-center justify-content-center text-white">
                    <i className="fa fa-user-plus"></i>
                  </button>
            )} >
            {close => (
              <div className="card border-0" style={{"maxWidth": "600px !important", "maxHeight": "60vh"}}>
                <div className="card-header orange d-flex flex-row align-items-center">
                  <div className="d-flex flex-row w-100 align-items-center justify-content-center">
                    <div className="flex-grow-1 overflow-ellipsis" style={{"fontSize": "24px", "fontWeight": "700"}}>
                      <i className="fa fa-users"></i> {organizationPartnership.name}
                    </div>
                    <div className="flex-shrink-1 ml-1">
                      <a href="#"
                        className="text-white"
                        style={{"paddingRight": "15px"}}
                        onClick={
                          (e) => {
                              e.preventDefault();
                              close();
                            }
                        }
                      >
                          <i className="fal fa-times" style={{"fontWeight": "400", "fontSize": "25px"}}></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="p-3 border-bottom">
                  <form onSubmit={
                    (e) => {
                      e.preventDefault();
                      createInvitation(csrfToken, organizationPartnership, email, currentEvent, currentPartnership);
                    }
                  }>
                    <div className="form-row">
                      {errors && errors.length > 0 ? (
                        <div className="col-12">
                          <div className="form-group">
                            <div className="p-3 mb-2 bg-danger text-white">
                              <ul className="mb-0">
                                {errors.map((error, key) =>
                                  <li key={key}>{error}</li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-12">
                        <div className="input-group">
                          <input className="form-control"
                                value={email}
                                onChange={
                                  (e) => {
                                    emailChanged(e.target.value);
                                  }
                                }
                                placeholder="Email"
                                type="email" />
                          <div className="input-group-append">
                            <button className="btn btn-primary"
                                    type="submit">
                              Send Invitation
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-body" style={{"overflowY": "scroll"}}>
                  {organizationPartnership.pending_invitations.length > 0 ? (
                    <nav className="nav nav-phq mb-4">
                      <button className={"btn btn-link nav-link p-0 " + (activeFilter === 'joined' ? 'active' : '')}
                          style={{"fontSize": "20px"}}
                          type="button"
                          onClick={
                            (e) => {
                              e.preventDefault();
                              filterChanged('joined')
                            }
                          } >
                        <strong>
                          Joined ({organizationPartnership.all_adjacent_partnerships.length})
                        </strong>
                      </button>
                      <button className={"btn btn-link nav-link p-0 ml-3 " + (activeFilter === 'invited' ? 'active' : '')}
                          type="button"
                          style={{"fontSize": "20px"}}
                          onClick={
                            (e) => {
                              e.preventDefault();
                              filterChanged('invited')
                            }
                          } >
                        <strong>
                          Invited ({organizationPartnership.pending_invitations.length})
                        </strong>
                      </button>
                    </nav>
                  ) : null }

                  {activeFilter === 'joined' ? (
                    <span>
                      {organizationPartnership.all_adjacent_partnerships.map((partnership, avatar_index) =>
                        <div className="d-flex flex-row align-items-center mb-3" key={avatar_index}>
                          <div className="flex-shrink-1">
                            <div className="avatar">
                              <div className="image-container rounded-circle"
                                    style={{
                                      "width": "40px",
                                      "height": "40px",
                                      "backgroundImage": "url('" + partnership.avatar_path + "')",
                                      "border": "1px solid white"
                                    }}>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex flex-row align-items-center flex-grow-1 ml-3">
                            <div style={{"fontSize": "16px", "fontWeight": "700"}}>
                              {partnership.name}
                            </div>

                            <div className="ml-3 p-2"
                              style={{"fontSize": "14px", "fontWeight": "400", "backgroundColor": "#F5F6FB", "borderRadius": "50px", "color": "#4861A1"}}>
                              {partnership.email}
                            </div>
                          </div>
                        </div>
                      )}
                    </span>
                  ) : (
                    <span>
                      {organizationPartnership.pending_invitations.map((invitation, invitation_index) =>
                        <div className="d-flex flex-row align-items-center mb-3" key={invitation_index}>
                          <div className="d-flex flex-row align-items-center flex-grow-1 ml-3">
                            <div className="p-2"
                                style={{"fontSize": "14px", "fontWeight": "400", "backgroundColor": "#F5F6FB", "borderRadius": "50px", "color": "#4861A1"}}>
                              {invitation.email}
                            </div>
                          </div>
                        </div>
                      )}
                    </span>
                  )}
                </div>
              </div>
            )}
          </Popup>
        </div>
      <hr style={{"borderTop": "1px solid #e5e5e5", "width": "100%"}} />
      </React.Fragment>
    )
  )
};

ProjectProfile.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  createInvitation: PropTypes.func.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  email: PropTypes.string,
  emailChanged: PropTypes.func.isRequired,
  errors: PropTypes.array,
  filterChanged: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  organizationPartnerships: PropTypes.array
};

export default ProjectProfile;
