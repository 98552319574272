import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

var _ = require('lodash');
import Select from 'react-select';

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const InventoryItem = ({
  inventory,
  inventoryChanged,
  tagOptions,
  index,
  provided
}) => {
  useEffect(() => {
    // ...
  }, []);

  return (
    <div key={index}
          className={"card-body " + (inventory._destroy ? "d-none" : "")}
          style={{"borderBottom": "1px solid #e7e9ec"}}>
      <input type="hidden"
            name={`task[inventories_attributes][${index}][id]`}
            value={inventory.id || ""} />
      <input type="hidden"
            name={`task[inventories_attributes][${index}][_destroy]`}
            value={inventory._destroy || false} />
      <input type="hidden"
            name={`task[inventories_attributes][${index}][position]`}
            value={(index + 1)} />
      <div className='form-row mb-2'>
        <div className='col-6'>
          <a {...provided.dragHandleProps}
            href="#"
            title="Drag to reorder"
            onClick={
              (e) => {
                e.preventDefault();
              }
            }
            className="text-muted">
            <i className="fas fa-bars"></i>
          </a>
        </div>
        <div className='col-6 text-right'>
          <a href="#"
              title={inventory.can_delete ? "Delete this item" : "You cannot delete this item because it has already been purchased"}
              onClick={
                (e) => {
                  e.preventDefault();

                  if(inventory.can_delete){
                    var updated = Object.assign({}, inventory, {_destroy: true});
                    inventoryChanged(updated, index);
                  }
                }
              }
              className={'text-muted ' + (inventory.can_delete ? "" : "disabled")}>
            <i className="fas fa-trash"></i>
          </a>
        </div>
      </div>
      <div className='form-row'>
        <div className='col-12 col-md-6'>
          <div className="form-group mb-1">
            <label className='mb-0 small text-muted'
                    htmlFor={`inventory-name-${index}`}>
              Name
            </label>
            <input type="text"
                    className="form-control"
                    name={`task[inventories_attributes][${index}][name]`}
                    value={inventory.name || ""}
                    onChange={
                      (e) => {
                        var updated = Object.assign({}, inventory, {name: e.target.value});
                        inventoryChanged(updated, index);
                      }
                    }
                    id={`inventory-name-${index}`} />
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='form-row'>
            <div className='col-4'>
              <input type="hidden"
                    name={`task[inventories_attributes][${index}][min_per_order]`}
                    value={inventory.min_per_order ? parseFloatFromString(inventory.min_per_order) : ""} />
              <div className="form-group mb-1 text-right">
                <label className='mb-0 small text-muted'
                        htmlFor={`inventory-min-per-order-${index}`}>
                  Min QTY
                </label>
                <MaskedInput className="form-control text-right"
                        mask={createNumberMask({
                          prefix: "",
                          decimalLimit: 0
                        })}
                        placeholder={"0"}
                        value={inventory.min_per_order || ""}
                        onChange={
                          (e) => {
                            var updated = Object.assign({}, inventory, {min_per_order: e.target.value});
                            inventoryChanged(updated, index);
                          }
                        }
                        id={`inventory-min-per-order-${index}`} />
              </div>
            </div>
            <div className='col-4'>
              <input type="hidden"
                    name={`task[inventories_attributes][${index}][max_per_order]`}
                    value={inventory.max_per_order ? parseFloatFromString(inventory.max_per_order) : ""} />
              <div className="form-group mb-1 text-right">
                <label className='mb-0 small text-muted'
                        htmlFor={`inventory-max-per-order-${index}`}>
                  Max QTY
                </label>
                <MaskedInput className="form-control text-right"
                        mask={createNumberMask({
                          prefix: "",
                          decimalLimit: 0
                        })}
                        placeholder={"10"}
                        value={inventory.max_per_order || ""}
                        onChange={
                          (e) => {
                            var updated = Object.assign({}, inventory, {max_per_order: e.target.value});
                            inventoryChanged(updated, index);
                          }
                        }
                        id={`inventory-max-per-order-${index}`} />
              </div>
            </div>
            <div className='col-4'>
              <input type="hidden"
                    name={`task[inventories_attributes][${index}][price]`}
                    value={inventory.price ? parseFloatFromString(inventory.price) : ""} />
              <div className="form-group mb-1 text-right">
                <label className='mb-0 small text-muted'
                        htmlFor={`inventory-price-${index}`}>
                  Price
                </label>
                <CurrencyInput className="form-control text-right"
                        maskOptions={{
                          prefix: "",
                          integerLimit: null
                        }}
                        placeholder={"0.00"}
                        value={inventory.price || ""}
                        onChange={
                          (e) => {
                            var updated = Object.assign({}, inventory, {price: e.target.value});
                            inventoryChanged(updated, index);
                          }
                        }
                        id={`inventory-price-${index}`} />
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div className="form-group mb-1">
            <label className='mb-0 small text-muted'
                    htmlFor={`inventory-description-${index}`}>
              Description
            </label>
            <textarea className="form-control"
                      rows={2}
                      name={`task[inventories_attributes][${index}][description]`}
                      value={inventory.description || ""}
                      onChange={
                        (e) => {
                          var updated = Object.assign({}, inventory, {description: e.target.value});
                          inventoryChanged(updated, index);
                        }
                      }
                      id={`inventory-description-${index}`} />
          </div>
        </div>
        <div className='col-12'>
          <div className="form-group mb-2">
              <input type="hidden"
                    name={`task[inventories_attributes][${index}][tag_ids][]`}
                    value={""} />
              {inventory.tag_ids.map((tagId, tagIndex) =>
                <input key={tagIndex}
                      type="hidden"
                      name={`task[inventories_attributes][${index}][tag_ids][]`}
                      value={tagId} />
              )}
              <label className='mb-0 small text-muted'
                      htmlFor={`inventory-tags-${index}`}>
                Tag(s) - <i>Added to organization when inventory is purchased</i>
              </label>
              <Select
                isMulti
                value={
                  tagOptions.filter((o) => inventory.tag_ids.includes(o.value))
                }
                onChange={
                  (options) => {
                    var tagIds = (options || []).map((option) => option.value);
                    var updated = Object.assign({}, inventory, {tag_ids: tagIds});

                    inventoryChanged(updated, index);
                  }
                }
                options={tagOptions}
              />
            </div>
        </div>
        <div className='col-12'>
          <div className="form-group mb-1">
              <input type="hidden"
                    name={`task[inventories_attributes][${index}][required]`}
                    value={inventory.required ? "1" : "0"} />
              <div className="custom-control custom-checkbox">
                <input type="checkbox"
                        className="custom-control-input"
                        checked={inventory.required || false}
                        onChange={
                          (e) => {
                            var updated = Object.assign({}, inventory, {
                              required: e.target.checked
                            });

                            inventoryChanged(updated, index);
                          }
                        }
                        id={`inventory-required-${index}`} />
                <label className="custom-control-label"
                        htmlFor={`inventory-required-${index}`}>
                  Require quantity selection to complete this task
                </label>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InventoryItem.propTypes = {
  inventory: PropTypes.object.isRequired,
  inventoryChanged: PropTypes.func.isRequired,
  tagOptions: PropTypes.array,
  index: PropTypes.number.isRequired,
  provided: PropTypes.object.isRequired
};

export default InventoryItem;
