import { combineReducers } from 'redux';
import {
  FILTER_CHANGED,
  EMAIL_CHANGED,
  ERRORS_CHANGED,
  INVITATION_CREATED
} from '../constants/projectProfileConstants';

const email = (state = "", action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return action.email;
    case INVITATION_CREATED:
      return "";
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const errors = (state = [], action) => {
  switch (action.type) {
    case ERRORS_CHANGED:
      return action.errors;
    case INVITATION_CREATED:
      return [];
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const organizationPartnerships = (state = [], action) => {
  switch (action.type) {
    case INVITATION_CREATED:
      return [...state].map((organizationPartnership) => {
        if (organizationPartnership.id === action.organizationPartnership.id) {
          var updatedInvitations = [...organizationPartnership.pending_invitations, action.invitation];

          return Object.assign({}, organizationPartnership, { pending_invitations: updatedInvitations });
        } else {
          return organizationPartnership;
        }
      });
    default:
      return state;
  }
}

const activeFilter = (state = "joined", action) => {
  switch (action.type) {
    case FILTER_CHANGED:
      return action.filter;
    default:
      return state;
  }
};

const projectProfileReducer = combineReducers({
  activeFilter,
  currentEvent,
  currentPartnership,
  email,
  errors,
  csrfToken,
  organizationPartnerships
});

export default projectProfileReducer;
