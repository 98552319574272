import { combineReducers } from 'redux';
import {
  MESSAGE_RECEIVED, UPDATE_CHAT
} from '../constants/chatConstants';

const chatChannelAvatars = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const channelId = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const chatChannelType = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const partnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const chat = (state = "", action) => {
  switch (action.type) {
    case UPDATE_CHAT:
      state = action.chat;
      return state;
    default:
      return state;
  }
};

const messages = (state = [], action) => {
  switch (action.type) {
    case MESSAGE_RECEIVED:
      return [...state, action.message];
    default:
      return state;
  }
};

const webSocketHost = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const canComment = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const chatReducer = combineReducers({
  chatChannelAvatars,
  channelId,
  chatChannelType,
  chat,
  partnership,
  messages,
  webSocketHost,
  currentEvent,
  csrfToken,
  canComment
});

export default chatReducer;
