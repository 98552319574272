// Simple example of a React "smart" component

import { connect } from 'react-redux';
import TaskCompletionGrid from '../components/TaskCompletionGrid';
import * as actions from '../actions/taskCompletionGridActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  tasks: state.tasks,
  organizationPartnerships: state.organizationPartnerships,
  customFieldValues: state.customFieldValues
});

const mapDispatchToProps = (dispatch) => {
  return {
    uploadCustomFieldValueFile: (csrfToken, currentEvent, currentPartnership, file, customFieldValue) => dispatch(actions.uploadCustomFieldValueFile(dispatch, csrfToken, currentEvent, currentPartnership, file, customFieldValue))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(TaskCompletionGrid);
