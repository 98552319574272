// Simple example of a React "smart" component

import { connect } from 'react-redux';
import TaskForm from '../components/TaskForm';
import * as actions from '../actions/taskFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  task: state.task,
  iconOptions: state.iconOptions,
  customField: state.customField,
  customFieldIndex: state.customFieldIndex,
  resourcesOptions: state.resourcesOptions,
  inventories: state.inventories,
  chargesEnabled: state.chargesEnabled,
  tagOptions: state.tagOptions
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateTaskOrganizationTags: (tags) => dispatch(actions.updateTaskOrganizationTags(tags)),
    updatePartnershipTaskCompletionEnabled: (partnership, enabled) => dispatch(actions.updatePartnershipTaskCompletionEnabled(partnership, enabled)),
    updateOrganizationPartnershipTaskCompletionEnabled: (organizationPartnership, enabled) => dispatch(actions.updateOrganizationPartnershipTaskCompletionEnabled(organizationPartnership, enabled)),
    updateTaskCompletionDueAt: (organizationPartnership, taskCompletion, date) => dispatch(actions.updateTaskCompletionDueAt(organizationPartnership, taskCompletion, date)),
    updateTaskDueAt: (due_at) => dispatch(actions.updateTaskDueAt(due_at)),
    updateTaskPublishedAt: (published_at) => dispatch(actions.updateTaskPublishedAt(published_at)),
    updateTaskDescription: (html) => dispatch(actions.updateTaskDescription(html)),
    updateTaskLabel: (label) => dispatch(actions.updateTaskLabel(label)),
    updateIcon: (icon) => dispatch(actions.updateIcon(icon)),
    updateTaskPinned: (pinned) => dispatch(actions.updateTaskPinned(pinned)),
    deleteField: (index) => dispatch(actions.deleteField(index)),
    customFieldChanged: (customField, index) => dispatch(actions.customFieldChanged(customField, index)),
    addField: (index) => dispatch(actions.addField(dispatch, index)),
    customFieldTypeChanged: (fieldType) => dispatch(actions.customFieldTypeChanged(fieldType)),
    customFieldNameChanged: (name) => dispatch(actions.customFieldNameChanged(name)),
    customFieldCharacterLimitChanged: (limit) => dispatch(actions.customFieldCharacterLimitChanged(limit)),
    customFieldRequiredChanged: (required) => dispatch(actions.customFieldRequiredChanged(required)),
    customFieldSaved: (customField, index) => dispatch(actions.customFieldSaved(customField, index)),
    updateTaskLocked: (locked) => dispatch(actions.updateTaskLocked(locked)),
    fieldDragEnd: (result) => dispatch(actions.fieldDragEnd(result)),
    taskChanged: (task) => dispatch(actions.taskChanged(task)),
    selectAllClicked: (value) => dispatch(actions.selectAllClicked(value)),
    addInventory: () => dispatch(actions.addInventory()),
    inventoryChanged: (inventory, index) => dispatch(actions.inventoryChanged(inventory, index)),
    inventoryDragEnd: (result) => dispatch(actions.inventoryDragEnd(result)),
    customFieldTextOptionsChanged: (textOptions) => dispatch(actions.customFieldTextOptionsChanged(textOptions)),
    customFieldEnableChoiceLimitsChanged: (enableChoiceLimits) => dispatch(actions.customFieldEnableChoiceLimitsChanged(enableChoiceLimits))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
