// Simple example of a React "smart" component

import { connect } from 'react-redux';
import Events from '../components/Events';
import * as actions from '../actions/eventsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  activeFilter: state.activeFilter,
  events: state.events,
  token: state.token
});

const mapDispatchToProps = (dispatch) => {
  return {
    filterChanged: (filter) => dispatch(actions.filterChanged(filter))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(Events);
