/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  UPDATE_ORGANIZATION_PARTNERSHIP_TAGS,
  UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  ORGANIZATION_PARTNERSHIP_CHANGED
} from '../constants/organizationPartnershipFormConstants';

export const updateOrganizationPartnershipTags = (tags) => ({
  type: UPDATE_ORGANIZATION_PARTNERSHIP_TAGS,
  tags
});

export const updateOrganizationPartnershipTaskCompletionEnabled = (task, enabled) => ({
  type: UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  task,
  enabled
});

export const organizationPartnershipChanged = (organizationPartnership) => ({
  type: ORGANIZATION_PARTNERSHIP_CHANGED,
  organizationPartnership
});
