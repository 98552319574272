/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  UPDATE_PARTNERSHIP_FIRST_NAME,
  UPDATE_PARTNERSHIP_LAST_NAME,
  UPDATE_PARTNERSHIP_EMAIL,
  PARTNERSHIP_CHANGED,
  ORGANIZATION_NAMES_CHANGED
} from '../constants/partnershipFormConstants';

export const updatePartnershipFirstName = (firstName) => ({
  type: UPDATE_PARTNERSHIP_FIRST_NAME,
  firstName
});

export const updatePartnershipLastName = (lastName) => ({
  type: UPDATE_PARTNERSHIP_LAST_NAME,
  lastName
});

export const updatePartnershipEmail = (email) => ({
  type: UPDATE_PARTNERSHIP_EMAIL,
  email
});

export const partnershipChanged = (partnership) => ({
  type: PARTNERSHIP_CHANGED,
  partnership
});

export const organizationNamesChanged = (organizationNames) => ({
  type: ORGANIZATION_NAMES_CHANGED,
  organizationNames
});
