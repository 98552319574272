import { combineReducers } from 'redux';
import {
  ANNOUNCEMENT_CHANGED
} from '../constants/announcementFormConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const announcement = (state = {}, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_CHANGED:
      return action.announcement;
    default:
      return state;
  }
};

const taskOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tagOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const partnershipCount = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const organizationPartnershipOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const announcementFormReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  announcement,
  taskOptions,
  tagOptions,
  partnershipCount,
  organizationPartnershipOptions
});

export default announcementFormReducer;
