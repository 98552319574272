/* eslint-disable import/prefer-default-export */

export const TASK_COMPLETION_CHANGED = 'TASK_COMPLETION_CHANGED';
export const INPUT_FIELD_CHANGED = 'INPUT_FIELD_CHANGED';
export const TEXT_AREA_FIELD_CHANGED = 'TEXT_AREA_FIELD_CHANGED';
export const RADIO_FIELD_CHANGED = 'RADIO_FIELD_CHANGED';
export const CHECK_BOX_FIELD_CHANGED = 'CHECK_BOX_FIELD_CHANGED';
export const SELECT_FIELD_CHANGED = 'SELECT_FIELD_CHANGED';
export const FILE_FIELD_CHANGED = 'FILE_FIELD_CHANGED';
export const FILE_FIELD_PROGRESS_CHANGED = 'FILE_FIELD_PROGRESS_CHANGED';
export const FILE_FIELD_VALUE_REMOVED = 'FILE_FIELD_VALUE_REMOVED';
export const INVENTORY_CHANGED = 'INVENTORY_CHANGED';
