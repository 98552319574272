import { combineReducers } from 'redux';
import {
  WELCOME_MESSAGE_CHANGED
} from '../constants/eventFormConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    case WELCOME_MESSAGE_CHANGED:
      return Object.assign({}, state, {welcome_message: action.welcomeMessage});
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const eventFormReducer = combineReducers({
  csrfToken, currentEvent, currentPartnership
});

export default eventFormReducer;
