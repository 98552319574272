import { combineReducers } from 'redux';
import {
  INPUT_FIELD_CHANGED,
  TEXT_AREA_FIELD_CHANGED,
  RADIO_FIELD_CHANGED,
  CHECK_BOX_FIELD_CHANGED,
  SELECT_FIELD_CHANGED,
  FILE_FIELD_CHANGED,
  FILE_FIELD_PROGRESS_CHANGED,
  TASK_COMPLETION_CHANGED,
  FILE_FIELD_VALUE_REMOVED,
  INVENTORY_CHANGED
} from '../constants/taskCompletionFormConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const taskCompletion = (state = {}, action) => {
  switch (action.type) {
    case INPUT_FIELD_CHANGED:
      var customFieldValues = [...state.custom_field_values].map((customFieldValue, index) => {
        if(index === action.index){
          var value = action.value;
          var limit = customFieldValue.custom_field.character_limit;

          if (limit && value.length >= limit) {
            value = value.slice(0, limit)
          }

          return Object.assign({}, customFieldValue, {value: value});
        } else {
          return customFieldValue;
        }
      });

      return Object.assign({}, state, {custom_field_values: customFieldValues});
    case TEXT_AREA_FIELD_CHANGED:
      var customFieldValues = [...state.custom_field_values].map((customFieldValue, index) => {
        if(index === action.index){
          var value = action.value;
          var limit = customFieldValue.custom_field.character_limit;

          if (limit && value.length >= limit) {
            value = value.slice(0, limit)
          }

          return Object.assign({}, customFieldValue, {value: value});
        } else {
          return customFieldValue;
        }
      });

      return Object.assign({}, state, {custom_field_values: customFieldValues});
    case RADIO_FIELD_CHANGED:
      var customFieldValues = [...state.custom_field_values].map((customFieldValue, index) => {
        if(index === action.index){
          return Object.assign({}, customFieldValue, {value: action.value});
        } else {
          return customFieldValue;
        }
      });

      return Object.assign({}, state, {custom_field_values: customFieldValues});
    case CHECK_BOX_FIELD_CHANGED:
      var customFieldValues = [...state.custom_field_values].map((customFieldValue, index) => {
        if(index === action.index){
          var values = (customFieldValue.value ? [...customFieldValue.value] : []);

          if (values.includes(action.value)){
            values = values.filter((v) => v !== action.value);
          } else {
            values = [...values, action.value];
          }

          return Object.assign({}, customFieldValue, {value: values});
        } else {
          return customFieldValue;
        }
      });

      return Object.assign({}, state, {custom_field_values: customFieldValues});
    case SELECT_FIELD_CHANGED:
      var customFieldValues = [...state.custom_field_values].map((customFieldValue, index) => {
        if(index === action.index){
          return Object.assign({}, customFieldValue, {value: action.value});
        } else {
          return customFieldValue;
        }
      });

      return Object.assign({}, state, {custom_field_values: customFieldValues});
    case FILE_FIELD_CHANGED:
      var customFieldValues = [...state.custom_field_values].map((customFieldValue, index) => {
        if(index === action.index){
          return Object.assign({}, customFieldValue, {
            custom_field_file_upload: action.customFieldFileUpload,
            value: action.customFieldFileUpload.token
          });
        } else {
          return customFieldValue;
        }
      });

      return Object.assign({}, state, {custom_field_values: customFieldValues});
    case FILE_FIELD_VALUE_REMOVED:
      var customFieldValues = [...state.custom_field_values].map((customFieldValue, index) => {
        if(index === action.index){
          return Object.assign({}, customFieldValue, {
            custom_field_file_upload: null,
            value: null
          });
        } else {
          return customFieldValue;
        }
      });

      return Object.assign({}, state, {custom_field_values: customFieldValues});
    case FILE_FIELD_PROGRESS_CHANGED:
      var customFieldValues = [...state.custom_field_values].map((customFieldValue, index) => {
        if(index === action.index){
          return Object.assign({}, customFieldValue, {
            percentCompleted: action.percentCompleted
          });
        } else {
          return customFieldValue;
        }
      });

      return Object.assign({}, state, {custom_field_values: customFieldValues});
    case TASK_COMPLETION_CHANGED:
      return action.taskCompletion;
    default:
      return state;
  }
};

const task = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const canUpdateTaskCompletion = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const inventories = (state = [], action) => {
  switch (action.type) {
    case INVENTORY_CHANGED:
      return [...state].map((inventory, index) => {
        if(inventory.id === action.inventory.id){
          return action.inventory;
        } else {
          return inventory;
        }
      });
    default:
      return state;
  }
};

const taskCompletionFormReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  taskCompletion,
  task,
  canUpdateTaskCompletion,
  inventories
});

export default taskCompletionFormReducer;
