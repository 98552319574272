/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  EMAIL_CHANGED,
  FILTER_CHANGED,
  INVITATION_CREATED,
  ERRORS_CHANGED
} from '../constants/projectProfileConstants';

export const emailChanged = (email) => ({
  type: EMAIL_CHANGED,
  email
});

export const errorsChanged = (errors) => ({
  type: ERRORS_CHANGED,
  errors
});

export const createInvitation = (dispatch, csrfToken, organizationPartnership, email, currentEvent, currentPartnership) => {
  return async dispatch => {
    dispatch(errorsChanged([]));
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    try {
      const { data } = await axios.post("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/organization_partnerships/" + organizationPartnership.id + "/org_to_org_invitations", {
        invitation: {
          email: email
        }
      });
      dispatch(invitationCreated(data, organizationPartnership));
    } catch (error) {
      dispatch(errorsChanged(error.response.data));
    }
  };
};

export const invitationCreated = (invitation, organizationPartnership) => ({
  type: INVITATION_CREATED,
  invitation,
  organizationPartnership
});

export const filterChanged = (filter) => ({
  type: FILTER_CHANGED,
  filter
});
