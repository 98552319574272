import { combineReducers } from 'redux';
import {
  TASK_COMPLETION_CHANGED
} from '../constants/taskCompletionAssignmentConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const taskCompletion = (state = {}, action) => {
  switch (action.type) {
    case TASK_COMPLETION_CHANGED:
      return action.taskCompletion;
    default:
      return state;
  }
};

const partnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const event = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const taskCompletionAssignmentReducer = combineReducers({
  csrfToken,
  taskCompletion,
  partnerships,
  currentPartnership,
  event
});

export default taskCompletionAssignmentReducer;
