import PropTypes from 'prop-types';
import React from 'react';

import ResourceRow from './ResourceRow';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

var _ = require('lodash');

const ResourceIndex = ({
  currentEvent,
  currentPartnership,
  resources,
  fieldDragEnd,
  canUpdateResource
}) => (
  <DragDropContext onDragEnd={
                      (result) => {
                        if (!result.destination) {
                          return false;
                        }
                        fieldDragEnd(result);
                      }
                    }>
    <Droppable droppableId="resource-fields">
      {(provided, snapshot) => (
        <table className="table table-sm table-fixed">
          <thead>
            <tr>
              <th className="text-muted border-0" style={{width: "70%"}}>Resource</th>
              <th className="text-muted border-0 text-right" style={{width: "15%"}}>Assigned</th>
              <th className="text-muted border-0 text-right" style={{width: "10%"}}>Views</th>
              <th className="text-muted border-0" style={{width: "5%"}}>&nbsp;</th>
            </tr>
          </thead>
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            {resources.map((resource, index) =>
              <Draggable key={resource.uuid} draggableId={resource.uuid} index={index}>
                {(provided, snapshot) => (
                  <ResourceRow key={index}
                              index={index}
                              currentEvent={currentEvent}
                              currentPartnership={currentPartnership}
                              provided={provided}
                              canUpdateResource={canUpdateResource}
                              resource={resource} />
                )}
              </Draggable>
            )}
            {provided.placeholder}
          </tbody>
        </table>
      )}
    </Droppable>
  </DragDropContext>
);

ResourceIndex.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  resources: PropTypes.array.isRequired,
  fieldDragEnd: PropTypes.func.isRequired,
  canUpdateResource: PropTypes.bool
};

export default ResourceIndex;
