/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  MESSAGES_CHANGED,
  NEXT_PAGE_CHANGED,
  HAS_MORE_CHANGED,
  INBOX_MODAL_IS_OPEN_CHANGED
} from '../constants/chatInboxConstants';

export const messagesChanged = (messages) => ({
  type: MESSAGES_CHANGED,
  messages
});

export const nextPageChanged = (nextPage) => ({
  type: NEXT_PAGE_CHANGED,
  nextPage
});

export const hasMoreChanged = (hasMore) => ({
  type: HAS_MORE_CHANGED,
  hasMore
});

export const fetchInboxMessages = (dispatch, currentEvent, currentPartnership, messages, nextPage) => {
  return dispatch => {
    return axios.get("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/message_inbox", {
        params: {
          page: nextPage
        }
      })
      .then(({ data }) => {
        var updatedMessages = [...messages].concat(data.collection);
        var updatedPage = (data.current_page + 1)
        var updatedHasMore = (data.collection.length === 30);

        dispatch(messagesChanged(updatedMessages));
        dispatch(nextPageChanged(updatedPage));
        dispatch(hasMoreChanged(updatedHasMore));
      });
  };
};

export const inboxModalIsOpenChanged = (inboxModalIsOpen) => ({
  type: INBOX_MODAL_IS_OPEN_CHANGED,
  inboxModalIsOpen
});
