/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { v4 as uuidv4 } from 'uuid';

import {
  SELECT_ALL_CLICKED,
  UPDATE_TASK_ORGANIZATION_TAGS,
  UPDATE_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  UPDATE_TASK_DUE_AT,
  UPDATE_TASK_PUBLISHED_AT,
  UPDATE_TASK_DESCRIPTION,
  UPDATE_TASK_LABEL,
  UPDATE_ICON,
  UPDATE_TASK_PINNED,
  DELETE_FIELD,
  CUSTOM_FIELD_CHANGED,
  ADD_FIELD,
  CUSTOM_FIELD_TYPE_CHANGED,
  CUSTOM_FIELD_NAME_CHANGED,
  CUSTOM_FIELD_CHARACTER_LIMIT_CHANGED,
  CUSTOM_FIELD_REQUIRED_CHANGED,
  CUSTOM_FIELD_SAVED,
  UPDATE_TASK_LOCKED,
  FIELD_DRAG_END,
  TASK_CHANGED,
  UPDATE_TASK_COMPLETION_DUE_AT,
  INVENTORY_ADDED,
  INVENTORY_CHANGED,
  INVENTORY_DRAG_END,
  CUSTOM_FIELD_TEXT_OPTIONS_CHANGED,
  CUSTOM_FIELD_ENABLE_CHOICE_LIMITS_CHANGED
} from '../constants/taskFormConstants';

export const updateTaskOrganizationTags = (tags) => ({
  type: UPDATE_TASK_ORGANIZATION_TAGS,
  tags
});

export const updatePartnershipTaskCompletionEnabled = (partnership, enabled) => ({
  type: UPDATE_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  partnership,
  enabled
});

export const updateOrganizationPartnershipTaskCompletionEnabled = (organizationPartnership, enabled) => ({
  type: UPDATE_ORGANIZATION_PARTNERSHIP_TASK_COMPLETION_ENABLED,
  organizationPartnership,
  enabled
});

export const updateTaskCompletionDueAt = (organizationPartnership, taskCompletion, date) => ({
  type: UPDATE_TASK_COMPLETION_DUE_AT,
  organizationPartnership,
  taskCompletion,
  date
});

export const updateTaskDueAt = (date) => ({
  type: UPDATE_TASK_DUE_AT,
  date
});

export const updateTaskPublishedAt = (date) => ({
  type: UPDATE_TASK_PUBLISHED_AT,
  date
});

export const updateTaskDescription = (html) => ({
  type: UPDATE_TASK_DESCRIPTION,
  html
});

export const updateTaskLabel = (label) => ({
  type: UPDATE_TASK_LABEL,
  label
});

export const updateIcon = (icon) => ({
  type: UPDATE_ICON,
  icon
});

export const updateTaskPinned = (pinned) => ({
  type: UPDATE_TASK_PINNED,
  pinned
});

export const deleteField = (index) => ({
  type: DELETE_FIELD,
  index
});

export const customFieldChanged = (customField, index) => ({
  type: CUSTOM_FIELD_CHANGED,
  customField,
  index
});

export const addFieldToCustomFields = (customField) => ({
  type: ADD_FIELD,
  customField
});

export const addField = (dispatch, index) => {
  return dispatch => {
    var customField = {
      _destroy: true,
      uuid: uuidv4()
    };

    dispatch(addFieldToCustomFields(customField));
    dispatch(customFieldChanged(customField, index));
  };
};

export const customFieldTypeChanged = (fieldType) => ({
  type: CUSTOM_FIELD_TYPE_CHANGED,
  fieldType
});

export const customFieldNameChanged = (name) => ({
  type: CUSTOM_FIELD_NAME_CHANGED,
  name
});

export const customFieldCharacterLimitChanged = (limit) => ({
  type: CUSTOM_FIELD_CHARACTER_LIMIT_CHANGED,
  limit
});

export const customFieldRequiredChanged = (required) => ({
  type: CUSTOM_FIELD_REQUIRED_CHANGED,
  required
});

export const customFieldSaved = (customField, index) => ({
  type: CUSTOM_FIELD_SAVED,
  customField,
  index
});

export const updateTaskLocked = (locked) => ({
  type: UPDATE_TASK_LOCKED,
  locked
});

export const fieldDragEnd = (result) => ({
  type: FIELD_DRAG_END,
  result
});

export const taskChanged = (task) => ({
  type: TASK_CHANGED,
  task
});

export const selectAllClicked = (value) => ({
  type: SELECT_ALL_CLICKED,
  value
});

export const addInventory = () => ({
  type: INVENTORY_ADDED
});

export const inventoryChanged = (inventory, index) => ({
  type: INVENTORY_CHANGED,
  inventory,
  index
});

export const inventoryDragEnd = (result) => ({
  type: INVENTORY_DRAG_END,
  result
});

export const customFieldTextOptionsChanged = (textOptions) => ({
  type: CUSTOM_FIELD_TEXT_OPTIONS_CHANGED,
  textOptions
});

export const customFieldEnableChoiceLimitsChanged = (enableChoiceLimits) => ({
  type: CUSTOM_FIELD_ENABLE_CHOICE_LIMITS_CHANGED,
  enableChoiceLimits
});
