/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  INVITE_CLAIM_CHANGED,
  IS_SUBMITTING_CHANGED,
  ERROR_MESSAGES_CHANGED
} from '../constants/claimInviteCodeConstants';

export const inviteClaimChanged = (inviteClaim) => ({
  type: INVITE_CLAIM_CHANGED,
  inviteClaim
});

export const claimCode = (dispatch, csrfToken, inviteClaim) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSubmittingChanged(true));
    dispatch(errorMessagesChanged([]));

    return axios.post("/invitation_codes/accept", {
        short_code: inviteClaim.short_code
      })
      .then(({ data }) => {
        window.location.replace("/dashboard");
      })
      .catch((error) => {
        if(typeof(error.response.data) === 'object') {
          dispatch(errorMessagesChanged(error.response.data));
        } else {
          dispatch(errorMessagesChanged(["Something went wrong. Please try again."]));
        }
      })
      .then(() => {
        dispatch(isSubmittingChanged(false));
      });
  };
};

export const isSubmittingChanged = (isSubmitting) => ({
  type: IS_SUBMITTING_CHANGED,
  isSubmitting
});

export const errorMessagesChanged = (errorMessages) => ({
  type: ERROR_MESSAGES_CHANGED,
  errorMessages
});
