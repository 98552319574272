import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Tippy from "@tippyjs/react";
import Popup from "reactjs-popup";
import Froala from '../../Froala/components/Froala';

var _ = require('lodash');
const axios = require('axios').default;

import { createConsumer } from '@rails/actioncable';

const modalContentStyle = {
  "position": "relative",
  "margin": "auto",
  "maxWidth": "600px",
  "width": "90%",
  "padding": "0",
  "backgroundColor": "transparent",
  "border": "0"
}

const canSubmitForm = (chat) => {
  return (
    chat
      && chat.length > 0
  );
}

const AlwaysScrollToBottom = (props) => {
  const elementRef = useRef();

  useEffect(
    () => {
      setTimeout(() => {
        props.parentRef.current.scrollTo(0, elementRef.current.offsetTop);
      }, 250);
    }
  );

  return <div ref={elementRef} />;
};

const commentsTooltipText = function(chatChannelType){
  if(chatChannelType === 'Partnership') {
    return 'We\'re here to help! Please submit your questions, concerns, or great ideas here and we will get back to you. Thanks!';
  } else {
    return 'If you need more information about this task or resource please let us know here. We will receive your comment via email and get back to you right away.';
  }
};

var consumer;

const ChatPage = ({
  chatChannelAvatars,
  channelId,
  chatChannelType,
  chat,
  messages,
  messageReceived,
  updateChat,
  partnership,
  webSocketHost,
  currentEvent,
  csrfToken,
  canComment
}) => {
  useEffect(() => {
    consumer = createConsumer(webSocketHost);
    consumer.subscriptions.create({
      channel: "ChatChannelsChannel",
      identifier: channelId,
      partnershipId: partnership.id
    }, {
      received: (data) => {
        var message = data['message'];

        if (data['action'] === 'new') {
          messageReceived(message);
        }
      }
    });
  }, [])

  const parentRef = useRef();

  return (
    <div className="card border-0 shadow-1 h-100">
      <div className="card-header d-flex align-items-center"
            style={{
              "borderBottom": "1px solid #e8e8e8",
              "background": "white"
            }}>
        <div className="d-flex flex-row w-100 align-items-center">
          <div className="flex-grow-1"
              style={{"fontSize": "18px"}}>
            <div className="form-group mb-0">
              <strong>Comments</strong>
              <Tippy content={commentsTooltipText(chatChannelType)}
                    theme='material'
                    animation={'shift-away'}>
                <i className="fas fa-info-circle ml-2 text-muted"></i>
              </Tippy>
            </div>
          </div>
          <div className="flex-shrink-1">
            <React.Fragment>
              <div className="d-flex flex-row dashboard-avatars">
                {chatChannelAvatars.adjacent_partnerships.map((partnership, avatar_index) =>
                  <Tippy content={
                            <div className="text-center">
                              {partnership.name}
                            </div>
                          }
                          key={avatar_index}
                          placement={'bottom-start'}
                          animation={'shift-away'}
                          theme='material'>
                      <div className="avatar hover-effect">
                        <div className="image-container rounded-circle"
                              style={{
                                "width": "30px",
                                "height": "30px",
                                "backgroundImage": "url('" + partnership.avatar_path + "')",
                                "border": "1px solid white"
                              }}>
                        </div>
                      </div>
                  </Tippy>
                )}
                {(chatChannelAvatars.adjacent_partnership_count - 4) > 0 ? (
                  <span>
                    <Popup arrow={false}
                          modal
                          contentStyle={modalContentStyle}
                          trigger={open => (
                            (chatChannelAvatars.adjacent_partnership_count - 4) > 0 ? (
                              <button type="button" className="btn btn-link text-white p-0">
                                <div style={{"fontSize": "12px"}}
                                  className="avatar hover-effect rounded-circle round-30 border font-weight-bold border-white d-flex flex-row align-items-center justify-content-center text-white">
                                  +{chatChannelAvatars.adjacent_partnership_count - 4}
                                </div>
                              </button>
                            ) : null
                      )} >
                      {close => (
                        <div className="card border-0" style={{"maxWidth": "600px !important", "maxHeight": "60vh"}}>
                          <div className="card-header orange d-flex flex-row align-items-center">
                            <div className="d-flex flex-row w-100 align-items-center justify-content-center">
                              <div className="flex-grow-1" style={{"fontSize": "24px", "fontWeight": "700"}}>
                              <i className="fa fa-users"></i> Chat Members
                              </div>
                              <div className="flex-shrink-1">
                                <a href="#"
                                  className="text-white"
                                  style={{"paddingRight": "15px"}}
                                  onClick={
                                    (e) => {
                                        e.preventDefault();
                                        close();
                                      }
                                  } 
                                >
                                    <i className="fal fa-times" style={{"fontWeight": "400", "fontSize": "25px"}}></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="card-body" style={{"overflowY": "scroll"}}>
                            {chatChannelAvatars.partnerships_with_access.map((partnership, avatar_index) =>
                              <div className="d-flex flex-row align-items-center mb-3" key={avatar_index}>
                                <div className="flex-shrink-1">
                                  <div className="avatar">
                                    <div className="image-container rounded-circle"
                                          style={{
                                            "width": "30px",
                                            "height": "30px",
                                            "backgroundImage": "url('" + partnership.avatar_path + "')",
                                            "border": "1px solid white"
                                          }}>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex flex-row align-items-center flex-grow-1 ml-3">
                                  <div style={{"fontSize": "16px", "fontWeight": "700"}}>
                                    {partnership.name}
                                  </div>

                                  <div className="ml-3 p-2"
                                    style={{"fontSize": "14px", "fontWeight": "400", "backgroundColor": "#F5F6FB", "borderRadius": "50px", "color": "#4861A1"}}>
                                    {partnership.email}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Popup>
                  </span>
                ) : null}
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
      <div className="card-body pb-0"
           ref={parentRef}
           style={{"overflowY": "auto", "overflowX": "hidden"}}>
        {_.sortBy(messages, 'created_at').map((message, index) => (
          <div key={index}
               style={{"paddingBottom": "20px"}}
               className={"d-flex"}>
            <div className="d-flex align-items-end">
              <Tippy content={
                        <div className="text-center">
                          {message.name}
                        </div>
                      }
                      placement={'bottom-start'}
                      animation={'shift-away'}
                      theme='material'>
                  <div className="avatar hover-effect">
                    <div className="image-container rounded-circle"
                          style={{
                            "width": "30px",
                            "height": "30px",
                            "backgroundImage": "url('" + message.avatar_path + "')"
                          }}>
                    </div>
                  </div>
              </Tippy>
            </div>
            <div style={{"paddingLeft": "10px"}}>
              <div className="small">
                <strong>{message.name}</strong>
              </div>
              <div className="small text-muted mb-2"
                   style={{"marginTop": "-1px"}}>
                {message.human_created_at}
              </div>
              <div className="fr-view message p-2 rounded overflow-auto"
                   style={{"background": "#f2f6fd", "fontSize": "12px"}}>
                <div dangerouslySetInnerHTML={{__html: message.text}}>
                </div>
              </div>
            </div>
          </div>
        ))}
        <AlwaysScrollToBottom parentRef={parentRef} />
      </div>
      {canComment ? (
        <div className="card-footer bg-white p-2">
          <form onSubmit={
            (e) => {
              e.preventDefault();

              if(!canSubmitForm(chat)){
                return false;
              }

              consumer.send({
                command: 'message',
                  identifier: JSON.stringify({
                  channel: 'ChatChannelsChannel',
                  identifier: channelId,
                  partnershipId: partnership.id
                }),
                data: JSON.stringify({
                  action: 'send_message',
                  content: chat
                })
              });

              updateChat('');
            }
          }>
            <div className="form-group mb-2">
              <Froala id="new-chat-message"
                      onModelChange={
                        (html) => {
                          updateChat(html);
                        }
                      }
                      toolbarButtons={[
                        'bold', 'italic', 'underline', 'insertLink', 'insertImage', 'emoticons'
                      ]}
                      fontSizeDefaultSelection={'11'}
                      currentEvent={currentEvent}
                      currentPartnership={partnership}
                      csrfToken={csrfToken}
                      model={chat || ""} />
            </div>
            <div className="form-row">
              <div className="col-12 d-flex justify-content-end">
                <button className="btn btn-primary"
                        disabled={!canSubmitForm(chat)}
                        type="submit">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  )
};

ChatPage.propTypes = {
  chatChannelAvatars: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
  chatChannelType: PropTypes.string,
  chat: PropTypes.string,
  partnership: PropTypes.object.isRequired,
  messages: PropTypes.array,
  messageReceived: PropTypes.func.isRequired,
  updateChat: PropTypes.func.isRequired,
  webSocketHost: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  canComment: PropTypes.bool.isRequired
};

export default ChatPage;
