export const enabledTaskCompletionsByTag = (taskCompletions, previousTags, newTags, taskCompletionAssociation, tagIdsAccessor) => {
  var newTagsIds = newTags.map((tag) => tag.value);

  var addedTagIds = newTags
    .filter((tag) =>
      previousTags.every((t) => t.value !== tag.value)
    )
    .map((tag) =>
      tag.value
    );

  var removedTagIds = previousTags
    .filter((tag) =>
      newTags.every((t) => t.value !== tag.value)
    )
    .map((tag) =>
      tag.value
    );

  var updatedTaskCompletions = [...taskCompletions].map((taskCompletion) => {
    var taskTagIds = taskCompletion[taskCompletionAssociation][tagIdsAccessor];

    var matchesAddedTagId = taskTagIds
      .filter((tagId) =>
        addedTagIds.includes(tagId)
      ).length > 0;

    var shouldDisable = (
      taskTagIds.every((tagId) => !newTagsIds.includes(tagId))
        && taskTagIds.some((tagId) => removedTagIds.includes(tagId))
    );

    var shouldEnable = (
      !shouldDisable
        && (
          taskCompletion.enabled
            || matchesAddedTagId
        )
    );

    return Object.assign({}, taskCompletion, {enabled: shouldEnable});
  });

  return updatedTaskCompletions;
}
