import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";
import { ToastContainer } from 'react-toastify';

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const canSubmitForm = (inviteClaim, isSubmitting) => {
  return (
    inviteClaim
      && inviteClaim.short_code
      && inviteClaim.short_code.length === 10
      && !isSubmitting
  );
}

const ClaimInviteCode = ({
  csrfToken,
  inviteClaim,
  inviteClaimChanged,
  claimCode,
  ftux,
  isSubmitting,
  errorMessages,
  errorMessagesChanged
}) => (
  <React.Fragment>
    <ToastContainer />
    {ftux ? (
      <a href="#"
         style={{"fontSize": "16px"}}
         onClick={
           (e) => {
             e.preventDefault();
             inviteClaimChanged({short_code: ""});
           }
         }>
        Have an Invite Code from a PartnerHQ email?
      </a>
    ) : (
      <a href="#"
         style={{"fontSize": "14px"}}
         onClick={
           (e) => {
             e.preventDefault();
             inviteClaimChanged({short_code: ""});
           }
         }
         className="btn btn-outline-primary btn-sm">
        <i className="fa fa-plus-circle mr-2"></i>
        Claim Invite Code
      </a>
    )}
    <Popup
      open={Object.keys(inviteClaim).length > 0}
      contentStyle={modalContentStyle}
      className={"popup-modal"}
      closeOnDocumentClick={true}
      onClose={
        () => {
          inviteClaimChanged({});
          errorMessagesChanged([]);
        }
      }
    >
      <div className="popup-modal-container">
        <div className="row">
          <div className="col">
            <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(!canSubmitForm(inviteClaim, isSubmitting)){
                        return false;
                      }

                      claimCode(csrfToken, inviteClaim);
                    }
                  }>
              <div className="form-group">
                <label htmlFor="invite-code-short-code"
                       style={{"fontSize": "14px"}}
                       className="text-muted mb-1">
                  Invite Code
                </label>
                <input type="text"
                       className="form-control form-control-lg text-uppercase"
                       value={inviteClaim.short_code || ""}
                       maxLength={10}
                       onChange={
                         (e) => {
                           var updated = Object.assign({}, inviteClaim, {
                             short_code: e.target.value
                           });

                           inviteClaimChanged(updated);
                         }
                       }
                       id="invite-code-short-code"
                       placeholder="XXXXXXXXXX" />
                <p className="text-muted mb-0 mt-1 small">
                  Find your 10-digit Invite Code at the bottom of a PartnerHQ email
                </p>
              </div>
              {errorMessages.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger" role="alert">
                      <ul className="mb-0 pl-1">
                        {errorMessages.map((message, index) =>
                          <li key={index}>
                            {message}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-12 text-right">
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         inviteClaimChanged({});
                       }
                     }
                     style={{"fontSize": "14px"}}
                     className="mr-3">
                    <strong>Cancel</strong>
                  </a>
                  <button type="submit"
                          disabled={!canSubmitForm(inviteClaim, isSubmitting)}
                          style={{"fontSize": "14px"}}
                          className="btn btn-primary">
                    {isSubmitting ? (
                      <strong>
                        <img src="/loading.gif"
                             className="mr-2"
                             style={{width: "16px"}} />
                        Claim
                      </strong>
                    ) : (
                      <strong>Claim</strong>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>
  </React.Fragment>
);

ClaimInviteCode.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  inviteClaim: PropTypes.object,
  inviteClaimChanged: PropTypes.func.isRequired,
  claimCode: PropTypes.func.isRequired,
  ftux: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  errorMessages: PropTypes.array,
  errorMessagesChanged: PropTypes.func.isRequired
};

export default ClaimInviteCode;
