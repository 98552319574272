import { combineReducers } from 'redux';
import {
  INVITE_CLAIM_CHANGED,
  IS_SUBMITTING_CHANGED,
  ERROR_MESSAGES_CHANGED
} from '../constants/claimInviteCodeConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const inviteClaim = (state = {}, action) => {
  switch (action.type) {
    case INVITE_CLAIM_CHANGED:
      return action.inviteClaim;
    default:
      return state;
  }
};

const ftux = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isSubmitting = (state = false, action) => {
  switch (action.type) {
    case IS_SUBMITTING_CHANGED:
      return action.isSubmitting;
    default:
      return state;
  }
};

const errorMessages = (state = [], action) => {
  switch (action.type) {
    case ERROR_MESSAGES_CHANGED:
      return action.errorMessages;
    default:
      return state;
  }
};

const claimInviteCodeReducer = combineReducers({
  csrfToken,
  inviteClaim,
  ftux,
  isSubmitting,
  errorMessages
});

export default claimInviteCodeReducer;
