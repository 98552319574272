import PropTypes from 'prop-types';
import React from 'react';
import Popup from "reactjs-popup";

const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "padding": "15px"
}

const PartnershipUpload = ({
  isFtux,
  csrfToken,
  currentEvent,
  currentPartnership,
  uploadType
}) => (
  <span>
    <Popup
      contentStyle={settingsContentStyle}
      modal
      className={"popup-modal"}
      trigger={
        isFtux ? (
          <a href="#"
             onClick={
               (e) => {
                 e.preventDefault();
               }
             }
             className="text-muted small">
            <i className="fa fa-upload fa-fw mr-1"></i>
            Bulk Upload CSV
          </a>
        ) : (
          <button type="button"
                  className={"btn btn-primary"}
                  title="Bulk Upload CSV">
            <i className="fa fa-upload fa-fw"></i>
          </button>
        )
      }
      position="right center"
    >
      {close => (
        <div className="popup-modal-container">
          <div className="row">
            <div className="col">
              <h3 className="text-dark"
                  style={{"marginBottom": "16px"}}>
                Bulk Add Individuals via CSV
              </h3>

              <p className="text-small">
                You can add multiple individuals into PartnerHQ by using our CSV template found below. Simply download the template, add first names, last names, emails, and tags for all your individuals and then upload the file. All individuals will be added (but not yet invited) into your PartnerHQ project.
              </p>

              <p className="text-small">
                <i>
                  Important Note: Bulk uploading will not invite them to your project. It simply stores their information. Then, you can invite them individually or in bulk by following the steps in our Knowledge Base found here.
                </i>
              </p>

              <ol>
                <li>
                  <a href="/bulk-partnership-upload-template.csv" target="BLANK">Download our CSV template</a>

                  <p className="mt-3 text-muted">
                    Click to download our CSV template with sample data for an individual. Remove the sample data and add the details of your own individuals including names, emails, and tags.
                  </p>
                </li>

                <li>
                  Upload Individuals Into PartnerHQ

                  <p className="mt-3 text-muted">
                    Once you have downloaded and updated the template with the individuals associated with your project upload that file here.
                  </p>
                </li>

                <li>
                  Manage Individuals

                  <p className="mt-3 text-muted">
                    After you upload the file, PartnerHQ will add the individuals for you to view in your Admin Dashboard found <a href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id} target="BLANK">here</a>.
                  </p>
                </li>
              </ol>
            </div>
            <div className="col-xs-auto">
              <a href="#"
                className="text-dark"
                style={{"paddingRight": "15px"}}
                onClick={
                  (e) => {
                    e.preventDefault();
                    close();
                  }
                } >
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form action={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/event_uploads"}
                    method="POST"
                    encType="multipart/form-data">
                <input type="hidden" name="event_upload[upload_type]" value={uploadType} />
                <input type="hidden" name="authenticity_token" value={csrfToken} />

                <div className="row mb-3">
                  <div className="col-12">
                    <input type="file" name="event_upload[file]" className="form-control-file"></input>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 text-right">
                    <a className="text-muted mr-3"
                      href="#"
                      onClick={
                        (e) => {
                            e.preventDefault();
                            close();
                        }
                      }
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">Upload</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Popup>
  </span>
);

PartnershipUpload.propTypes = {
  isFtux: PropTypes.bool,
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  uploadType: PropTypes.string.isRequired
};

export default PartnershipUpload;
