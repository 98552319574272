import PropTypes from 'prop-types';
import React from 'react';

import Froala from '../../Froala/components/Froala';
import Tippy from '@tippyjs/react';
import { ToastContainer, toast } from 'react-toastify';

const EventForm = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  welcomeMessageChanged,
  resetWelcomeMessage
}) => (
  <div className="form-group">
    <ToastContainer />
    <div className="row">
      <div className="col">
        <label className="form-control-label" htmlFor="event-welcome-message">
          Welcome Message
        </label>
      </div>
      <div className="col-xs-auto"
           style={{"paddingRight": "15px"}}>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               resetWelcomeMessage(csrfToken, currentEvent, currentPartnership);
             }
           }
           className="small">
           <Tippy content={
                    <div>
                      <p className="mb-0">Reset your welcome message (to test)</p>
                    </div>
                  }
                  theme='material'
                  animation={'shift-away'}>
             <i className="fas fa-redo mr-2"></i>
           </Tippy>
        </a>
      </div>
    </div>
    <Froala id="event-welcome-message"
            onModelChange={
              (html) => {
                welcomeMessageChanged(html);
              }
            }
            fontSizeDefaultSelection={'11'}
            currentEvent={currentEvent}
            toolbarButtons={{
              'moreText': {
                'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'textColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
              },
              'moreParagraph': {
                'buttons': ['formatOLSimple', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
              },
              'moreRich': {
                'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
              },
              'moreMisc': {
                'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                'align': 'right',
                'buttonsVisible': 2
              }
            }}
            currentPartnership={currentPartnership}
            csrfToken={csrfToken}
            model={currentEvent.welcome_message || ""} />
    <input type="hidden"
           name="event[welcome_message]"
           value={currentEvent.welcome_message || ""} />
  </div>
);

EventForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  welcomeMessageChanged: PropTypes.func.isRequired,
  resetWelcomeMessage: PropTypes.func.isRequired
};

export default EventForm;
