import PropTypes from 'prop-types';
import ClaimInviteCodeApp from '../../ClaimInviteCode/startup/ClaimInviteCodeApp';
import React, { useEffect } from 'react';
import Grid from "./Grid";

const Events = ({
  activeFilter,
  events,
  token,
  filterChanged
}) => {
  useEffect(() => {
    filterChanged('in_progress')
  }, [])

  return (
    <div>
      <span>
        {events.some(event => event.archived || event.deactivated) ? (
          <nav className="nav nav-phq mb-4">
            <button className={"btn btn-link nav-link p-0 " + (activeFilter === 'in_progress' ? 'active' : '')}
                style={{"fontSize": "20px"}}
                type="button"
                onClick={
                  (e) => {
                    e.preventDefault();
                    filterChanged('in_progress')
                  }
                } >
              <strong>
                In Progress
              </strong>
            </button>
            {events.some(event => event.archived) ? (
              <button className={"btn btn-link nav-link p-0 ml-3 " + (activeFilter === 'archived' ? 'active' : '')}
                  type="button"
                  style={{"fontSize": "20px"}}
                  onClick={
                    (e) => {
                      e.preventDefault();
                      filterChanged('archived')
                    }
                  } >
                <strong>
                  Archived
                </strong>
              </button>
            ) : null }
            {events.some(event => event.deactivated) ? (
              <button className={"btn btn-link nav-link p-0 ml-3 " + (activeFilter === 'deactivated' ? 'active' : '')}
                  type="button"
                  style={{"fontSize": "20px"}}
                  onClick={
                    (e) => {
                      e.preventDefault();
                      filterChanged('deactivated')
                    }
                  } >
                <strong>
                  Completed
                </strong>
              </button>
            ) : null }
          </nav>
        ) : null}
      </span>

      <span>
        {activeFilter === 'archived' ? (
          <Grid events={events.filter(event => event.archived)} />
        ) : activeFilter === 'deactivated' ? (
          <Grid events={events.filter(event => event.deactivated)} />
        ) : (
          events.some(event => !event.archived && !event.deactivated) ? (
            <Grid events={events.filter(event => !event.archived && !event.deactivated)} />
          ) : (
            <div className="container">
              <div className="row">
                <div className="col-12" style={{"margin": "135px 0px"}}>
                  <div className="text-center">
                    <div className="d-flex justify-content-center align-items-center" style={{"background": "#f3ecec", "width": "100px", "height": "100px", "margin": "0 auto", "borderRadius": "20px"}}>
                      <i className="fas fa-rocket-launch" style={{"color": "#d9916a", "fontSize": "48px"}}></i>
                    </div>
                    <p style={{"fontSize": "22px", "marginTop": "18px", "color": "#545b79"}}>
                      <strong>Trying to find your Project?</strong>
                    </p>
                    <p className="text-muted" style={{"fontSize": "16px", "maxWidth": "365px", "margin": "0 auto"}}>
                      Please check your email for an invitation, or contact the event host if something went wrong.
                    </p>
                    <div style={{"marginTop": "18px"}}>
                      <ClaimInviteCodeApp csrfToken={token} ftux={true}></ClaimInviteCodeApp>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </span>
    </div>
  )
};

Events.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  filterChanged: PropTypes.func.isRequired
};

export default Events;
