import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Tippy from "@tippyjs/react";

const getCardStyle = function(taskCompletion){
  let backgroundColor = "white";
  let border = "1px solid #dadbe0";
  let opacity = 1;

  if (taskCompletion.task.is_internal) {
    border = "1px solid #4861A1";
  }

  if (taskCompletion.overdue) {
    backgroundColor = "#f3dadf";
    border = "1px solid #e28396";
  }
  
  if (!taskCompletion.task.is_published) {
    backgroundColor = "#BFDBFE";
    border = "1px solid #2563EB";
    opacity = 0.4;
  }

  return { backgroundColor, border, opacity }
}

const getCheckBoxBorderStyle = function(taskCompletion){
  let value = "2px solid #b5b7c1";

  if (taskCompletion.task.is_internal) {
    value = "1px solid #4861A1";
  }

  if (taskCompletion.completed_at) {
    value = "2px solid #8dc5b5";
  }

  if (taskCompletion.overdue) {
    value = "1px solid #e28396";
  }
  
  if (!taskCompletion.task.is_published) {
    value = "1px solid #2563EB";
  }

  return value;
}

const ToDoTaskCompletion = ({
  currentEvent,
  currentPartnership,
  taskCompletion,
  csrfToken,
  taskCompletionLabel,
  isLast
}) => {
  useEffect(() => {
    // ...
  }, [])

  return (
    <div className="col-12"
          style={{"marginBottom": (isLast ? "0px" : "10px")}}>
      <div className="card task-completion-dashboard-card"
            style={
              getCardStyle(taskCompletion)
            }>
        <div className="card-body text-truncate"
              style={{
                "fontSize": "14px",
                "padding": "20px 15px"
              }}>
          <div className='row d-flex align-items-center'>
            <div className='col-12 col-sm-6 col-lg-12 col-xl-8 task-completion-card-top-row'>
              <div className='form-row'>
                <div className='col-12 d-flex align-items-center'>
                  <span className="mr-2"
                        style={{
                          "display": "inline-block",
                          "borderRadius": "4px",
                          "border": getCheckBoxBorderStyle(taskCompletion),
                          "backgroundColor": "white",
                          "width": "16px",
                          "height": "16px"
                        }}>
                    <div className="d-flex align-items-center justify-content-center"
                          style={{"width": "16px"}}>
                      {taskCompletion.completed_at ? (
                        <i className="fas fa-check"
                          style={{
                              "fontSize": "10px",
                              "marginTop": "2px",
                              "marginLeft": "-4px",
                              "color": "#8dc5b5"
                          }}></i>
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </div>
                  </span>
                  <a href={"/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/task_completions/" + taskCompletion.id + "/edit"}
                      title={taskCompletionLabel(currentPartnership, taskCompletion)}
                      style={{"flexGrow": "1"}}
                      className="text-dark stretched-link text-truncate">
                    <span style={{
                            "color": "#97989f",
                            "textDecoration": (taskCompletion.completed_at ? "line-through" : "none")
                          }}>
                      <span className="text-dark">
                        {taskCompletion.overdue ? (
                          <strong>{taskCompletionLabel(currentPartnership, taskCompletion)}</strong>
                        ) : (
                          taskCompletionLabel(currentPartnership, taskCompletion)
                        )}
                      </span>
                    </span>
                  </a>
                  {taskCompletion.task.is_internal ? (
                    <span className="badge badge-secondary ml-2"
                          style={{
                            "borderRadius": "4px",
                            "padding": "4px 8px",
                            "fontSize": "10px",
                            "backgroundColor": "#4861A1",
                            "color": "white"
                          }}>
                      <strong>Internal</strong>
                    </span>
                  ) : null}
                  {!taskCompletion.task.is_published ? (
                    <span className="badge badge-primary ml-2"
                          style={{
                            "borderRadius": "4px",
                            "padding": "4px 8px",
                            "fontSize": "10px",
                            "backgroundColor": "#2563EB",
                            "color": "#white"
                          }}>
                      <strong>Draft</strong>
                    </span>
                  ) : taskCompletion.overdue ? (
                    <span className="badge badge-secondary ml-2"
                          style={{
                            "borderRadius": "4px",
                            "padding": "4px 8px",
                            "fontSize": "10px",
                            "backgroundColor": "#db546b",
                            "color": "white"
                          }}>
                      <strong>Overdue</strong>
                    </span>
                  ) : taskCompletion.due_tomorrow ? (
                    <span className="badge badge-secondary ml-2"
                          style={{
                            "borderRadius": "4px",
                            "padding": "4px 8px",
                            "fontSize": "10px",
                            "backgroundColor": "white",
                            "border": "1px solid #f1c87b",
                            "color": "#f1c87b"
                          }}>
                      <strong>Tomorrow</strong>
                    </span>
                  ) : null }
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-lg-12 col-xl-4'>
              <div className='form-row'>
                <div className='col-12 d-flex align-items-center'
                     style={{"fontSize": "10px"}}>
                  <p className='mb-0 task-completion-due-on' style={{"marginTop": "3px"}}>
                    {taskCompletion.human_due_on}
                  </p>
                  <p className='mb-0 text-right' style={{"marginTop": "3px", "flexGrow": "1"}}>
                    <i className="far fa-comment mr-1"></i>
                    {taskCompletion.number_of_messages.toLocaleString()} Comments
                  </p>
                  {taskCompletion.assigned_partnership ? (
                    <Tippy content={
                              <div className="text-center">
                                {taskCompletion.assigned_partnership.name}
                              </div>
                            }
                            placement={'bottom-start'}
                            animation={'shift-away'}
                            theme='material'>
                        <div className="image-container rounded-circle ml-2"
                              style={{
                                "width": "25px",
                                "height": "25px",
                                "backgroundImage": "url('" + taskCompletion.assigned_partnership.avatar_path + "')"
                              }}>
                        </div>
                      </Tippy>
                    ) : (
                      <div style={{
                            "width": "0px",
                            "height": "25px",
                           }}>
                        &nbsp;
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

ToDoTaskCompletion.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  taskCompletion: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  taskCompletionLabel: PropTypes.func.isRequired,
  isLast: PropTypes.bool.isRequired
};

export default ToDoTaskCompletion;
