/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  CUSTOM_FIELD_VALUE_CHANGED
} from '../constants/taskCompletionGridConstants';

export const uploadCustomFieldValueFile = (dispatch, csrfToken, currentEvent, currentPartnership, file, customFieldValue) => {
  return dispatch => {
    var updated = Object.assign({}, customFieldValue, {isUploading: true});
    dispatch(customFieldValueChanged(updated));

    dispatch(
      createCustomFieldFileUpload(
        dispatch,
        csrfToken,
        currentEvent,
        currentPartnership,
        file,
        (customFieldFileUpload) => {
          var value = customFieldFileUpload.token;

          dispatch(
            updateCustomFieldValue(
              dispatch,
              csrfToken,
              currentEvent,
              currentPartnership,
              customFieldValue,
              value,
              (customFieldValue) => {
                var updated = Object.assign({}, customFieldValue, {isUploading: false});
                dispatch(customFieldValueChanged(updated));

                toast.success("File has been uploaded", {
                  position: toast.POSITION.TOP_CENTER,
                  draggable: false,
                  closeOnClick: false,
                  autoClose: 5000,
                  hideProgressBar: true
                });
              }
            )
          );
        }
      )
    );
  };
};

export const createCustomFieldFileUpload = (dispatch, csrfToken, currentEvent, currentPartnership, file, onCreate) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var formData = new FormData();
    formData.append("custom_field_file_upload[file]", file);

    return axios({
        method: "post",
        url: ("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/custom_field_file_uploads"),
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(({ data }) => {
        onCreate(data);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const updateCustomFieldValue = (dispatch, csrfToken, currentEvent, currentPartnership, customFieldValue, value, onUpdate) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/e/" + currentEvent.permalink + "/p/" + currentPartnership.id + "/custom_field_values/" + customFieldValue.id, {
        custom_field_value: {
          value: value
        }
      })
      .then(({ data }) => {
        onUpdate(data);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const customFieldValueChanged = (customFieldValue) => ({
  type: CUSTOM_FIELD_VALUE_CHANGED,
  customFieldValue
});
