/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  INVITATION_CREATED
} from '../constants/partnershipInvitationButtonConstants';

export const createPartnershipInvitation = (dispatch, csrfToken, event, currentPartnership, partnership) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/e/" + event.permalink + "/p/" + currentPartnership.id + "/partnership_invitations.json", {
        memberable_id: partnership.id
      })
      .then(({ data }) => {
        dispatch(invitationCreated(data));
      })
  };
};

export const invitationCreated = (invitation) => ({
  type: INVITATION_CREATED,
  invitation
});

export const resendInvitation = (dispatch, csrfToken, event, currentPartnership, invitation) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/e/" + event.permalink + "/p/" + currentPartnership.id + "/partnership_invitations/" + invitation.id + "/resend")
      .then(({ data }) => {
        toast.dark('Invitation resent', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
  };
};
