// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ChatInbox from '../components/ChatInbox';
import * as actions from '../actions/chatInboxActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentEvent: state.currentEvent,
  currentPartnership: state.currentPartnership,
  messages: state.messages,
  nextPage: state.nextPage,
  hasMore: state.hasMore,
  inboxModalIsOpen: state.inboxModalIsOpen
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInboxMessages: (currentEvent, currentPartnership, messages, nextPage) => dispatch(actions.fetchInboxMessages(dispatch, currentEvent, currentPartnership, messages, nextPage)),
    inboxModalIsOpenChanged: (inboxModalIsOpen) => dispatch(actions.inboxModalIsOpenChanged(inboxModalIsOpen))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ChatInbox);
