import { combineReducers } from 'redux';
import {
  MESSAGES_CHANGED,
  NEXT_PAGE_CHANGED,
  HAS_MORE_CHANGED,
  INBOX_MODAL_IS_OPEN_CHANGED
} from '../constants/chatInboxConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentEvent = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentPartnership = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const messages = (state = [], action) => {
  switch (action.type) {
    case MESSAGES_CHANGED:
      return action.messages;
    default:
      return state;
  }
};

const nextPage = (state = 1, action) => {
  switch (action.type) {
    case NEXT_PAGE_CHANGED:
      return action.nextPage;
    default:
      return state;
  }
};

const hasMore = (state = true, action) => {
  switch (action.type) {
    case HAS_MORE_CHANGED:
      return action.hasMore;
    default:
      return state;
  }
};

const inboxModalIsOpen = (state = false, action) => {
  switch (action.type) {
    case INBOX_MODAL_IS_OPEN_CHANGED:
      return action.inboxModalIsOpen;
    default:
      return state;
  }
};

const chatInboxReducer = combineReducers({
  csrfToken,
  currentEvent,
  currentPartnership,
  messages,
  nextPage,
  hasMore,
  inboxModalIsOpen
});

export default chatInboxReducer;
