import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { CopyToClipboard}  from 'react-copy-to-clipboard';

const CopyTextComponent = ({
  text
}) => {
  useEffect(() => {
    //...
  }, [])

  return (
    <span>
      <CopyToClipboard text={text}
                        onCopy={
                          () => {
                            // I don't know why the position isn't working, but I think
                            // we can follow up when we move the toast to the main app layout
                            toast.dark('Text copied to Clipboard', {
                              position: toast.POSITION.BOTTOM_RIGHT,
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        }>

            <button className="btn btn-link"
                    onClick={
                      (e) =>  {
                        e.preventDefault();
                      }
                    }
                    style={{"borderRadius": "8px"}}>
              <div className="d-flex">
                <div className="flex-shrink-1 mr-2">
                  <i className="far fa-copy"></i>
                </div>
                <div className="flex-grow-1 overflow-ellipsis" style={{maxWidth: '200px'}}>
                  {text}
                </div>
              </div>
            </button>
      </CopyToClipboard>
    </span>
  )
};

CopyTextComponent.propTypes = {
  text: PropTypes.string.isRequired
};

export default CopyTextComponent;
