import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import { ToastContainer, toast } from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "300px",
  "padding": "0px",
  "marginTop": "10px",
  "zIndex": "3000"
}

const PartnershipInvitationButton = ({
  csrfToken,
  currentPartnership,
  currentEvent,
  partnership,
  createPartnershipInvitation,
  resendInvitation,
  invitationUrl
}) => (
  <div className="row task-completion-assignment"
       style={{
         top: "-45px"
       }}>
    <ToastContainer />
    <div className="text-muted"
         style={{"fontSize": "14px"}}>
      <Popup arrow={false}
             offsetY={-5}
             position="bottom right"
             contentStyle={settingsContentStyle}
             trigger={open => (
            <button style={{"fontSize": "14px"}}
               className="text-muted btn btn-light">
              <span>
                {partnership.user && partnership.user.id ? (
                  <span>
                    User has access
                    <i className="fad fa-circle ml-2"
                       style={{"color": "#10B981"}}></i>
                  </span>
                ) : partnership.invitation && partnership.invitation.id ? (
                  <span>
                    Invitation sent
                    <i className="fad fa-circle ml-2"
                       style={{"color": "#F59E0B"}}></i>
                  </span>
                ) : (
                  <span>
                    No user access
                    <i className="fad fa-circle ml-2"
                       style={{"color": "#EF4444"}}></i>
                  </span>
                )}
              </span>
            </button>
         )} >
           {close => (
             <div className='text-left p-3'>
              <form onSubmit={
                (e) => {
                  e.preventDefault();
                }
              }>
                {partnership.user && partnership.user.id ? (
                  <div className="form-group mb-0">
                    <label className="text-muted small mb-0">
                      This individual has access to see their resources and manage their assigned tasks.
                    </label>
                  </div>
                ) : partnership.invitation && partnership.invitation.id ? (
                  <div className="form-group mb-0">
                    <label className="text-muted small">
                      An invitation email has been sent to the individual (be sure to check spam).
                    </label>
                    <button className="btn btn-primary btn-sm"
                            onClick={
                              (e) =>  {
                                e.preventDefault();
                                resendInvitation(csrfToken, currentEvent, currentPartnership, partnership.invitation);
                                close();
                              }
                            }
                            style={{"borderRadius": "8px"}}>
                      Resend invitation
                    </button>
                    {invitationUrl && invitationUrl.length > 0 ? (
                      <div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-xs-auto"
                               style={{"paddingLeft": "15px"}}>
                            <CopyToClipboard text={invitationUrl}
                                              onCopy={
                                                () => {
                                                  toast.dark('Invitation link copied to Clipboard', {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: true,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                  });
                                                }
                                              }>
                               <button className="btn btn-outline-secondary btn-sm"
                                       onClick={
                                         (e) =>  {
                                           e.preventDefault();
                                         }
                                       }
                                       style={{"borderRadius": "8px"}}>
                                 <i className="fas fa-copy"></i>
                               </button>
                            </CopyToClipboard>
                          </div>
                          <div className="col">
                            <p className="text-muted small mb-0">
                              Can't find the email? Send the accept invitation link manually...
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-xs-auto"
                               style={{"paddingLeft": "15px"}}>
                            <CopyToClipboard text={partnership.invitation.short_code}
                                              onCopy={
                                                () => {
                                                  toast.dark('Invite code copied to Clipboard', {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: true,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                  });
                                                }
                                              }>
                               <button className="btn btn-outline-secondary btn-sm"
                                       onClick={
                                         (e) =>  {
                                           e.preventDefault();
                                         }
                                       }
                                       style={{"borderRadius": "8px"}}>
                                 <i className="fas fa-copy"></i>
                               </button>
                            </CopyToClipboard>
                          </div>
                          <div className="col">
                            <p className="text-muted small mb-0">
                              Or, instruct the individual to create (or sign in with) a PartnerHQ user account and use the following Invite Code: <kbd>{partnership.invitation.short_code}</kbd>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="form-group mb-0">
                    <label className="text-muted small">
                      Send an invitation to grant user access
                    </label>
                    <button className="btn btn-primary btn-sm"
                            onClick={
                              (e) =>  {
                                e.preventDefault();
                                createPartnershipInvitation(csrfToken, currentEvent, currentPartnership, partnership);
                              }
                            }
                            style={{"borderRadius": "8px"}}>
                      Send invitation
                    </button>
                  </div>
                )}
              </form>
             </div>
           )}
         </Popup>
    </div>
  </div>
);

PartnershipInvitationButton.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  currentEvent: PropTypes.object.isRequired,
  partnership: PropTypes.object.isRequired,
  createPartnershipInvitation: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  invitationUrl: PropTypes.string
};

export default PartnershipInvitationButton;
