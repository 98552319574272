import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Masonry from 'react-masonry-css'
import Tippy from "@tippyjs/react";

const breakpointColumnsObj = {
  default: 3,
  768: 2,
  576: 1
};

const Events = ({
  events
}) => {
  useEffect(() => {
  }, [])

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column">
      {
        _.sortBy(events, 'created_at').reverse().map((event, index) =>
          <div key={index}
               onClick={
                 (e) => {
                   e.preventDefault();

                   var link = ("/e/" + event.permalink + "/p/" + event.serialize_for_partnership.id);
                   window.location.href = link;
                 }
               }
               className={"card clickable-card border-0"}>
            <div className="card">
              {event.logo_thumb_url ? (
                <div className="card-header d-flex" style={{"backgroundColor": event.brand_color_hex}}>
                  <div dangerouslySetInnerHTML={{__html: event.event_logo_tag}}></div>
                </div>
              ) : null }
              <div className="card-body" style={{"padding": "24px 20px"}}>
                <div className="card-title mb-0">
                  <h5 className="text-dark mb-1" style={{"fontSize": "18px"}}>
                    <strong>
                      {event.name}
                    </strong>
                  </h5>
                  <p className="mb-3" style={{"fontSize": "16px", "color": "#C3C3CF"}}>
                    <strong>
                      {event.human_created_at}
                    </strong>
                  </p>
                  <p className="mb-4">
                    {event.truncated_description_no_tags}
                  </p>
                  <div className="mb-0" key={index}>
                    <React.Fragment>
                      <div className="d-flex flex-row dashboard-avatars">
                        {event.adjactent_avatar_partnerships.map((partnership, avatar_index) =>
                          <Tippy content={
                                    <div className="text-center">{partnership.name}</div>
                                  }
                                  key={avatar_index}
                                  placement={'bottom-start'}
                                  animation={'shift-away'}
                                  theme='material'>
                              <div className="avatar hover-effect">
                                <div className="image-container rounded-circle"
                                    style={{
                                      "width": "40px",
                                      "height": "40px",
                                      "backgroundImage": "url('" + partnership.avatar_path + "')",
                                      "border": "1px solid white"
                                    }}>
                                </div>
                              </div>
                          </Tippy>
                        )}
                        {(event.adjacent_partnerships_count - 3) > 0 ? (
                          <div className="rounded-circle round-40 border font-weight-bold border-white d-flex flex-row align-items-center justify-content-center text-white">
                            +{event.adjacent_partnerships_count - 3}
                          </div>
                        ) : null }
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </Masonry>
  )
};

Events.propTypes = {
  events: PropTypes.array.isRequired
};

export default Events;
