import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import CustomFieldValueCell from './CustomFieldValueCell';
import { ToastContainer, toast } from 'react-toastify';

const TaskCompletionGrid = ({
  csrfToken,
  currentEvent,
  currentPartnership,
  tasks,
  organizationPartnerships,
  customFieldValues,
  uploadCustomFieldValueFile
}) => {
  const gridTable = useRef(null);

  // var data = hotTableComponent.current.hotInstance.getData(row, column, row2, column2);
  useEffect(() => {
    if(gridTable.current){
      new FreezeTable(gridTable.current, {
        'scrollable': true
      });
    }
  }, [gridTable]);

  return (
  <div ref={gridTable}
        style={{
          "overflow": "scroll",
          "maxWidth": "1000px",
          "maxHeight": "700px",
        }}>
    <ToastContainer />
    <table style={{
             "background": "white",
             "fontSize": "14px"
           }}
           className="table table-bordered table-sm">
      <thead>
        <tr>
          <th className="px-2">
            &nbsp;
          </th>
          {tasks.filter((t) => t.custom_fields.length > 0).map((task, index) =>
            <th key={index}
                colSpan={task.custom_fields.length}
                className="text-nowrap px-2 small">
              {task.label}
            </th>
          )}
        </tr>
        <tr>
          <th className="px-2">
            &nbsp;
          </th>
          {tasks.filter((t) => t.custom_fields.length > 0).map((task, index) =>
            <React.Fragment key={index}>
              {task.custom_fields.map((customField, index) =>
                <th key={index}
                    className="text-truncate px-2"
                    style={{"maxWidth": "200px"}}
                    title={customField.name}>
                  {customField.name}
                </th>
              )}
            </React.Fragment>
          )}
        </tr>
      </thead>
      <tbody>
        {organizationPartnerships.map((organizationPartnership, index) =>
          <tr key={index}>
            <td className="text-nowrap px-2">
              <strong>{organizationPartnership.name}</strong>
            </td>
            {tasks.filter((t) => t.custom_fields.length > 0).map((task, index) =>
              <React.Fragment key={index}>
                {task.custom_fields.map((customField, index) =>
                  <CustomFieldValueCell key={index}
                                        organizationPartnership={organizationPartnership}
                                        customFieldValues={customFieldValues}
                                        uploadCustomFieldValueFile={uploadCustomFieldValueFile}
                                        csrfToken={csrfToken}
                                        currentEvent={currentEvent}
                                        currentPartnership={currentPartnership}
                                        customField={customField} />
                )}
              </React.Fragment>
            )}
          </tr>
        )}
      </tbody>
    </table>
  </div>
  );
};

TaskCompletionGrid.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  tasks: PropTypes.array,
  organizationPartnerships: PropTypes.array,
  customFieldValues: PropTypes.array,
  uploadCustomFieldValueFile: PropTypes.func.isRequired
};

export default TaskCompletionGrid;
