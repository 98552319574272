import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Tippy from "@tippyjs/react";
import Popup from "reactjs-popup";
const axios = require('axios').default;
var _ = require('lodash');

import ChatApp from '../../Chat/startup/ChatApp';
import Menu from './Menu';
import Resources from "./Resources";
import Tasks from "./Tasks";

import {
  ALL_TASKS,
  ARCHIVED_TASKS
} from '../constants/dashboardConstants';

import {
  ALL_RESOURCES,
  ARCHIVED_RESOURCES
} from '../constants/dashboardConstants';

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "560px",
  "padding": "15px"
}

const showFirstTimeWelcomeMessage = function(currentEvent, currentPartnership, currentUserPartnership){
  return (
    currentPartnership.id === currentUserPartnership.id
      && !currentPartnership.dismissed_welcome_message_at
      && currentEvent.welcome_message_no_tags
      && currentEvent.welcome_message_no_tags.length > 0
  );
}

const taskCompletionLabel = function(currentPartnership, taskCompletion){
  if(currentPartnership.has_multiple_organization_partnerships) {
    return taskCompletion.label_with_organization;
  } else {
    return taskCompletion.label;
  }
}

const scrollToResources = function(e){
  e.preventDefault();

  $('#resources')[0]
    .scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });

  $(".card-header", "#resources")
    .addClass("highlight")

  setTimeout(() => {
    $(".card-header", "#resources")
      .removeClass("highlight");
  }, 2000);
}

const scrollToTasks = function(e){
  e.preventDefault();

  $('#tasks')[0]
    .scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });

  $(".card-header", "#tasks")
    .addClass("highlight")

  setTimeout(() => {
    $(".card-header", "#tasks")
      .removeClass("highlight");
  }, 2000);
}

const scrollToChat = function(e){
  e.preventDefault();

  $('.chat-instance:visible')[0]
    .scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  
  $(".card-header", '.chat-instance:visible')
    .addClass("highlight")

  setTimeout(() => {
    $(".card-header", '.chat-instance:visible')
      .removeClass("highlight");
  }, 2000);
}

const unarchivedTaskCompletions = function(taskCompletions){
  return (
    taskCompletions.filter((tc) => !tc.task.archived)
  );
}

const archivedTaskCompletions = function(taskCompletions){
  return (
    taskCompletions.filter((tc) => tc.task.archived)
  );
}

const taskCompletionsForMenuState = function(taskCompletions, taskMenuState){
  if (taskMenuState === ALL_TASKS){
    return unarchivedTaskCompletions(taskCompletions);
  } else if (taskMenuState === ARCHIVED_TASKS){
    return archivedTaskCompletions(taskCompletions);
  }
}

const DashboardPage = ({
  currentEvent,
  currentPartnership,
  currentUserPartnership,
  resourceTaskCompletions,
  dismissWelcomeMessage,
  welcomeMessageOpened,
  welcomeMessageOpenedChanged,
  csrfToken,
  todoTaskCompletions,
  chatChannelAvatars,
  chatChannelId,
  chatChannelType,
  chatChannelMessages,
  webSocketHost,
  taskMenuState,
  taskMenuStateChanged,
  resourceMenuState,
  resourceMenuStateChanged,
  chatHistoryExists,
  canSeeSettings,
  canComment,
  canSeePermissions,
  canSeeInternalTasks
}) => {
  useEffect(() => {
    if (showFirstTimeWelcomeMessage(currentEvent, currentPartnership, currentUserPartnership)) {
      welcomeMessageOpenedChanged(true);
    }
  }, [])

  return (
    <div className="d-flex" id="dashboard-wrapper">
      <div id="dashboard-sidebar" style={{"zIndex": "1"}}>
        <div className="row sticky-top">
          <div className="col-12 py-3" style={{"paddingLeft": "30px"}}>
            <Menu currentEvent={currentEvent}
                  currentUserPartnership={currentUserPartnership}
                  csrfToken={csrfToken}
                  scrollToResources={scrollToResources}
                  scrollToTasks={scrollToTasks}
                  scrollToChat={scrollToChat}
                  chatHistoryExists={chatHistoryExists}
                  canSeeSettings={canSeeSettings}
                  canSeePermissions={canSeePermissions}
                  currentPartnership={currentPartnership}
                  canSeeInternalTasks={canSeeInternalTasks} />
          </div>
        </div>
      </div>
      <div id="dashboard-content">
        <div className="container-fluid py-3">
          <div className="form-row">
            <div className="col-xs-auto d-inline-block d-md-none"
                 style={{"maxWidth": "50px"}}>
              <div className="form-row sticky-top py-3">
                <div className="col-12">
                  <div className="btn-group-vertical">
                    <a href="#resources"
                       onClick={scrollToResources}
                       className="btn btn-light btn-sm">
                      <Tippy content={"Resources"}
                              placement={'right'}
                              offset={[0, 20]}
                              animation={'shift-away'}
                              theme='material'>
                          <i className='fab fa-readme fa-fw'></i>
                      </Tippy>
                    </a>
                    <a href="#tasks"
                       onClick={scrollToTasks}
                       className="btn btn-light btn-sm">
                      <Tippy content={"Tasks"}
                                placement={'right'}
                                offset={[0, 20]}
                                animation={'shift-away'}
                                theme='material'>
                        <i className='fas fa-check-circle fa-fw'></i>
                      </Tippy>
                    </a>
                    <a href="#chat"
                       onClick={scrollToChat}
                       className="btn btn-light btn-sm">
                      <Tippy content={"Comments"}
                                  placement={'right'}
                                  offset={[0, 20]}
                                  animation={'shift-away'}
                                  theme='material'>
                        <i className='fas fa-comment-alt-lines fa-fw'></i>
                      </Tippy>
                    </a>
                  </div>
                  <div className="btn-group-vertical mt-2">
                    <a href={`/e/${currentEvent.permalink}/p/${currentPartnership.id}/project_settings/current/edit`}
                       className="btn btn-light btn-sm">
                      <Tippy content={"Project Settings"}
                              placement={'right'}
                              offset={[0, 20]}
                              animation={'shift-away'}
                              theme='material'>
                          <i className='fa fa-cog fa-fw'></i>
                      </Tippy>
                    </a>
                  </div>
                  <div className="btn-group-vertical mt-2">
                    <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/host`}
                       className="btn btn-light btn-sm">
                      <Tippy content={"Host Overview"}
                              placement={'right'}
                              offset={[0, 20]}
                              animation={'shift-away'}
                              theme='material'>
                          <i className='fas fa-chart-line fa-fw'></i>
                      </Tippy>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-row">
                <div className="col-12 col-lg-6 col-xl-8">
                  <div className="form-row">
                    <div className="col-12 mb-3" id="resources">
                      <Resources currentEvent={currentEvent}
                                  currentPartnership={currentPartnership}
                                  resourceTaskCompletions={
                                    resourceMenuState === ALL_RESOURCES ? (
                                      unarchivedTaskCompletions(resourceTaskCompletions)
                                    ) : (
                                      archivedTaskCompletions(resourceTaskCompletions)
                                    )
                                  }
                                  taskCompletionLabel={taskCompletionLabel}
                                  unarchivedTaskCompletions={unarchivedTaskCompletions(resourceTaskCompletions)}
                                  archivedTaskCompletions={archivedTaskCompletions(resourceTaskCompletions)}
                                  resourceMenuState={resourceMenuState}
                                  resourceMenuStateChanged={resourceMenuStateChanged}
                                  csrfToken={csrfToken} />
                    </div>
                    <div className="col-12 mb-3" id="tasks">
                      <Tasks currentEvent={currentEvent}
                            currentPartnership={currentPartnership}
                            pendingTaskCompletions={
                              taskCompletionsForMenuState(todoTaskCompletions, taskMenuState).filter((tc) => !tc.completed_at)
                            }
                            completedTaskCompletions={
                              taskCompletionsForMenuState(todoTaskCompletions, taskMenuState).filter((tc) => tc.completed_at)
                            }
                            unfilteredTaskCompletions={todoTaskCompletions}
                            taskCompletionLabel={taskCompletionLabel}
                            unarchivedTaskCompletions={unarchivedTaskCompletions(todoTaskCompletions)}
                            archivedTaskCompletions={archivedTaskCompletions(todoTaskCompletions)}
                            taskMenuState={taskMenuState}
                            taskMenuStateChanged={taskMenuStateChanged}
                            csrfToken={csrfToken} />
                    </div>
                    <div className="col-12 d-block d-lg-none chat-instance" style={{"maxHeight": "550px"}}>
                      <ChatApp chatChannelAvatars={chatChannelAvatars}
                              channelId={chatChannelId}
                              chatChannelType={chatChannelType}
                              partnership={currentUserPartnership}
                              messages={chatChannelMessages}
                              webSocketHost={webSocketHost}
                              currentEvent={currentEvent}
                              canComment={canComment}
                              csrfToken={csrfToken} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-4 d-none d-lg-block" style={{"zIndex": "1"}}>
                  <div className="form-row sticky-top">
                    <div className="col-12 chat-instance" style={{"maxHeight": "550px"}}>
                      <ChatApp chatChannelAvatars={chatChannelAvatars}
                              channelId={chatChannelId}
                              chatChannelType={chatChannelType}
                              partnership={currentUserPartnership}
                              messages={chatChannelMessages}
                              webSocketHost={webSocketHost}
                              currentEvent={currentEvent}
                              canComment={canComment}
                              csrfToken={csrfToken} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        open={welcomeMessageOpened}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={false}
        className={"popup-modal"}
        onClose={
          () => {
            welcomeMessageOpenedChanged(false);
            dismissWelcomeMessage(csrfToken, currentEvent, currentPartnership);
          }
        }
      >
        {close => (
          <div className="popup-modal-container">
            <div className="row">
              <div className="col">
                <div className="fr-view message">
                  <div dangerouslySetInnerHTML={{__html: currentEvent.sanitized_welcome_message}}>
                  </div>
                </div>
              </div>
            </div>
            <div className="row border-top pt-3">
              <div className="col text-right">
                <a href="#"
                    className="btn btn-primary"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        close();
                      }
                    } >
                  <strong>Close</strong>
                </a>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  )
};

DashboardPage.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  currentUserPartnership: PropTypes.object.isRequired,
  resourceTaskCompletions: PropTypes.array,
  dismissWelcomeMessage: PropTypes.func.isRequired,
  welcomeMessageOpened: PropTypes.bool,
  welcomeMessageOpenedChanged: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  todoTaskCompletions: PropTypes.array,
  chatChannelAvatars: PropTypes.object.isRequired,
  chatChannelId: PropTypes.string,
  chatChannelType: PropTypes.string,
  chatChannelMessages: PropTypes.array,
  webSocketHost: PropTypes.string.isRequired,
  taskMenuState: PropTypes.string,
  taskMenuStateChanged: PropTypes.func.isRequired,
  resourceMenuState: PropTypes.string,
  resourceMenuStateChanged: PropTypes.func.isRequired,
  chatHistoryExists: PropTypes.bool,
  canSeeSettings: PropTypes.bool,
  canComment: PropTypes.bool,
  canSeePermissions: PropTypes.bool,
  canSeeInternalTasks: PropTypes.bool
};

export default DashboardPage;
