import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import ChatInboxApp from '../../ChatInbox/startup/ChatInboxApp';

const Menu = ({
  currentEvent,
  currentPartnership,
  currentUserPartnership,
  csrfToken,
  scrollToResources,
  scrollToTasks,
  scrollToChat,
  chatHistoryExists,
  canSeeSettings,
  canSeePermissions,
  canSeeInternalTasks
}) => {
  useEffect(() => {
    // ...
  }, [])

  return (
    <React.Fragment>
      <ul className="list-group" style={{"fontSize": "14px"}}>
        <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
          <a href="#resources"
             onClick={scrollToResources}
             className='text-dark stretched-link'>
            Resources
          </a>
        </li>
        <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
          <a href="#tasks"
             onClick={scrollToTasks}
             className='text-dark stretched-link'>
            Tasks
          </a>
        </li>
        <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px 12px 0px"}}>
          <a href="chat"
             onClick={scrollToChat}
             className='text-dark stretched-link'>
            Comments
          </a>
        </li>
      </ul>
      <ul className="list-group" style={{"fontSize": "10px"}}>
        <li className="list-group-item bg-transparent pl-0 border-0 py-1">
          <a href={`/e/${currentEvent.permalink}/p/${currentPartnership.id}/project_settings/current/edit`}
             className='text-muted'>
            <i className="fas fa-cog fa-fw mr-1"></i>
            Project Settings
          </a>
        </li>
        {currentEvent.allow_teammate_invitations && currentPartnership.organization_partnerships_count > 0 ? (
          <li className="list-group-item bg-transparent pl-0 border-0 py-1">
            <a href={`/e/${currentEvent.permalink}/p/${currentPartnership.id}/project_settings/current/edit#add-teammate`}
              className='text-muted'>
              <i className="fas fa-plus fa-fw mr-1"></i>
              Add Teammates
            </a>
          </li>
        ) : null}
        {chatHistoryExists ? (
          <li className="list-group-item bg-transparent pl-0 border-0 py-1">
            <ChatInboxApp csrfToken={csrfToken}
                          currentEvent={currentEvent}
                          currentPartnership={currentPartnership} />
          </li>
        ) : null}
      </ul>
      {currentUserPartnership.host ? (
        <React.Fragment>
          <hr style={{
                "borderTop": "1px solid #e5e5e7",
                "marginTop": "30px",
                "marginBottom": "30px"
              }} />
          <p className='text-muted' style={{"fontSize": "10px", "marginBottom": "8px"}}>
            HOST SETTINGS
          </p>
          <ul className="list-group" style={{"fontSize": "14px"}}>
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/host`}
                className='text-dark stretched-link'>
                <i className="fas fa-chart-line fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Host Overview
              </a>
            </li>
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/tags`}
                className='text-dark stretched-link'>
                <i className="fas fa-tag fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Tags
              </a>
            </li>
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/organization_partnerships`}
                className='text-dark stretched-link'>
                <i className="fas fa-sitemap fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Organizations
              </a>
            </li>
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/partnerships`}
                className='text-dark stretched-link'>
                <i className="fas fa-users fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Individuals
              </a>
            </li>
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/invitations`}
                className='text-dark stretched-link'>
                <i className="fas fa-users-medical fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Invitations
              </a>
            </li>
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/tasks`}
                className='text-dark stretched-link'>
                <i className="fas fa-tasks-alt fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Tasks
              </a>
            </li>
            {canSeeInternalTasks ? (
              <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
                <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/internal_tasks`}
                  className='text-dark stretched-link'>
                  <i className="fas fa-tasks-alt fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                  Internal Tasks
                </a>
              </li>
            ) : null}
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/resources`}
                className='text-dark stretched-link'>
                <i className="fas fa-book fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Resources
              </a>
            </li>
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/announcements`}
                className='text-dark stretched-link'>
                <i className="fas fa-megaphone fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Announcements
              </a>
            </li>
            <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
              <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/messages`}
                className='text-dark stretched-link'>
                <i className="fas fa-envelope fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                Comments
              </a>
            </li>
            {canSeePermissions ? (
              <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
                <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/authorizations`}
                  className='text-dark stretched-link'>
                  <i className="fas fa-shield-check fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                  Permissions
                </a>
              </li>
            ) : null}
            {canSeeSettings ? (
              <li className="list-group-item bg-transparent border-0" style={{"padding": "6px 0px"}}>
                <a href={`/e/${currentEvent.permalink}/p/${currentUserPartnership.id}/edit`}
                  className='text-dark stretched-link'>
                  <i className="fas fa-cog fa-fw smaller" style={{"marginRight": "9px", "color": "#cbccd2"}}></i>
                  Settings
                </a>
              </li>
            ) : null}
          </ul>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
};

Menu.propTypes = {
  currentEvent: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
  currentUserPartnership: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  scrollToResources: PropTypes.func.isRequired,
  scrollToTasks: PropTypes.func.isRequired,
  scrollToChat: PropTypes.func.isRequired,
  chatHistoryExists: PropTypes.bool,
  canSeeSettings: PropTypes.bool,
  canSeePermissions: PropTypes.bool,
  canSeeInternalTasks: PropTypes.bool
};

export default Menu;
